import { CaretSortIcon, CheckIcon } from "@radix-ui/react-icons";
import { keys as k } from "common/mod.ts";
import { Button } from "components/ui/button";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "components/ui/command";
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuRadioGroup,
  DropdownMenuRadioItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "components/ui/dropdown-menu";
import { Popover, PopoverContent, PopoverTrigger } from "components/ui/popover";
import { cn, NAMESPACES } from "lib/utils";
import { ChevronDownIcon, CircleX, Settings } from "lucide-react";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

export default function ProxyFilters({
  users,
  modems,
  setFilteredItems,
  columns,
}) {
  const { t } = useTranslation(Object.values(NAMESPACES));
  const [filterStatus, setFilterStatus] = useState("all");
  const [filterOccupancyStatus, setFilterOccupancyStatus] = useState("all");
  const [filterLocation, setFilterLocation] = useState("all");
  const [filterServer, setFilterServer] = useState("all");
  const [filterUser, setFilterUser] = useState("");
  const [userOpen, setUserOpen] = useState(false);

  const clearFilters = () => {
    setFilterStatus("all");
    setFilterOccupancyStatus("all");
    setFilterLocation("all");
    setFilterUser("");
  };

  const sortProxies = (a, b) => {
    return a.status === b.status
      ? a.server.serverNumber === b.server.serverNumber
        ? a.interface === b.interface
          ? a.occupancyStatus.localeCompare(b.occupancyStatus)
          : a.interface.localeCompare(b.interface)
        : a.server.serverNumber - b.server.serverNumber
      : a.status.localeCompare(b.status);
  };

  useEffect(() => {
    setFilteredItems(
      modems
        .filter((modem) => {
          if (filterLocation === "all") return true;
          return (
            `${t(modem.server.city, { ns: "cities" })}, ${t(modem.server.country, { ns: "countries" })}` ===
            filterLocation
          );
        })
        .filter((modem) => {
          if (filterServer === "all") return true;
          return modem.server.serverNumber === filterServer;
        })
        .filter((modem) => {
          if (filterOccupancyStatus === "all") return true;
          return modem.occupancyStatus === filterOccupancyStatus;
        })
        .filter((modem) => {
          if (filterStatus === "all") return true;
          return modem.status === filterStatus;
        })
        .filter((modem) => {
          if (!filterUser) return true;
          if (!modem.session?.client?._id) return false;
          return modem.session.client?._id === filterUser;
        })
        .sort(sortProxies)
    );
  }, [
    t,
    modems,
    filterUser,
    filterStatus,
    filterOccupancyStatus,
    filterLocation,
    filterServer,
    setFilteredItems,
  ]);

  return (
    // <div className="flex items-center justify-between mb-4 ">
    <div className="flex flex-wrap gap-2 mb-5">
      <div className="flex flex-wrap gap-2">
        <Popover open={userOpen} onOpenChange={setUserOpen}>
          <PopoverTrigger asChild className="">
            <Button
              variant="outline"
              role="combobox"
              size="sm"
              aria-expanded={userOpen}
              className="justify-between text-wrap max-w-[200px]"
            >
              <span className="max-w-[180px] text-wrap text-ellipsis overflow-hidden mx-auto">
                {filterUser ? users[filterUser] : t(k.SELECT_USER)}
              </span>
              <CaretSortIcon className="ml-2 h-4 w-4 shrink-0 opacity-50" />
            </Button>
          </PopoverTrigger>
          <PopoverContent className="w-[200px] ml-10 md:ml-20 p-0">
            <Command>
              <CommandInput placeholder={t(k.SELECT_USER)} className="h-9" />
              <CommandEmpty>{t(k.NO_USER_FOUND)}</CommandEmpty>
              <CommandGroup>
                <CommandList>
                  {Object.entries(users).map(([userId, userEmail]) => (
                    <CommandItem
                      key={userId}
                      value={userId}
                      onSelect={(currentValue) => {
                        setFilterUser(
                          currentValue === filterUser ? "" : currentValue
                        );
                        setUserOpen(false);
                      }}
                    >
                      {userEmail}
                      <CheckIcon
                        className={cn(
                          "ml-auto h-4 w-4",
                          filterUser === userId ? "opacity-100" : "opacity-0"
                        )}
                      />
                    </CommandItem>
                  ))}
                </CommandList>
              </CommandGroup>
            </Command>
          </PopoverContent>
        </Popover>
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button
              variant="outline"
              className="flex items-center gap-2"
              size="sm"
            >
              <span>
                {filterLocation === "all" ? t(k.LOCATIONS) : filterLocation}
              </span>
              <ChevronDownIcon className="w-4 h-4" />
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent align="start">
            <DropdownMenuRadioGroup
              value={filterLocation}
              onValueChange={setFilterLocation}
            >
              <DropdownMenuRadioItem value="all">
                {t(k.LOCATIONS)}
              </DropdownMenuRadioItem>
              {[
                ...new Set(
                  modems.map(
                    (modem) =>
                      `${t(modem.server.city, { ns: "cities" })}, ${t(modem.server.country, { ns: "countries" })}`
                  )
                ),
              ].map((location) => (
                <DropdownMenuRadioItem key={location} value={location}>
                  {location}
                </DropdownMenuRadioItem>
              ))}
            </DropdownMenuRadioGroup>
          </DropdownMenuContent>
        </DropdownMenu>
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button variant="outline" className="flex gap-2" size="sm">
              <span>
                {filterServer === "all"
                  ? t(k.SERVER_NUMBER)
                  : `#${filterServer}`}
              </span>
              <ChevronDownIcon className="w-4 h-4" />
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent align="start">
            <DropdownMenuRadioGroup
              value={filterServer}
              onValueChange={setFilterServer}
            >
              <DropdownMenuRadioItem value="all">
                {t(k.SERVER_NUMBER)}
              </DropdownMenuRadioItem>
              {[...new Set(modems.map((item) => item.server.serverNumber))].map(
                (server) => (
                  <DropdownMenuRadioItem key={server} value={server}>
                    #{server}
                  </DropdownMenuRadioItem>
                )
              )}
            </DropdownMenuRadioGroup>
          </DropdownMenuContent>
        </DropdownMenu>
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button variant="outline" className="flex gap-2" size="sm">
              <span>
                {filterStatus === "all"
                  ? t(k.STATUS)
                  : t(filterStatus.toUpperCase(), { ns: "statuses" })
                      .charAt(0)
                      .toUpperCase() +
                    t(filterStatus.toUpperCase(), { ns: "statuses" }).slice(1)}
              </span>
              <ChevronDownIcon className="w-4 h-4" />
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent align="start">
            <DropdownMenuRadioGroup
              value={filterStatus}
              onValueChange={setFilterStatus}
            >
              <DropdownMenuRadioItem value="all">
                {t(k.STATUS)}
              </DropdownMenuRadioItem>
              {[...new Set(modems.map((item) => item.status))].map((status) => (
                <DropdownMenuRadioItem key={status} value={status}>
                  {t(status.toUpperCase(), { ns: "statuses" })
                    .charAt(0)
                    .toUpperCase() +
                    t(status.toUpperCase(), { ns: "statuses" }).slice(1)}
                </DropdownMenuRadioItem>
              ))}
            </DropdownMenuRadioGroup>
          </DropdownMenuContent>
        </DropdownMenu>
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button
              variant="outline"
              className="flex items-center gap-2"
              size="sm"
            >
              <span>
                {filterOccupancyStatus === "all"
                  ? t(k.OCCUPANCY)
                  : t(filterOccupancyStatus.toUpperCase(), { ns: "statuses" })
                      .charAt(0)
                      .toUpperCase() +
                    t(filterOccupancyStatus.toUpperCase(), {
                      ns: "statuses",
                    }).slice(1)}
              </span>
              <ChevronDownIcon className="w-4 h-4" />
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent align="start">
            <DropdownMenuRadioGroup
              value={filterOccupancyStatus}
              onValueChange={setFilterOccupancyStatus}
            >
              <DropdownMenuRadioItem value="all">
                {t(k.OCCUPANCY)}
              </DropdownMenuRadioItem>
              {[...new Set(modems.map((item) => item.occupancyStatus))].map(
                (status) => (
                  <DropdownMenuRadioItem key={status} value={status}>
                    {t(status.toUpperCase(), { ns: "statuses" })
                      .charAt(0)
                      .toUpperCase() +
                      t(status.toUpperCase(), { ns: "statuses" }).slice(1)}
                  </DropdownMenuRadioItem>
                )
              )}
            </DropdownMenuRadioGroup>
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
      <div className="flex gap-4 grow">
        <Button variant="default" onClick={() => clearFilters()} size="sm">
          <CircleX className="h-4 w-4" />
          <span className="ml-2">{t(k.CLEAR_FILTERS)}</span>
        </Button>
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button variant="outline" size="sm" className="lg:ml-auto">
              <Settings className="h-4 w-4 mr-2" />
              {t(k.MANAGE_COLUMNS)}
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent align="end" className="w-[200px]">
            <DropdownMenuCheckboxItem checked disabled>
              {t(k.LOCATION)}
            </DropdownMenuCheckboxItem>
            <DropdownMenuCheckboxItem checked disabled>
              {t(k.OPERATOR)}
            </DropdownMenuCheckboxItem>
            <DropdownMenuCheckboxItem checked disabled>
              {t(k.SIGNAL)}
            </DropdownMenuCheckboxItem>
            <DropdownMenuCheckboxItem checked disabled>
              {t(k.PUBLIC_IP)}
            </DropdownMenuCheckboxItem>
            <DropdownMenuSeparator />
            <DropdownMenuCheckboxItem
              checked={columns["serverNumber"][0]}
              onCheckedChange={(v) => columns["serverNumber"][1](v)}
            >
              {t(k.SERVER_NUMBER)}
            </DropdownMenuCheckboxItem>

            <DropdownMenuCheckboxItem
              checked={columns["modemInterface"][0]}
              onCheckedChange={(v) => columns["modemInterface"][1](v)}
            >
              {t(k.INTERFACE)}
            </DropdownMenuCheckboxItem>

            <DropdownMenuCheckboxItem
              checked={columns["internalIP"][0]}
              onCheckedChange={(v) => columns["internalIP"][1](v)}
            >
              {t(k.INTERNAL_IP)}
            </DropdownMenuCheckboxItem>

            <DropdownMenuCheckboxItem
              checked={columns["gatewayIP"][0]}
              onCheckedChange={(v) => columns["gatewayIP"][1](v)}
            >
              {t(k.GATEWAY_IP)}
            </DropdownMenuCheckboxItem>

            <DropdownMenuCheckboxItem
              checked={columns["occupancyStatus"][0]}
              onCheckedChange={(v) => columns["occupancyStatus"][1](v)}
            >
              {t(k.OCCUPANCY_STATUS)}
            </DropdownMenuCheckboxItem>
            <DropdownMenuCheckboxItem
              checked={columns["modemStatus"][0]}
              onCheckedChange={(v) => columns["modemStatus"][1](v)}
            >
              {t(k.MODEM_STATUS)}
            </DropdownMenuCheckboxItem>
            <DropdownMenuCheckboxItem
              checked={columns["loginInfo"][0]}
              onCheckedChange={(v) => columns["loginInfo"][1](v)}
            >
              {t(k.LOGIN_INFO)}
            </DropdownMenuCheckboxItem>
            <DropdownMenuCheckboxItem
              checked={columns["validUntil"][0]}
              onCheckedChange={(v) => columns["validUntil"][1](v)}
            >
              {t(k.VALID_UNTIL)}
            </DropdownMenuCheckboxItem>
            <DropdownMenuCheckboxItem
              checked={columns["updatedAt"][0]}
              onCheckedChange={(v) => columns["updatedAt"][1](v)}
            >
              {t(k.UPDATED_AT)}
            </DropdownMenuCheckboxItem>
            <DropdownMenuCheckboxItem
              checked={columns["comment"][0]}
              onCheckedChange={(v) => columns["comment"][1](v)}
            >
              {t(k.COMMENT)}
            </DropdownMenuCheckboxItem>
            <DropdownMenuSeparator />
            <DropdownMenuCheckboxItem
              checked={columns["actions"][0]}
              onCheckedChange={(v) => columns["actions"][1](v)}
            >
              {t(k.ACTIONS)}
            </DropdownMenuCheckboxItem>
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
    </div>
  );
}
