const serverHandler = (socket, setServers, setSpinner) => {
  function handler(payload) {
    setServers(() => payload);
    setSpinner(false);
  }
  socket.on("web:data:servers", handler);
  return () => {
    socket.off("web:data:servers", handler);
  };
};

const updateServerHandler = (socket, setServers) => {
  function handler(updatedServer) {
    setServers((prevServers) => {
      const currentServers = [...prevServers];
      const index = currentServers.findIndex(
        (server) => server._id === updatedServer._id,
      );
      if (index === -1) return [...prevServers, updatedServer];
      else
        return prevServers.map((server) =>
          server._id === updatedServer._id ? updatedServer : server,
        );
    });
  }
  socket.on("web:action:update-server", handler);
  return () => {
    socket.off("web:action:update-server", handler);
  };
};

const deleteServerHandler = (socket, setServers) => {
  function handler(serverId) {
    setServers((prevServers) =>
      prevServers.filter((server) => server._id !== serverId),
    );
  }
  socket.on("web:action:delete-server", handler);
  return () => {
    socket.off("web:action:delete-server", handler);
  };
};

export { deleteServerHandler, serverHandler, updateServerHandler };
