const pricingPlansHandler = (socket, setPricingPlans, setLocations) => {
  function handler({ pricingPlans, locations }) {
    setPricingPlans(() => pricingPlans);
    if (setLocations) setLocations(() => locations);
  }
  socket.on("web:data:pricing-plans", handler);
  return () => {
    socket.off("web:data:pricing-plans", handler);
  };
};

const updatePricingPlanHandler = (socket, setPricingPlans, setPlan) => {
  function handler(pricingPlan) {
    setPricingPlans((pricingPlans) => {
      const index = pricingPlans.findIndex(
        (plan) => plan._id === pricingPlan._id,
      );
      if (index === -1) {
        return [...pricingPlans, pricingPlan];
      }
      pricingPlans[index] = pricingPlan;
      return [...pricingPlans];
    });
    setPlan(null);
  }

  socket.on("web:action:update-pricing-plan", handler);
  return () => {
    socket.off("web:action:update-pricing-plan", handler);
  };
};

const deletePricingPlanHandler = (socket, setPricingPlans) => {
  function handler(pricingPlan) {
    setPricingPlans((pricingPlans) =>
      pricingPlans.filter((plan) => plan._id !== pricingPlan._id),
    );
  }

  socket.on("web:action:delete-pricing-plan", handler);
  return () => {
    socket.off("web:action:delete-pricing-plan", handler);
  };
};

const availableModemsCountHandler = (socket, setAvailableModemsCount) => {
  function handler(availableModemsCount) {
    setAvailableModemsCount(() => availableModemsCount);
  }
  socket.on("web:data:available-modems-count", handler);
  return () => {
    socket.off("web:data:available-modems-count", handler);
  };
};

export {
  availableModemsCountHandler,
  deletePricingPlanHandler,
  pricingPlansHandler,
  updatePricingPlanHandler,
};
