import { keys as k } from "common/mod.ts";
import { t } from "i18next";
import { toast } from "sonner";

export const purchaseSuccessHandler = (socket, navigate) => {
  function handler() {
    navigate("/proxies");
  }

  socket.on("web:action:purchase-success", handler);

  return () => {
    socket.off("web:action:purchase-success", handler);
  };
};

export const paymentRequestHandler = (socket) => {
  function handler(navigateUrl) {
    window.location.href = navigateUrl;
    toast.success(t(k.PAYMENT_REQUEST_SUCCESS));
  }
  socket.on("web:action:payment-request", handler);
  return () => {
    socket.off("web:action:payment-request", handler);
  };
};

export const couponInfoHandler = (socket, setCouponInfo, setCouponError) => {
  function handler(couponInfo) {
    if (couponInfo) {
      setCouponInfo(couponInfo);
      setCouponError(false);
    } else {
      setCouponInfo(null);
      setCouponError(true);
    }
  }
  socket.on("web:data:coupon-info", handler);
  return () => {
    socket.off("web:data:coupon-info", handler);
  };
};
