export const usersHandler = (socket, setUsers, setSpinner) => {
  function handler(payload) {
    setUsers(payload);
    setSpinner(false);
  }
  socket.on("web:data:users-full", handler);
  return () => {
    socket.off("web:data:users-full", handler);
  };
};

export const updateUserHandler = (socket, setUsers) => {
  function handler(updatedUser) {
    setUsers((prevUsers) => {
      const currentUsers = [...prevUsers];
      const index = currentUsers.findIndex(
        (user) => user._id === updatedUser._id,
      );
      if (index === -1) return [...prevUsers, updatedUser];
      else
        return prevUsers.map((modem) =>
          modem._id === updatedUser._id ? updatedUser : modem,
        );
    });
  }

  socket.on("web:action:update-user", handler);
  return () => {
    socket.off("web:action:update-user", handler);
  };
};
