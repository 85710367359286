export default {
  SORT_BY: "Sort",
  DATE: "Date",
  STATUS: "Status",
  SORT_BY_DATE: "by date",
  SORT_BY_STATUS: "by status",
  ASCENDING: "ascending",
  DESCENDING: "descending",
  SELECT_USER: "Select user",
  NO_USER_FOUND: "No user found.",
  USER: "User",
  LAST_UPDATED: "Last updated",
  AMOUNT: "Amount",
  REFUND_AMOUNT: "Refund amount",
  TRANSACTION_ID: "Transaction ID",
  COMPLETED: "completed",
  TOGGLE_MENU: "Toggle menu",
  ACTIONS: "Actions",
  REFUND: "Refund",
  CONFIRM_REFUND: "Are you sure you want to refund this payment?",
  REFUND_DESCRIPTION:
    "Refunding this payment will revert the transaction and the user\nwill be refunded the amount.",
  REFUND_AMOUNT_WARNING:
    "Refund amount should be less than or equal to the payment amount",
  CANCEL: "Cancel",
  MODIFY: "Modify",
  CONFIRM: "Confirm",
  CREATE: "Create",
  PRICING_PLAN: "pricing plan",
  CLEAR: "Clear",
  COUNTRY: "Country",
  CITY: "City",
  OPERATOR: "Operator",
  ADD_SUB_PLAN: "Add sub-plan",
  UPDATE: "Update",
  SUBMIT: "Submit",
  PLAN: "plan",
  SUBMIT_PLAN: "Submit plan",
  SUB_PLAN: "Sub-plan",
  TITLE: "Title",
  PRICE: "Price",
  DESCRIPTION: "Description",
  FEATURES: "Features",
  ADD_FEATURE: "Add Feature",
  PRICING_PLANS: "Pricing Plans",
  AVAILABLE_MODEMS_COUNT: "Available Modems",
  PRICING_PLANS_DESCRIPTION: "Choose the plan that's right for you",
  ALL: "All",
  PLANS: "Plans",
  VIEW_PLANS: "View Plans",
  COPY_PLAN: "Copy plan",
  EDIT_PLAN: "Edit plan",
  DELETE_PLAN: "Delete plan",
  ADD_PLAN: "Add Plan",
  CHANGE_IP: "Change IP",
  CHANGE_DHCP: "Change DHCP",
  REBOOT: "Reboot",
  RESET: "Reset",
  SWITCH: "Switch",
  EXTEND: "Extend",
  ASSIGN: "Assign",
  DELETE: "Delete",
  ASSIGN_MODEM: "Assign modem",
  ASSIGN_TRIAL: "Assign trial",
  CLEAR_SESSION: "Clear session",
  CLEAR_SESSION_WARNING: "Are you sure you want to clear the session?",
  DELETE_MODEM: "Delete Modem",
  DELETE_MODEM_WARNING: "Are you sure you want to delete this modem?",
  CHANGE_MODEM_DHCP: "Change Modem DHCP",
  CHANGE_MODEM_DHCP_WARNING:
    "This will change the DHCP gateway IP for the modem.",
  SELECT_PERIOD: "Select Period",
  SELECT_PERIOD_DESCRIPTION:
    "Choose how you would like to extend the current period.",
  EXTEND_SESSION: "Extend session",
  LOCATIONS: "Locations",
  SERVER_NUMBER: "Server number",
  OCCUPANCY: "Occupancy",
  OCCUPANCY_STATUS: "Occupancy status",
  CLEAR_FILTERS: "Clear filters",
  MANAGE_COLUMNS: "Manage Columns",
  SIGNAL: "Signal",
  PUBLIC_IP: "Public IP",
  INTERFACE: "Interface",
  INTERNAL_IP: "Internal IP",
  GATEWAY_IP: "DHCP",
  UPDATED_AT: "Updated at",
  COMMENT: "Comment",
  RENEW: "Renew",
  RENEW_SUBSCRIPTION: "Extend modem subscription",
  RENEW_SUBSCRIPTION_DESCRIPTION:
    "Extend your subscription by selecting a plan",
  MODEM_STATUS: "Modem status",
  USER_LOGIN_INFO: "User Login info",
  ADMIN_LOGIN_INFO: "Admin Login Info",
  VALID_UNTIL: "Valid until",
  UPDATED: "Updated",
  SELECT_DATE: "Select Date",
  SELECT_DURATION: "Select Duration",
  HOURS: "Hours",
  MINUTES: "Minutes",
  DAYS: "Days",
  END_PERIOD: "End period:",
  MODEM_DETAILS: "Modem Details",
  GENERAL: "General",
  ID: "ID",
  SERVER_ID: "Server ID",
  NUMBER: "Number",
  LOCATION_NUMBER: "Location number",
  COUNT: "Count",
  LAST_REBOOT: "Last Reboot",
  SESSION: "Session",
  RESERVED_BY: "Reserved By",
  RESERVED_UNTIL: "Reserved Until",
  PURCHASED_BY: "Purchased by",
  PURCHASED_UNTIL: "Purchased Until",
  USED_TRAFFIC: "Used Traffic",
  LIMIT_TRAFFIC: "Limit Traffic",
  LATEST_DOWNLOAD_SPEED: "Latest download speed",
  LATEST_UPLOAD_SPEED: "Latest upload speed",
  CONNECTION_DETAILS: "Connection details",
  URL: "URL",
  COPY_URL: "Copy URL",
  HTTP_PORT: "HTTP Port",
  COPY_HTTP_PORT: "Copy HTTP port",
  SOCKS_PORT: "SOCKS5 Port",
  COPY_SOCKS_PORT: "Copy SOCKS5 port",
  ADMIN_USERNAME: "Admin username",
  COPY_USERNAME: "Copy username",
  COPY_HTTP_LOGIN: "Copy HTTP Login",
  ADMIN_PASSWORD: "Admin password",
  COPY_PASSWORD: "Copy password",
  COPY_SOCKS_LOGIN: "Copy SOCKS5 Login",
  CLIENT_USERNAME: "Client username",
  CLIENT_PASSWORD: "Client password",
  CLOSE: "Close",
  REBOOT_MODEM: "Reboot Modem",
  REBOOT_MODEM_WARNING: "Are you sure you want to reboot this modem?",
  RESET_MODEM: "Reset Modem",
  RESET_MODEM_WARNING: "Are you sure you want to reset this modem?",
  ENABLE_MODEM: "Enable Modem",
  ENABLE_MODEM_CONFIRM: "Are you sure you want to enable this modem?",
  DISABLE_MODEM: "Disable Modem",
  DISABLE_MODEM_CONFIRM: "Are you sure you want to disable this modem?",
  SHOW_ACTIONS: "Show actions",
  SHOW_MODEM: "Show modem",
  FREE: "Free",
  RESERVED: "Reserved",
  BUSY: "Busy",
  SWITCH_MODEM: "Switch modem",
  SELECT: "Select",
  SERVER_RESTRICTIONS_MANAGEMENT: "Server Restrictions Management",
  PUBLIC_URL: "Public URL",
  ANYDESK: "AnyDesk",
  CONNECT: "Connect",
  RESTRICTIONS: "Restrictions",
  NEW_RESTRICTION: "New restriction",
  IP_RESTRICTIONS_FOR: "IP Restrictions for",
  CURRENT_RESTRICTIONS: "Current Restrictions",
  REMOVE: "Remove",
  RESTRICTIONS_ACTIONS: "Restrictions actions",
  ADD_NEW_RESTRICTIONS: "Add new restrictions",
  ADD: "Add",
  OR: "OR",
  COPY_RESTRICTIONS_FROM_ANOTHER: "Copy restrictions from another server",
  SELECT_SERVER: "Select server",
  SELECT_COUNTRY: "Select country",
  SELECT_CITY: "Select city",
  SELECT_OPERATOR: "Select operator",
  SELECT_LOCATION: "Select location",
  COPY: "Copy",
  UPDATE_RESTRICTIONS: "Update Restrictions",
  AVAILABLE: "Available",
  DISABLED: "Disabled",
  UNAVAILABLE: "Unavailable",
  NO_INTERNET: "No internet",
  EDIT_BALANCE: "Edit balance",
  ENTER_BALANCE: "Enter balance",
  EDIT: "Edit",
  ENABLE: "Enable",
  DISABLE: "Disable",
  EMAIL: "Email",
  TELEGRAM_ID: "Telegram ID",
  FULL_NAME: "Full name",
  BALANCE: "Balance",
  ROLE: "Role",
  EMAIL_PLACEHOLDER: "user@example.com",
  FIRST_NAME_PLACEHOLDER: "John",
  LAST_NAME_PLACEHOLDER: "Doe",
  TELEGRAM_PLACEHOLDER: "@username",
  DEFAULT: "default",
  GHOST: "ghost",
  RIGHT: "right",
  START: "start",
  SIGN_OUT: "Sign out",
  GLOBALPROXY: "Global Proxy",
  PROFILE: "Profile",
  ACCOUNT: "Account",
  SELECT_AN_OPTION: "Select an option",
  EDIT_COMMENT: "Edit comment",
  ENTER_COMMENT: "Enter comment",
  SHOW: "Show",
  USERNAME: "Username",
  PASSWORD: "Password",
  COPY_CHANGE_IP_URL: "Copy Change IP URL",
  SAVE_CHANGES: "Save Changes",
  IP: "IP",
  LOGIN_INFO: "Login info",
  QUANTITY: "Quantity",
  PERIOD: "Period",
  PRICE_PER_ITEM: "Price per Item",
  SWITCH_THEME: "Switch Theme",
  MORE: "More",
  PICK_A_DATE: "Pick a date",
  COLUMNS: "Columns",
  LOCATION: "Location",
  NO_RESULTS_FOUND: "No results found",
  CUSTOM: "Custom",
  PURCHASE_PRODUCT: "Purchase Product",
  PURCHASE_PRODUCT_DESCRIPTION:
    "Complete your purchase by filling out the details below.",
  TOTAL: "Total",
  REQUEST_TRIAL: "Request Trial",
  PURCHASE: "Purchase",
  DUE: "due",
  PURCHASE_REDIRECT_INFO: "* You will be redirected to the payment gateway.",
  CURRENT_BALANCE: "Current Balance",
  REFILL_AMOUNT: "Refill Amount",
  NEW_BALANCE: "New Balance",
  REFILL_BALANCE: "Refill Balance",
  REQUEST_TRIAL_ACCESS: "Request Trial Access",
  REQUEST_TRIAL_PROVIDE_HANDLER_WARNING:
    "Please provide either a Telegram handle or an email address.",
  REQUEST_TRIAL_ACCESS_DESCRIPTION:
    "Fill out the form below to request trial access to our service.\nProvide either a Telegram handle or an email address for contact.",
  TELEGRAM: "Telegram",
  TRIAL_PROCESS: "Trial Process:",
  TRIAL_PROCESS_STEP_1: "We will receive your trial request",
  TRIAL_PROCESS_STEP_2: "Our team will review and grant access",
  TRIAL_PROCESS_STEP_3:
    "We will inform you via Telegram or email with the next steps",
  SUBMIT_REQUEST: "Submit Request",
  HOME: "Home",
  DASHBOARD: "Dashboard",
  ADMIN_PANEL: "Admin Panel",
  HISTORY: "History",
  PAYMENT: "Payment",
  PAYMENT_HISTORY: "Payment History",
  PROXY_HISTORY: "Proxy History",
  NO_PAYMENT_HISTORY_AVAILABLE: "No payment history available",
  PROXIES: "Proxies",
  NO_PROXIES_AVAILABLE: "No proxies available",
  SERVERS: "Servers",
  NO_SERVERS_AVAILABLE: "No servers available",
  USERS: "Users",
  NO_USERS_AVAILABLE: "No users available",
  COUPONS: "Coupons",
  NO_COUPONS_AVAILABLE: "No coupons available",
  ADD_COUPON: "Add coupon",
  GENERATE_COUPON: "Generate",
  UPDATE_COUPON: "Update coupon",
  NO_COUPON_FOUND: "No coupon found.",
  COUPON_INVALID: "Coupon is invalid",
  SELECT_COUPON: "Select coupon",
  COUPON_CODE: "Code",
  COUPON_DISCOUNT: "Discount amount",
  COUPON_TYPE: "Discount type",
  COUPON_USES: "Uses",
  COUPON_LIMIT: "Max uses",
  COUPON_EXPIRY: "Expiry",
  COUPON_FIXED: "Fixed amount",
  COUPON_PERCENTAGE: "Percentage",
  COUPON_ZERO_IS_UNLIMITED: "0 is unlimited",
  COUPON_CODE_VALIDATION: "Coupon code must be at least 2 characters.",
  COUPON_PERCENTAGE_VALIDATION: "Percentage must be less than or equal to 100",
  COUPON_AFFECTED_PRODUCTS: "Affected locations",
  SELECT_COUNTRIES: "Select countries",
  SELECT_COUNTRIES_DESCRIPTION:
    "Select the countries that the coupon applies to",
  SELECT_CITIES: "Select cities",
  SELECT_CITIES_DESCRIPTION:
    "Select the cities that the coupon applies to. Only applies if one country is selected",
  SELECT_OPERATORS: "Select operators",
  SELECT_OPERATORS_DESCRIPTION:
    "Select the operators that the coupon applies to. Only applies if one city is selected",
  ALL_COUNTRIES: "All countries",
  ALL_CITIES: "All cities",
  ALL_OPERATORS: "All operators",
  ALL_LOCATIONS: "All locations",
  NONE_INCLUDES_ALL: "none includes all",
  CONFIRM_EMAIL: "Confirm email",
  CONFIRM_EMAIL_DESCRIPTION: "Please check your email to confirm your account",
  TOKEN_UNUSED:
    "You will be redirected to the login page once you confirm your\nemail. If you don't see the email, please check your spam\nfolder. If you still don't see the email, please contact\nsupport.",
  TOKEN_INVALID_INFO:
    "The token is invalid. Please try again or contact support.",
  REGENERATE_TOKEN: "Regenerate token",
  TOKEN_EXPIRED_INFO:
    "The token has expired. Please try again or contact support.",
  ERROR_PAGE_TITLE: "Oops, something went wrong!",
  ERROR_PAGE_DESCRIPTION:
    "We're sorry, but an unexpected error has occurred. Please try\nagain later or contact support if the issue persists.",
  GO_TO_HOMEPAGE: "Go to Homepage",
  IP_STATISTICS: "IP Statistics",
  NO_IP_STATISTICS_AVAILABLE: "No IP statistics available",
  PURCHASE_PROXIES: "Purchase proxies",
  NO_PROXIES_HISTORY_AVAILABLE: "No proxies history available",
  LOGIN: "Login",
  LOGIN_DESCRIPTION: "Enter your email below to login to your account",
  FORGOT_YOUR_PASSWORD: "Forgot your password?",
  LOGIN_NO_ACCOUNT: "Don't have an account?",
  FORGOT_PASSWORD: "Forgot Password",
  FORGOT_PASSWORD_DESCRIPTION: "Enter your email below to reset your password",
  RESET_PASSWORD: "Reset password",
  BACK_TO_LOGIN: "Back to login",
  SIGN_UP: "Sign Up",
  SIGN_UP_DESCRIPTION: "Enter your information to create an account",
  FIRST_NAME: "First name",
  LAST_NAME: "Last name",
  CONFIRM_PASSWORD: "Confirm password",
  CREATE_AN_ACCOUNT: "Create an account",
  ALREADY_HAVE_AN_ACCOUNT: "Already have an account?",
  SIGN_IN: "Sign in",
  SETTINGS: "Settings",
  UPDATE_ACCOUNT: "Update account",
  REFILL: "Refill",
  CONTACT: "Contact",
  TELEGRAM_OFFICIAL: "Official channel",
  TELEGRAM_OFFICIAL_LINK: "https://t.me/globalproxy_en",
  TELEGRAM_SUPPORT: "Support channel",
  TELEGRAM_SUPPORT_LINK: "https://t.me/GlobalProxy_support",
  INVALID_EMAIL_OR_PASSWORD: "Invalid email or password",
  USER_NOT_ACTIVE: "User is not active. Check your email for activation link.",
  LOGGED_IN: "Logged in successfully",
  LOGGED_OUT: "Logged out successfully",
  AUTHENTICATION_FAILED: "Authentication failed. Please log in again.",
  GENERAL_ERROR: "An error occurred. Please try again.",
  CONNECTION_ERROR: "Connection error. Trying to reconnect.",
  RESET_PASSWORD_EMAIL_SENT: "Reset password email sent",
  PASSWORDS_DO_NOT_MATCH: "Passwords do not match",
  REGISTER_ERROR: "An error occurred while registering. Please try again.",
  REGISTER_SUCCESS:
    "Registered successfully. Please check your email for activation link.",
  CHANGE_PASSWORD: "Change password",
  CURRENT_PASSWORD: "Current password",
  NEW_PASSWORD: "New password",
  ACCOUNT_UPDATED: "Account updated successfully",
  ACCOUNT_FIRST_NAME_VALIDATION: "First name must be at least 2 characters.",
  ACCOUNT_LAST_NAME_VALIDATION: "Last name must be at least 2 characters.",
  ACCOUNT_PASSWORD_VALIDATION: "Password must be at least 8 characters.",
  ACCOUNT_PASSWORD_MATCH_VALIDATION: "Passwords do not match.",
  ACCOUNT_INVALID_CURRENT_PASSWORD:
    "Invalid current password. Please try again",
  ACCOUNT_UNAUTHORIZED: "Unauthorized. Please try to log in again.",
  CONFIRM_USER_SUCCESS: "User confirmed successfully",
  CONFIRM_USER_NO_USER_ID: "No user ID provided",
  CONFIRM_USER_TOKEN_REGENERATED:
    "Token regenerated successfully. Please check your email",
  INVALID_RESTRICTION: "Invalid restriction",
  INVALID_RESTRICTIONS_COPIED:
    "Some restrictions were not copied because they are invalid",
  AUTH_USER_NOT_ACTIVE:
    "User is not active. Check your email for activation link.",
  ACTIONS_MODEM_REBOOT_INITIATED: "Modem reboot initiated",
  ACTIONS_MODEM_RESET_INITIATED: "Modem reset initiated",
  ACTIONS_MODEM_DELETE_INITIATED: "Modem delete initiated",
  ACTIONS_DHCP_CHANGE_INITIATED: "DHCP change initiated",
  ACTIONS_MODEM_SWITCH_INITIATED: "Modem switch initiated",
  ACTIONS_SESSION_CLEARED: "Session cleared",
  ACTIONS_SESSION_EXTENDED: "Session extended",
  ACTIONS_MODEM_ASSIGNED: "Modem assigned",
  ACTIONS_TRIAL_ASSIGNED: "Trial assigned",
  ACTIONS_IP_CHANGE_REQUESTED: "IP change requested",
  ACTIONS_REBOOT_REQUESTED: "Reboot requested",
  ACTIONS_RESET_REQUESTED: "Reset requested",
  ACTIONS_RESTRICTIONS_UPDATED: "Restrictions updated",
  ACTIONS_BALANCE_UPDATED: "Balance updated",
  ACTIONS_COMMENT_UPDATED: "Comment updated",
  ACTIONS_RENEW_UPDATED: "Renew updated",
  ACTIONS_STATUS_SWITCH_INITIATED: "Status switch initiated",
  PROXY_LOGIN_INFO_UPDATED: "Proxy login info updated",
  PAYMENT_REQUEST_SUCCESS: "Payment successful. Redirecting to proxies page...",
  IP_STATISTICS_ALL: "All periods",
  IP_STATISTICS_LAST_HOUR: "Last hour",
  IP_STATISTICS_LAST_6_HOURS: "Last 6 hours",
  IP_STATISTICS_LAST_12_HOURS: "Last 12 hours",
  IP_STATISTICS_LAST_24_HOURS: "Last 24 hours",
  IP_STATISTICS_LAST_7_DAYS: "Last 7 days",
  IP_STATISTICS_LAST_30_DAYS: "Last 30 days",
  NOTIFICATIONS: "Notifications",
  ALL_NOTIFICATIONS: "All",
  OPERATIONAL_NOTIFICATIONS: "Operational",
  ACCOUNT_NOTIFICATIONS: "Account",
  BILLING_NOTIFICATIONS: "Billing",
  MARK_AS_READ: "Mark as read",
  MARK_ALL_AS_READ: "Mark all as read",
  DASHBOARD_GRANULARITY_SELECT: "Select granularity",
  DASHBOARD_GRANULARITY_MINUTELY: "Minutely",
  DASHBOARD_GRANULARITY_HOURLY: "Hourly",
  DASHBOARD_GRANULARITY_DAILY: "Daily",
  DASHBOARD_GRANULARITY_MONTHLY: "Monthly",
  DASHBOARD_GRANULARITY_YEARLY: "Yearly",
  DASHBOARD_AVAILABILITY_CHART_TITLE: "Modem availability over time",
  DASHBOARD_AVAILAILABILITY_FREE: "Free",
  DASHBOARD_AVAILAILABILITY_BUSY: "Busy",
  DASHBOARD_AVAILAILABILITY_RESERVED: "Reserved",
  DASHBOARD_STATUS_CHART_TITLE: "Modem status over time",
  DASHBOARD_STATUS_AVAILABLE: "Available",
  DASHBOARD_STATUS_DISABLED: "Disabled",
  DASHBOARD_STATUS_UNAVAILABLE: "Unavailable",
  DASHBOARD_STATUS_NO_INTERNET: "No internet",
  NO_NOTIFICATIONS: "No notifications",
  NEW_GATEWAY_IP: "New Gateway IP",
  NOTIFICATION_COPIED_TO_CLIPBOARD: "Copied to clipboard: {{content}}",
  NOTIFICATION_CLIPBOARD_NOT_SUPPORTED: "Clipboard not supported",
  NOTIFICATION_IP_CHANGE_SUCCESS: "IP change successful: {{newIp}}",
  NOTIFICATION_REBOOT_SUCCESS: "Reboot successful",
  NOTIFICATION_RESET_SUCCESS: "Reset successful",
  NOTIFICATION_USER_UPDATE_SUCCESS: "User updated successfully",
  NOTIFICATION_PLAN_CREATE_SUCCESS: "Plan created successfully",
  NOTIFICATION_PLAN_UPDATE_SUCCESS: "Plan updated successfully",
  NOTIFICATION_PLAN_DELETE_SUCCESS: "Plan deleted successfully",
  NOTIFICATION_REFUND_SUCCESS: "Refund successful",
  NOTIFICATION_TRIAL_REQUEST_SUCCESS: "Trial request successful",
  NOTIFICATION_COUPON_CREATE_SUCCESS: "Coupon created successfully",
  NOTIFICATION_COUPON_UPDATE_SUCCESS: "Coupon updated successfully",
  NOTIFICATION_SESSION_PURCHASE_SUCCESS_SUBJECT: "Proxy session purchased",
  NOTIFICATION_SESSION_PURCHASE_SUCCESS_BODY:
    "Your proxy session has been successfully purchased. Please navigate to the proxies page to view more details.",
  NOTIFICATION_SESSION_PURCHASE_FAILED_SUBJECT: "Proxy session purchase failed",
  NOTIFICATION_SESSION_PURCHASE_FAILED_BODY:
    "Your proxy session purchase has failed",
  NOTIFICATION_SESSION_EXPIRATION_SUBJECT: "Proxy session expires soon",
  NOTIFICATION_SESSION_EXPIRATION_BODY:
    "Your proxy session is about to expire. Please renew your subscription to continue using this proxy session.",
  NOTIFICATION_SESSION_RENEWAL_SUBJECT: "Proxy session renewal",
  NOTIFICATION_SESSION_RENEWAL_BODY:
    "Your proxy session will soon be renewed. Please ensure you have enough balance to be able to renew this proxy session.",
  NOTIFICATION_SESSION_RENEWAL_FAILED_SUBJECT: "Proxy session renewal failed",
  NOTIFICATION_SESSION_RENEWAL_FAILED_BODY:
    "Your proxy session renewal has failed due to insufficient balance.",
  NOTIFICATION_SESSION_RENEWAL_SUCCESS_SUBJECT: "Proxy session renewed",
  NOTIFICATION_SESSION_RENEWAL_SUCCESS_BODY:
    "Your proxy session has been successfully renewed.",
  ERROR_USER_NOT_FOUND: "User not found",
  ERROR_NO_MODEMS_AVAILABLE: "No modems available",
  ERROR_GATEWAY_EXISTS: "Gateway already exists",
  ERROR_PROXY_SERVER_NOT_CONNECTED: "Proxy server is not connected",
  ERROR_MODEM_NOT_FOUND: "Modem not found",
  ERROR_MODEM_ASSIGNED: "Modem is already assigned",
  ERROR_IP_CHANGE: "IP change failed",
  ERROR_REBOOT_FAILED: "Reboot failed",
  ERROR_RESET_FAILED: "Reset failed",
  ERROR_REBOOT_LIMIT: "Reboot limit reached",
  ERROR_MODEM_NOT_PURCHASED: "Modem is not purchased",
  ERROR_UNAUTHORIZED_ACTION: "Unauthorized action",
  ERROR_SERVER_NOT_FOUND: "Server not found",
  ERROR_PRICING_PLAN_NOT_FOUND: "Pricing plan not found",
  ERROR_PURCHASE_INVALID_QUANTITY: "Invalid quantity",
  ERROR_PAYMENT_NOT_FOUND: "Payment not found",
  ERROR_PAYMENT_NOT_COMPLETED: "Payment not completed",
  ERROR_REFUND_AMOUNT_EXCEEDS: "Refund amount exceeds payment amount",
  ERROR_COUPON_EXISTS: "Coupon already exists",
  ERROR_COUPON_NOT_FOUND: "Coupon not found",
};
