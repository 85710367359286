import { CaretSortIcon, CheckIcon } from "@radix-ui/react-icons";
import { keys as k } from "common/mod.ts";
import { Button } from "components/ui/button";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "components/ui/command";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "components/ui/dialog";
import { Popover, PopoverContent, PopoverTrigger } from "components/ui/popover";
import { cn } from "lib/utils";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { socket } from "socket";

import { assignTrial } from "./actions-handler";

export default function AssignTrialOpen({ modemId, setAssignTrialOpen }) {
  const { t } = useTranslation();
  const [selectedUser, setSelectedUser] = useState(null);
  const [users, setUsers] = useState([]);
  const [userOpen, setUserOpen] = useState(false);

  useEffect(() => {
    socket.emit("main:fetch:users", (users) => {
      setUsers(users);
    });
  }, []);

  useEffect(() => {
    socket.on("web:data:users", (users) => {
      setUsers(users);
    });
    return () => {
      socket.off("web:data:users", (users) => {
        setUsers(users);
      });
    };
  }, [users]);

  const usersMap = useMemo(() => {
    return users.reduce((acc, user) => {
      acc[user.email] = user;
      return acc;
    }, {});
  }, [users]);

  return (
    <Dialog defaultOpen>
      <DialogTrigger></DialogTrigger>
      <DialogContent
        closeClick={() => setAssignTrialOpen(false)}
        onEscapeKeyDown={() => setAssignTrialOpen(false)}
        onPointerDownOutside={() => setAssignTrialOpen(false)}
        className="h-[200px]"
      >
        <DialogHeader>
          <DialogTitle>{t(k.ASSIGN_TRIAL)}</DialogTitle>
        </DialogHeader>
        <div className="flex gap-4 justify-between">
          <Popover open={userOpen} onOpenChange={setUserOpen}>
            <PopoverTrigger asChild className="">
              <Button
                variant="outline"
                role="combobox"
                size="sm"
                aria-expanded={userOpen}
                className="justify-between text-wrap max-w-[200px]"
              >
                <span className="max-w-[180px] text-wrap text-ellipsis overflow-hidden mx-auto">
                  {selectedUser ? selectedUser.email : t(k.SELECT_USER)}
                </span>
                <CaretSortIcon className="ml-2 h-4 w-4 shrink-0 opacity-50" />
              </Button>
            </PopoverTrigger>
            <PopoverContent className="w-[200px] ml-10 md:ml-20 p-0">
              <Command>
                <CommandInput placeholder={t(k.SELECT_USER)} className="h-9" />
                <CommandEmpty>{t(k.NO_USER_FOUND)}</CommandEmpty>
                <CommandGroup>
                  <CommandList>
                    {users.map((user) => (
                      <CommandItem
                        key={user.email}
                        value={user.email}
                        onSelect={(email) => {
                          setSelectedUser(usersMap[email]);
                          setUserOpen(false);
                        }}
                      >
                        {user.email}
                        <CheckIcon
                          className={cn(
                            "ml-auto h-4 w-4",
                            selectedUser?._id === user._id
                              ? "opacity-100"
                              : "opacity-0"
                          )}
                        />
                      </CommandItem>
                    ))}
                  </CommandList>
                </CommandGroup>
              </Command>
            </PopoverContent>
          </Popover>
          <div className="flex flex-row gap-4">
            <Button onClick={() => setAssignTrialOpen(false)} variant="outline">
              {t(k.CANCEL)}
            </Button>
            <Button
              variant="destructive"
              disabled={!selectedUser}
              onClick={() =>
                assignTrial(modemId, selectedUser, setAssignTrialOpen)
              }
            >
              {t(k.ASSIGN_TRIAL)}
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}
