import { keys as k } from "common/mod.ts";
import AdminProxiesTable from "components/layout/admin/proxies";
import { ContentLayout } from "components/layout/panel/content-layout";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from "components/ui/breadcrumb";
import { Card, CardContent, CardFooter, CardHeader } from "components/ui/card";
import { Spinner } from "components/ui/spinner";
import { backconnectServersHandler } from "handlers/backconnect-servers";
import {
  deleteUserModemHandler,
  updateUserModemHandler,
  userModemsHandler,
} from "handlers/modems";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { socket } from "socket";

export default function AdminProxies() {
  const { t } = useTranslation();
  const [modems, setModems] = useState([]);
  const [users, setUsers] = useState([]);
  const [spinner, setSpinner] = useState(true);
  const [backconnectServers, setBackconnectServers] = useState([]);
  useEffect(() => {
    socket.emit("main:fetch:modems");
  }, []);
  useEffect(() => {
    socket.emit("main:fetch:backconnect-servers");
  }, []);
  useEffect(
    () => userModemsHandler(socket, setModems, setUsers, setSpinner),
    []
  );

  useEffect(() => backconnectServersHandler(socket, setBackconnectServers), []);

  useEffect(() => updateUserModemHandler(socket, setModems), []);
  useEffect(() => deleteUserModemHandler(socket, setModems), []);

  return (
    <ContentLayout title={t(k.PROXIES)}>
      <Card className="rounded-lg border-none mt-6">
        <CardHeader>
          <Breadcrumb>
            <BreadcrumbList>
              <BreadcrumbItem>
                <BreadcrumbPage>{t(k.ADMIN_PANEL)}</BreadcrumbPage>
              </BreadcrumbItem>
              <BreadcrumbSeparator />
              <BreadcrumbItem>
                <BreadcrumbPage>{t(k.PROXIES)}</BreadcrumbPage>
              </BreadcrumbItem>
            </BreadcrumbList>
          </Breadcrumb>
        </CardHeader>
        <CardContent>
          {spinner && <Spinner className="text-primary flex justify-center" />}
          {modems.length ? (
            <AdminProxiesTable
              modems={modems}
              users={users}
              backconnectServers={backconnectServers}
            />
          ) : (
            <p className="text-center text-muted-foreground">
              {t(k.NO_PROXIES_AVAILABLE)}
            </p>
          )}
        </CardContent>
        <CardFooter></CardFooter>
      </Card>
    </ContentLayout>
  );
}
