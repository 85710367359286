import { keys as k } from "common/mod.ts";
import { SignalStrengthIcon } from "components/icons/signal";
import { Button } from "components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "components/ui/dialog";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "components/ui/table";
import { NAMESPACES } from "lib/utils";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { socket } from "socket";

import { switchModem } from "./actions-handler";

export default function SwitchModal({ modemId, serverNumber, setSwitchOpen }) {
  const { t } = useTranslation([
    NAMESPACES.GENERAL,
    NAMESPACES.CITIES,
    NAMESPACES.COUNTRIES,
  ]);
  const [selectedModem, setSelectedModem] = useState(null);
  const [modems, setModems] = useState([]);

  const modemSort = (a, b) => {
    // Sort first to be the same server number as the current modem
    // Then by same country as the current modem
    // Then by same city as the current modem
    // Then by signal strength
    if (a.server.serverNumber === serverNumber) {
      return -1;
    } else if (b.server.serverNumber === serverNumber) {
      return 1;
    } else if (a.server.country === b.server.country) {
      if (a.server.city === b.server.city) {
        return b.signal - a.signal;
      }
      return a.server.city.localeCompare(b.server.city);
    }
    return a.server.country.localeCompare(b.server.country);
  };

  useEffect(() => {
    socket.emit("main:fetch:available-modems");

    socket.on("web:data:available-modems", (data) => {
      setModems(data.sort(modemSort));
    });

    return () => {
      socket.off("web:data:available-modems");
    };
  }, []);

  return (
    <Dialog defaultOpen>
      <DialogTrigger></DialogTrigger>
      <DialogContent
        closeClick={() => setSwitchOpen(false)}
        onEscapeKeyDown={() => setSwitchOpen(false)}
        onPointerDownOutside={() => setSwitchOpen(false)}
        className="max-w-[50%]"
      >
        <DialogHeader>
          <DialogTitle>{t(k.SWITCH_MODEM)}</DialogTitle>
        </DialogHeader>
        <div className="flex flex-col gap-4">
          <Table>
            <TableHeader>
              <TableRow>
                <TableHead>{t(k.LOCATION)}</TableHead>
                <TableHead>{t(k.SERVER_NUMBER)}</TableHead>
                <TableHead>{t(k.INTERFACE)}</TableHead>
                <TableHead>{t(k.OPERATOR)}</TableHead>
                <TableHead className="text-center">{t(k.SIGNAL)}</TableHead>
                <TableHead>{t(k.PUBLIC_IP)}</TableHead>
                <TableHead>{t(k.INTERNAL_IP)}</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {modems.map((modem) => (
                <TableRow
                  key={modem._id}
                  className={
                    selectedModem?._id === modem._id ? "bg-gray-100" : ""
                  }
                >
                  <TableCell>
                    {t(modem.server.city, { ns: "cities" })},{" "}
                    {t(modem.server.country, { ns: "countries" })}
                  </TableCell>
                  <TableCell>{modem.server.serverNumber}</TableCell>
                  <TableCell>{modem.interface}</TableCell>
                  <TableCell>{modem.operator}</TableCell>
                  <TableCell className="text-center">
                    <SignalStrengthIcon
                      signal={modem.signal}
                      className="w-[25px] text-left"
                    />
                  </TableCell>
                  <TableCell>{modem.publicIP}</TableCell>
                  <TableCell>{modem.internalIP}</TableCell>
                  <TableCell>
                    {selectedModem?._id === modem._id ? (
                      <Button
                        variant="outline"
                        onClick={() => setSelectedModem(null)}
                      >
                        {t(k.CLEAR)}
                      </Button>
                    ) : (
                      <Button
                        variant="outline"
                        onClick={() => setSelectedModem(modem)}
                      >
                        {t(k.SELECT)}
                      </Button>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
        <DialogFooter>
          <div className="flex gap-4">
            <Button onClick={() => setSwitchOpen(false)} variant="outline">
              {t(k.CANCEL)}
            </Button>
            <Button
              variant="destructive"
              disabled={!selectedModem}
              onClick={() =>
                switchModem(modemId, selectedModem._id, setSwitchOpen)
              }
            >
              {t(k.SWITCH)}
            </Button>
          </div>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
