import { Button } from "components/ui/button";
import { cn } from "lib/utils";
import { ChevronLeft } from "lucide-react";
import React from "react";

export function SidebarToggle({ isOpen, setIsOpen }) {
  return (
    <div className="invisible lg:visible absolute top-[12px] -right-[16px] z-20 bg-white dark:bg-transparent">
      <Button
        onClick={() => setIsOpen?.()}
        className="rounded-md w-8 h-8"
        variant="secondary"
        size="icon"
      >
        <ChevronLeft
          className={cn(
            "h-4 w-4 transition-transform ease-in-out duration-700",
            isOpen === false ? "rotate-180" : "rotate-0",
          )}
        />
      </Button>
    </div>
  );
}
