import { keys as k } from "common/mod.ts";
import i18n from "i18next";
import { toast } from "sonner";

export const handleSocketTokens = ({
  socket,
  accessToken,
  refreshToken,
  user,
}) => {
  socket.disconnect();
  accessToken && localStorage.setItem("accessToken", accessToken);
  refreshToken && localStorage.setItem("refreshToken", refreshToken);
  user && localStorage.setItem("user", user);
  socket.auth = {
    accessToken,
    refreshToken,
  };
  socket.connect();
};

export const authFailed = (err, navigate) => {
  localStorage.removeItem("accessToken");
  localStorage.removeItem("refreshToken");
  localStorage.removeItem("user");

  if (err.message === "UserNotActive") toast.error(i18n.t(k.USER_NOT_ACTIVE));
  else toast.error(i18n.t(k.AUTHENTICATION_FAILED));

  navigate("/login");
};

const emitRefreshedAccessToken = (accessToken) => {
  localStorage.setItem("accessToken", accessToken);
};

const emitRefreshedRefreshToken = (refreshToken) => {
  localStorage.setItem("refreshToken", refreshToken);
};

const emitUser = (user) => {
  localStorage.setItem("user", user);
};

export const handleAuth = (socket) => {
  socket.on("auth:refreshed-access-token", (accessToken) =>
    emitRefreshedAccessToken(accessToken)
  );
  socket.on("auth:refreshed-refresh-token", (refreshToken) =>
    emitRefreshedRefreshToken(refreshToken)
  );
  socket.on("auth:success", (user) => emitUser(user));
  return () => {
    socket.off("auth:refreshed-access-token", (accessToken) =>
      emitRefreshedAccessToken(accessToken)
    );
    socket.off("auth:refreshed-refresh-token", (refreshToken) =>
      emitRefreshedRefreshToken(refreshToken)
    );
    socket.off("auth:success", (user) => emitUser(user));
  };
};

export const handleLogout = (navigate) => {
  localStorage.removeItem("accessToken");
  localStorage.removeItem("refreshToken");
  localStorage.removeItem("user");
  navigate("/login");
  toast.success(i18n.t(k.LOGGED_OUT));
};
