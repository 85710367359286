import { CaretSortIcon, CheckIcon } from "@radix-ui/react-icons";
import { keys as k } from "common/mod.ts";
import { Button } from "components/ui/button";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "components/ui/command";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "components/ui/dropdown-menu";
import { Popover, PopoverContent, PopoverTrigger } from "components/ui/popover";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "components/ui/table";
import { cn } from "lib/utils";
import { MoreHorizontal } from "lucide-react";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import EditBalanceModal from "./edit-balance-modal";

export default function UsersTable({ users }) {
  const { t } = useTranslation();
  const [userEmailFilter, setUserEmailFilter] = useState("");
  const [userOpen, setUserOpen] = useState(false);
  const [editBalanceOpen, setEditBalanceOpen] = useState(false);

  const filteredItems = useMemo(() => {
    return users.filter((item) => {
      if (!userEmailFilter) return true;
      return item.email === userEmailFilter;
    });
  }, [users, userEmailFilter]);

  const [selectedUser, setSelectedUser] = useState(null);

  const handleEditBalance = (user) => {
    setSelectedUser(user);
    setEditBalanceOpen(true);
  };

  useEffect(() => {
    if (!editBalanceOpen) {
      setSelectedUser(null);
    }
  }, [editBalanceOpen]);

  return (
    <>
      <div className="flex items-center justify-between mb-4">
        <div className="flex items-center gap-4"></div>
        <div className="flex items-center gap-4">
          <Popover open={userOpen} onOpenChange={setUserOpen}>
            <PopoverTrigger asChild>
              <Button
                variant="outline"
                role="combobox"
                aria-expanded={userOpen}
                className="w-[200px] justify-between"
              >
                {userEmailFilter ? userEmailFilter : t(k.SELECT_USER)}
                <CaretSortIcon className="ml-2 h-4 w-4 shrink-0 opacity-50" />
              </Button>
            </PopoverTrigger>
            <PopoverContent className="w-[200px] p-0">
              <Command>
                <CommandInput placeholder={t(k.SELECT_USER)} className="h-9" />
                <CommandEmpty>{t(k.NO_USER_FOUND)}</CommandEmpty>
                <CommandGroup>
                  <CommandList>
                    {users.map((user) => (
                      <CommandItem
                        key={user.email}
                        value={user.email}
                        onSelect={(email) => {
                          setUserEmailFilter(
                            email === userEmailFilter ? "" : email
                          );
                        }}
                      >
                        {user.email}
                        <CheckIcon
                          className={cn(
                            "ml-auto h-4 w-4",
                            userEmailFilter === user.email
                              ? "opacity-100"
                              : "opacity-0"
                          )}
                        />
                      </CommandItem>
                    ))}
                  </CommandList>
                </CommandGroup>
              </Command>
            </PopoverContent>
          </Popover>
        </div>
      </div>
      <Table className="border-spacing-y-4 border-separate">
        <TableHeader>
          <TableRow>
            <TableHead>{t(k.ID)}</TableHead>
            <TableHead>{t(k.EMAIL)}</TableHead>
            <TableHead className="text-center">{t(k.FULL_NAME)}</TableHead>
            <TableHead className="text-center">{t(k.BALANCE)}</TableHead>
            <TableHead className="text-center">{t(k.ROLE)}</TableHead>
            <TableHead className="md:hidden">{t(k.ACTIONS)}</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {filteredItems.map((user) => (
            <TableRow key={user._id}>
              <TableCell>{user._id}</TableCell>
              <TableCell>{user.email}</TableCell>
              <TableCell className="text-center">
                {user.firstName} {user.lastName}
              </TableCell>
              <TableCell className="text-center">{user.balance}$</TableCell>
              <TableCell className="text-center font-bold">
                {user.role}
              </TableCell>

              <TableCell>
                <DropdownMenu>
                  <DropdownMenuTrigger asChild>
                    <Button aria-haspopup="true" size="icon" variant="ghost">
                      <MoreHorizontal className="h-4 w-4" />
                      <span className="sr-only">{t(k.TOGGLE_MENU)}</span>
                    </Button>
                  </DropdownMenuTrigger>
                  <DropdownMenuContent align="end">
                    <DropdownMenuLabel>{t(k.ACTIONS)}</DropdownMenuLabel>
                    <DropdownMenuSeparator />
                    <DropdownMenuItem onClick={() => handleEditBalance(user)}>
                      {t(k.EDIT_BALANCE)}
                    </DropdownMenuItem>
                  </DropdownMenuContent>
                </DropdownMenu>
              </TableCell>
            </TableRow>
          ))}
          {editBalanceOpen && (
            <EditBalanceModal
              userId={selectedUser._id}
              currentBalance={selectedUser.balance}
              setEditBalanceOpen={setEditBalanceOpen}
            />
          )}
        </TableBody>
      </Table>
    </>
  );
}
