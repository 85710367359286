import { AuthProvider, useAuth } from "providers/AuthProvider";
import React from "react";

import MainLayout from "./components/layout/panel/main-layout";

export default function Layout({ children }) {
  const { hasConnected } = useAuth();
  return (
    hasConnected && (
      <AuthProvider>
        <MainLayout>{children}</MainLayout>
      </AuthProvider>
    )
  );
}
