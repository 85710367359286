import { keys as k } from "common/mod.ts";
import { Button } from "components/ui/button";
import { Calendar } from "components/ui/calendar";
import { Popover, PopoverContent, PopoverTrigger } from "components/ui/popover";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "components/ui/select";
import { endOfDay, format } from "date-fns";
import { dashboardHandler } from "handlers/dashboard";
import i18n from "i18n";
import { t } from "i18next";
import { cn, localeMap } from "lib/utils";
import { CalendarIcon } from "lucide-react";
import React, { useEffect, useState } from "react";
import { socket } from "socket";

import ModemChart from "./modem-chart";

export default function Dashboard() {
  const [date, setDate] = useState(null);
  const [dashboardData, setDashboardData] = useState({});
  const [selectedCountry, setSelectedCountry] = useState("all");
  const [selectedCity, setSelectedCity] = useState("all");
  const [selectedLocation, setSelectedLocation] = useState("all");
  const [modemData, setModemData] = useState([]);
  const [granularity, setGranularity] = useState("daily");
  const [countries, setCountries] = useState([]);
  const [cities, setCities] = useState([]);
  const [locations, setLocations] = useState([]);

  useEffect(() => {
    if (!dashboardData?.modems) return;
    setModemData(dashboardData.modems);
  }, [dashboardData]);

  useEffect(() => {
    if (!modemData?.length) return;
    if (countries?.length) return;
    setCountries([
      ...new Set(
        modemData.reduce((acc, data) => [...acc, ...data.countries], [])
      ),
    ]);
    setCities([
      ...new Set(modemData.reduce((acc, data) => [...acc, ...data.cities], [])),
    ]);
    setLocations([
      ...new Set(
        modemData.reduce((acc, data) => [...acc, ...data.locationNumbers], [])
      ),
    ]);
  }, [countries, modemData]);

  useEffect(() => {
    socket.emit("main:fetch:dashboard", granularity, {
      country: selectedCountry === "all" ? undefined : selectedCountry,
      city: selectedCity === "all" ? undefined : selectedCity,
      location: selectedLocation === "all" ? undefined : selectedLocation,
      startDate: date?.from,
      endDate: date?.to ? endOfDay(date.to) : undefined,
    });
  }, [selectedCountry, selectedCity, selectedLocation, granularity, date]);

  useEffect(
    () => dashboardHandler(socket, setDashboardData),
    [setDashboardData]
  );

  return (
    <>
      <div className="flex space-x-4 mb-4 mt-4">
        <Popover>
          <PopoverTrigger asChild>
            <Button
              id="date"
              variant={"outline"}
              className={cn("w-[300px] justify-start text-left")}
            >
              <CalendarIcon className="mr-2" />
              {date?.from ? (
                date.to ? (
                  <>
                    {format(date.from, "LLL dd, y", {
                      locale: localeMap[i18n.language],
                    })}{" "}
                    -{" "}
                    {format(date.to, "LLL dd, y", {
                      locale: localeMap[i18n.language],
                    })}
                  </>
                ) : (
                  format(date.from, "LLL dd, y", {
                    locale: localeMap[i18n.language],
                  })
                )
              ) : (
                <span>{t(k.SELECT_DATE)}</span>
              )}
            </Button>
          </PopoverTrigger>
          <PopoverContent className="w-auto p-0" align="start">
            <Calendar
              initialFocus
              mode="range"
              defaultMonth={date?.from}
              selected={date}
              onSelect={setDate}
              numberOfMonths={2}
              locale={localeMap[i18n.language]}
            />
          </PopoverContent>
        </Popover>

        <Select
          value={selectedCountry}
          onValueChange={(c) => setSelectedCountry(c)}
        >
          <SelectTrigger className="w-[180px]">
            <SelectValue placeholder={t(k.SELECT_COUNTRY)} />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value="all">{t(k.ALL_COUNTRIES)}</SelectItem>
            {countries.map((country) => (
              <SelectItem key={country} value={country}>
                {t(country, { ns: "countries" })}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
        <Select value={selectedCity} onValueChange={(c) => setSelectedCity(c)}>
          <SelectTrigger className="w-[180px]">
            <SelectValue placeholder={t(k.SELECT_CITY)} />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value="all">{t(k.ALL_CITIES)}</SelectItem>
            {cities.map((city) => (
              <SelectItem key={city} value={city}>
                {t(city, { ns: "cities" })}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
        <Select
          value={selectedLocation}
          onValueChange={(l) => setSelectedLocation(l)}
        >
          <SelectTrigger className="w-[180px]">
            <SelectValue placeholder={t(k.SELECT_LOCATION)} />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value="all">{t(k.ALL_LOCATIONS)}</SelectItem>
            {locations.map((location) => (
              <SelectItem key={location} value={location}>
                #{location}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
        <Select
          value={granularity}
          label={t(k["DASHBOARD_GRANULARITY_" + granularity.toUpperCase()])}
          onValueChange={(g) => setGranularity(g)}
          className="mb-4"
        >
          <SelectTrigger className="w-[180px]">
            <SelectValue placeholder={t(k.DASHBOARD_GRANULARITY_SELECT)} />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value="minutely">
              {t(k.DASHBOARD_GRANULARITY_MINUTELY)}
            </SelectItem>
            <SelectItem value="hourly">
              {t(k.DASHBOARD_GRANULARITY_HOURLY)}
            </SelectItem>
            <SelectItem value="daily">
              {t(k.DASHBOARD_GRANULARITY_DAILY)}
            </SelectItem>
            <SelectItem value="monthly">
              {t(k.DASHBOARD_GRANULARITY_MONTHLY)}
            </SelectItem>
            <SelectItem value="yearly">
              {t(k.DASHBOARD_GRANULARITY_YEARLY)}
            </SelectItem>
          </SelectContent>
        </Select>
      </div>
      <div className="flex space-x-4 mb-4 mt-4">
        <ModemChart
          title={t(k.DASHBOARD_AVAILABILITY_CHART_TITLE)}
          config={{
            free: {
              label: t(k.DASHBOARD_AVAILAILABILITY_FREE),
              color: "hsl(var(--chart-2))",
            },
            busy: {
              label: t(k.DASHBOARD_AVAILAILABILITY_BUSY),
              color: "hsl(var(--chart-1))",
            },
            reserved: {
              label: t(k.DASHBOARD_AVAILAILABILITY_RESERVED),
              color: "hsl(var(--chart-4))",
            },
          }}
          data={modemData}
          granularity={granularity}
        />
        <ModemChart
          title={t(k.DASHBOARD_STATUS_CHART_TITLE)}
          config={{
            available: {
              label: t(k.DASHBOARD_STATUS_AVAILABLE),
              color: "hsl(var(--chart-2))",
            },
            unavailable: {
              label: t(k.DASHBOARD_STATUS_UNAVAILABLE),
              color: "hsl(var(--chart-1))",
            },
            noInternet: {
              label: t(k.DASHBOARD_STATUS_NO_INTERNET),
              color: "hsl(var(--chart-4))",
            },
            disabled: {
              label: t(k.DASHBOARD_STATUS_DISABLED),
              color: "hsl(var(--chart-3))",
            },
          }}
          data={modemData}
          granularity={granularity}
        />
      </div>
    </>
  );
}
