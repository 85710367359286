import { keys as k } from "common/mod.ts";
import { SignalStrengthIcon } from "components/icons/signal";
import { Badge } from "components/ui/badge";
import { Button } from "components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "components/ui/dialog";
import { Input } from "components/ui/input";
import { Separator } from "components/ui/separator";
import { format } from "date-fns";
import {
  DATETIME_FORMAT,
  NAMESPACES,
  occupancyStatusMap,
  statusMap,
  toReadableSize,
  toReadableSpeed,
} from "lib/utils";
import { CopyIcon } from "lucide-react";
import React from "react";
import { useTranslation } from "react-i18next";

import Actions from "./actions";

export default function ProxyModal({ selectedModem, setSelectedModem }) {
  const { t } = useTranslation(Object.values(NAMESPACES));
  const {
    _id,
    internalIP,
    publicIP,
    gatewayIP,
    socks5Port,
    httpPort,
    status,
    occupancyStatus,
    reservedBy,
    reservedUntil,
    count,
    signal,
    interface: modemInterface,
    adminUser,
    session,
    traffic,
    operator,
    number,
    lastReboot,
    server,
    updatedAt,
  } = selectedModem;
  const { username: adminUsername, password: adminPassword } = adminUser;
  const { client, user, usedTraffic, limitTraffic, limitTime } = session || {};
  const { username: clientUsername, password: clientPassword } = user || {};
  const { currentDownloadRate, currentUploadRate } = traffic;
  const { city, country, publicURL, serverNumber, _id: serverId } = server;

  const handleCopy = (text) => {
    navigator.clipboard.writeText(text);
  };

  return (
    <Dialog modal open={selectedModem}>
      <DialogTrigger asChild></DialogTrigger>
      <DialogContent
        closeClick={() => setSelectedModem(null)}
        onEscapeKeyDown={() => setSelectedModem(null)}
        onPointerDownOutside={() => setSelectedModem(null)}
        className="w-full max-w-[70%]"
      >
        <DialogHeader>
          <DialogTitle className="text-center">
            {t(k.MODEM_DETAILS)}
          </DialogTitle>
        </DialogHeader>
        <div className="grid grid-cols-2 gap-6 py-6">
          <div className="relative p-6">
            <h3 className="text-lg font-semibold text-center">
              {t(k.GENERAL)}
            </h3>
            <div className="grid grid-cols-2 gap-4 mt-4">
              <div>
                <div className="text-sm text-muted-foreground">{t(k.ID)}</div>
                <div className="flex items-center gap-2">
                  <span>{_id}</span>
                </div>
              </div>
              <div>
                <div className="text-sm text-muted-foreground">
                  {t(k.SERVER_ID)}
                </div>
                <div className="flex items-center gap-2">
                  <span>{serverId}</span>
                </div>
              </div>
            </div>

            <div className="grid grid-cols-4 gap-4 mt-4">
              <div>
                <div className="text-sm text-muted-foreground">
                  {t(k.OPERATOR)}
                </div>
                <div className="flex items-center gap-2">
                  <span>{operator}</span>
                </div>
              </div>
              <div>
                <div className="text-sm text-muted-foreground">
                  {t(k.NUMBER)}
                </div>
                <div className="flex items-center gap-2">
                  <span>{number}</span>
                </div>
              </div>
              <div>
                <div className="text-sm text-muted-foreground">
                  {t(k.SIGNAL)}
                </div>
                <SignalStrengthIcon
                  signal={signal}
                  className="w-[25px] text-left"
                />
              </div>
            </div>

            <div className="grid grid-cols-4 gap-4 mt-4">
              <div>
                <div className="text-sm text-muted-foreground">
                  {t(k.COUNTRY)}
                </div>
                <div className="flex items-center gap-2">
                  <span>{t(country, { ns: "countries" })}</span>
                </div>
              </div>
              <div>
                <div className="text-sm text-muted-foreground">{t(k.CITY)}</div>
                <div className="flex items-center gap-2">
                  <span>{t(city, { ns: "cities" })}</span>
                </div>
              </div>
              <div>
                <div className="text-sm text-muted-foreground">
                  {t(k.LOCATION_NUMBER)}
                </div>
                <div className="flex items-center gap-2">
                  <span>{serverNumber}</span>
                </div>
              </div>
            </div>

            <div className="grid grid-cols-4 gap-4 mt-4">
              <div>
                <div className="text-sm text-muted-foreground">
                  {t(k.PUBLIC_IP)}
                </div>
                <div className="flex items-center gap-2">
                  <span>{publicIP}</span>
                </div>
              </div>
              <div>
                <div className="text-sm text-muted-foreground">
                  {t(k.INTERNAL_IP)}
                </div>
                <div className="flex items-center gap-2">
                  <span>{internalIP}</span>
                </div>
              </div>
              <div>
                <div className="text-sm text-muted-foreground">
                  {t(k.GATEWAY_IP)}
                </div>
                <div className="flex items-center gap-2">
                  <span>{gatewayIP}</span>
                </div>
              </div>
              <div>
                <div className="text-sm text-muted-foreground">
                  {t(k.INTERFACE)}
                </div>
                <div>{modemInterface}</div>
              </div>
            </div>
            <div className="grid grid-cols-4 gap-4 mt-4">
              <div>
                <div className="text-sm text-muted-foreground">
                  {t(k.STATUS)}
                </div>
                <div className="flex items-center gap-2">
                  <Badge
                    className={`text-xs ${statusMap[status].color} relative`}
                    variant="outline"
                  >
                    {t(status.toUpperCase(), { ns: "statuses" })}
                  </Badge>
                </div>
              </div>
              <div>
                <div className="text-sm text-muted-foreground">
                  {t(k.OCCUPANCY)}
                </div>
                <div className="flex items-center gap-2">
                  <Badge
                    className={`text-xs ${occupancyStatusMap[occupancyStatus].color} relative`}
                    variant="outline"
                  >
                    {t(occupancyStatus.toUpperCase(), { ns: "statuses" })}
                  </Badge>
                </div>
              </div>
              <div>
                <div className="text-sm text-muted-foreground">
                  {t(k.COUNT)}
                </div>
                <div>{count}</div>
              </div>
            </div>

            <div className="grid grid-cols-2 gap-4 mt-4">
              <div>
                <div className="text-sm text-muted-foreground">
                  {t(k.LAST_REBOOT)}
                </div>
                <div className="flex items-center gap-2">
                  <span>{format(lastReboot, DATETIME_FORMAT)}</span>
                </div>
              </div>
              <div>
                <div className="text-sm text-muted-foreground">
                  {t(k.LAST_UPDATED)}
                </div>
                <div className="flex items-center gap-2">
                  <span> {format(updatedAt, DATETIME_FORMAT)}</span>
                </div>
              </div>
            </div>
            <Separator
              orientation="vertical"
              className="invisible md:visible top-0 right-0 absolute h-[90%] my-auto"
            />
          </div>

          <div className="flex flex-col relative p-6">
            <h3 className="text-lg font-semibold text-center">
              {t(k.SESSION)}
            </h3>
            {reservedBy && (
              <div className="grid grid-cols-2 mt-4">
                <div>
                  <div className="text-sm text-muted-foreground">
                    {t(k.RESERVED_BY)}
                  </div>
                  <span>{reservedBy} </span>
                </div>
                <div>
                  <div className="text-sm text-muted-foreground">
                    {t(k.RESERVED_UNTIL)}
                  </div>
                  <span> {format(reservedUntil, DATETIME_FORMAT)}</span>
                </div>
              </div>
            )}

            {client && (
              <>
                <div className="grid grid-cols-2 mt-4">
                  <div>
                    <div className="text-sm text-muted-foreground">
                      {t(k.PURCHASED_BY)}
                    </div>
                    <span>{client.email}</span>
                  </div>
                  <div>
                    <div className="text-sm text-muted-foreground">
                      {t(k.PURCHASED_UNTIL)}
                    </div>
                    <div className="flex items-baseline gap-2">
                      <span>{format(limitTime, DATETIME_FORMAT)}</span>

                      <Button size="sm" className="mt-2" disable>
                        {t(k.EXTEND)}
                      </Button>
                    </div>
                  </div>
                </div>
                <div className="grid grid-cols-2 mt-4">
                  <div>
                    <div className="text-sm text-muted-foreground">
                      {t(k.USED_TRAFFIC)}
                    </div>
                    <span>{toReadableSize(usedTraffic)}</span>
                  </div>
                  <div>
                    <div>
                      <div className="text-sm text-muted-foreground">
                        {t(k.LIMIT_TRAFFIC)}
                      </div>
                      <span>{toReadableSize(limitTraffic)}</span>
                    </div>
                  </div>
                </div>
                <div className="grid grid-cols-2 mt-4">
                  <div>
                    <div>
                      <div className="text-sm text-muted-foreground">
                        {t(k.LATEST_DOWNLOAD_SPEED)}
                      </div>
                      <span>{toReadableSpeed(currentDownloadRate)}</span>
                    </div>
                  </div>
                  <div>
                    <div>
                      <div className="text-sm text-muted-foreground">
                        {t(k.LATEST_UPLOAD_SPEED)}
                      </div>
                      <span>{toReadableSpeed(currentUploadRate)}</span>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>

          <div className="flex flex-col gap-4 relative py-6">
            <Separator
              orientation="horizontal"
              className="invisible md:visible absolute w-[90%] mx-auto"
            />

            <h3 className="text-lg font-semibold text-center mt-8 mb-8">
              {t(k.CONNECTION_DETAILS)}
            </h3>
            <div className="flex items-center gap-2">
              <div className="w-40 text-sm text-muted-foreground">
                {t(k.URL)}
              </div>
              <Input
                className="w-25"
                id="url"
                defaultValue={publicURL}
                disabled
              />

              <Button
                variant="ghost"
                size="sm"
                onClick={() => handleCopy(publicURL)}
              >
                <CopyIcon className="h-4 w-4" />
                <span className="sr-only">{t(k.COPY_URL)}</span>
              </Button>
            </div>
            <div className="flex items-center gap-2">
              <div className="w-40 text-sm text-muted-foreground">
                {t(k.HTTP_PORT)}
              </div>
              <Input
                className="w-25"
                id="httpPort"
                defaultValue={httpPort}
                disabled
              />

              <Button
                variant="ghost"
                size="icon"
                onClick={() => handleCopy(httpPort)}
              >
                <CopyIcon className="h-4 w-4" />
                <span className="sr-only">{t(k.COPY_HTTP_PORT)}</span>
              </Button>
            </div>
            <div className="flex items-center gap-2">
              <div className="w-40 text-sm text-muted-foreground">
                {t(k.SOCKS_PORT)}
              </div>
              <Input
                className="w-25"
                id="socks5Port"
                defaultValue={socks5Port}
                disabled
              />

              <Button
                variant="ghost"
                size="icon"
                onClick={() => handleCopy(socks5Port)}
              >
                <CopyIcon className="h-4 w-4" />
                <span className="sr-only">{t(k.COPY_SOCKS_PORT)}</span>
              </Button>
            </div>

            <div className="flex items-center gap-2">
              <div className="w-40 text-sm text-muted-foreground">
                {t(k.ADMIN_USERNAME)}
              </div>
              <Input
                className="w-25"
                id="username"
                defaultValue={adminUsername}
                disabled
              />

              <Button
                variant="ghost"
                size="icon"
                onClick={() => handleCopy(adminUsername)}
              >
                <CopyIcon className="h-4 w-4" />
                <span className="sr-only">{t(k.COPY_USERNAME)}</span>
              </Button>
              <Button
                variant="outline"
                size="sm"
                onClick={() =>
                  handleCopy(
                    `${adminUsername}:${adminPassword}@${publicURL}:${httpPort}`
                  )
                }
              >
                {t(k.COPY_HTTP_LOGIN)}
                <CopyIcon className="h-4 w-4 ml-2" />
              </Button>
            </div>
            <div className="flex items-center gap-2">
              <div className="w-40 text-sm text-muted-foreground">
                {t(k.ADMIN_PASSWORD)}
              </div>
              <Input
                className="w-25"
                id="password"
                defaultValue={adminPassword}
                disabled
              />
              <Button
                variant="ghost"
                size="icon"
                onClick={() => handleCopy(adminPassword)}
              >
                <CopyIcon className="h-4 w-4" />

                <span className="sr-only">{t(k.COPY_PASSWORD)}</span>
              </Button>
              <Button
                variant="outline"
                size="sm"
                onClick={() =>
                  handleCopy(
                    `${adminUsername}:${adminPassword}@${publicURL}:${socks5Port}`
                  )
                }
              >
                {t(k.COPY_SOCKS_LOGIN)}
                <CopyIcon className="h-4 w-4 ml-2" />
              </Button>
            </div>
            {client && (
              <>
                <div className="flex items-center gap-2">
                  <div className="w-40 text-sm text-muted-foreground">
                    {t(k.CLIENT_USERNAME)}
                  </div>
                  <Input
                    className="w-25"
                    id="username"
                    defaultValue={clientUsername}
                    disabled
                  />

                  <Button
                    variant="ghost"
                    size="icon"
                    onClick={() => handleCopy(clientUsername)}
                  >
                    <CopyIcon className="h-4 w-4" />
                    <span className="sr-only">{t(k.COPY_USERNAME)}</span>
                  </Button>
                  <Button
                    variant="outline"
                    size="sm"
                    onClick={() =>
                      handleCopy(
                        `${clientUsername}:${clientPassword}@${publicURL}:${httpPort}`
                      )
                    }
                  >
                    {t(k.COPY_HTTP_LOGIN)}
                    <CopyIcon className="h-4 w-4 ml-2" />
                  </Button>
                </div>
                <div className="flex items-center gap-2">
                  <div className="w-40 text-sm text-muted-foreground">
                    {t(k.CLIENT_PASSWORD)}
                  </div>
                  <Input
                    className="w-25"
                    id="password"
                    defaultValue={clientPassword}
                    disabled
                  />

                  <Button
                    variant="ghost"
                    size="icon"
                    onClick={() => handleCopy(clientPassword)}
                  >
                    <CopyIcon className="h-4 w-4" />

                    <span className="sr-only">{t(k.COPY_PASSWORD)}</span>
                  </Button>
                  <Button
                    variant="outline"
                    size="sm"
                    onClick={() =>
                      handleCopy(
                        `${clientUsername}:${clientPassword}@${publicURL}:${socks5Port}`
                      )
                    }
                  >
                    {t(k.COPY_SOCKS_LOGIN)}
                    <CopyIcon className="h-4 w-4 ml-2" />
                  </Button>
                </div>
              </>
            )}
            <Separator
              orientation="vertical"
              className="invisible md:visible right-0 bottom-0 absolute h-[90%] my-auto"
            />
          </div>
          <Actions selectedModem={selectedModem} />
        </div>

        <DialogFooter>
          <div>
            <Button type="button" onClick={() => setSelectedModem(null)}>
              {t(k.CLOSE)}
            </Button>
          </div>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
