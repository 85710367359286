import { cn } from "lib/utils";
import React from "react";
// SignalStrengthIcon component accepting a signal prop
export const SignalStrengthIcon = (props) => {
  const { signal, ...rest } = props;
  let color;
  switch (signal) {
    case 1:
      color = "fill-destructive";
      break;
    case 2:
      color = "fill-destructive";
      break;
    case 3:
      color = "fill-yellow";
      break;
    case 4:
      color = "fill-green";
      break;
    case 5:
      color = "fill-green";
      break;
    default:
      color = "fill-outline opacity-20";
  }

  return (
    <svg
      viewBox="0 0 120 75"
      xmlns="http://www.w3.org/2000/svg"
      className={cn(rest.className)}
    >
      {/* Signal strength bars */}
      {Array.from({ length: signal }).map((_, i) => (
        <rect
          key={i}
          x={i * 26}
          y={60 - i * 15}
          width={18}
          height={(i + 1) * 15}
          className={cn("transition-transform duration-300 ease-in-out", color)}
        />
      ))}
      {Array.from({ length: 5 - signal }).map((_, i) => (
        <rect
          key={i}
          x={(signal + i) * 26}
          y={60 - (signal + i) * 15}
          width={18}
          height={(signal + i + 1) * 15}
          className={cn(
            "transition-transform duration-300 ease-in-out",
            "fill-outline opacity-20",
          )}
        />
      ))}
    </svg>
  );
};
