import { keys as k } from "common/mod.ts";
import AdminServersTable from "components/layout/admin/servers";
import { ContentLayout } from "components/layout/panel/content-layout";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from "components/ui/breadcrumb";
import { Card, CardContent, CardFooter, CardHeader } from "components/ui/card";
import { Spinner } from "components/ui/spinner";
import {
  deleteServerHandler,
  serverHandler,
  updateServerHandler,
} from "handlers/servers";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { socket } from "socket";

export default function AdminProxies() {
  const { t } = useTranslation();
  const [servers, setServers] = useState([]);
  const [spinner, setSpinner] = useState(true);

  useEffect(() => {
    socket.emit("main:fetch:servers");
  }, []);
  useEffect(() => serverHandler(socket, setServers, setSpinner), []);
  useEffect(() => updateServerHandler(socket, setServers), []);
  useEffect(() => deleteServerHandler(socket, setServers), []);

  return (
    <ContentLayout title={t(k.SERVERS)}>
      <Card className="rounded-lg border-none mt-6">
        <CardHeader>
          <Breadcrumb>
            <BreadcrumbList>
              <BreadcrumbItem>
                <BreadcrumbPage>{t(k.ADMIN_PANEL)}</BreadcrumbPage>
              </BreadcrumbItem>
              <BreadcrumbSeparator />
              <BreadcrumbItem>
                <BreadcrumbPage>{t(k.SERVERS)}</BreadcrumbPage>
              </BreadcrumbItem>
            </BreadcrumbList>
          </Breadcrumb>
        </CardHeader>
        <CardContent>
          {spinner && <Spinner className="text-primary flex justify-center" />}
          {servers.length ? (
            <AdminServersTable servers={servers} />
          ) : (
            <p className="text-center text-muted-foreground">
              {t(k.NO_SERVERS_AVAILABLE)}
            </p>
          )}
        </CardContent>
        <CardFooter></CardFooter>
      </Card>
    </ContentLayout>
  );
}
