import "./index.css";
import "./i18n";

import * as Sentry from "@sentry/browser";
import React from "react";
import ReactDOM from "react-dom/client";

import App from "./App";

// eslint-disable-next-line no-undef
if (process.env.NODE_ENV === "production") {
  Sentry.init({
    // eslint-disable-next-line no-undef
    dsn: process.env.REACT_APP_GLITCHTIP_DNS,
  });
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);
