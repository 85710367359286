import { keys as k } from "common/mod.ts";
import { Badge } from "components/ui/badge";
import { Button } from "components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuRadioGroup,
  DropdownMenuRadioItem,
  DropdownMenuTrigger,
} from "components/ui/dropdown-menu";
import { Label } from "components/ui/label";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "components/ui/table";
import { NAMESPACES } from "lib/utils";
import { ChevronDownIcon } from "lucide-react";
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

export default function PaymentHistoryTable({ items }) {
  const { t } = useTranslation([NAMESPACES.GENERAL, NAMESPACES.STATUSES]);
  const [sortBy, setSortBy] = useState("date");
  const [sortOrder, setSortOrder] = useState("desc");
  const [filterStatus, setFilterStatus] = useState("all");

  const filteredItems = useMemo(() => {
    return items
      .filter((item) => {
        if (filterStatus === "all") return true;
        return item.status === filterStatus;
      })
      .sort((a, b) => {
        const currentSortBy = sortBy === "date" ? "updatedAt" : "status";
        if (sortOrder === "asc") {
          return a[currentSortBy] > b[currentSortBy] ? 1 : -1;
        } else {
          return a[currentSortBy] < b[currentSortBy] ? 1 : -1;
        }
      });
  }, [items, sortBy, sortOrder, filterStatus]);

  return (
    <>
      <div className="flex items-center justify-between mb-4">
        <div className="flex items-center gap-4">
          <Label>{t(k.SORT_BY)}</Label>

          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button variant="outline" className="flex items-center gap-2">
                <span>{t("SORT_BY_" + sortBy.toUpperCase())}</span>
                <ChevronDownIcon className="w-4 h-4" />
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="start">
              <DropdownMenuRadioGroup value={sortBy} onValueChange={setSortBy}>
                <DropdownMenuRadioItem value="date">
                  {t(k.SORT_BY_DATE)}
                </DropdownMenuRadioItem>
                <DropdownMenuRadioItem value="status">
                  {t(k.SORT_BY_STATUS)}
                </DropdownMenuRadioItem>
              </DropdownMenuRadioGroup>
            </DropdownMenuContent>
          </DropdownMenu>
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button variant="outline" className="flex items-center gap-2">
                <span>
                  {sortOrder === "asc" ? t(k.ASCENDING) : t(k.DESCENDING)}
                </span>
                <ChevronDownIcon className="w-4 h-4" />
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="start">
              <DropdownMenuRadioGroup
                value={sortOrder}
                onValueChange={setSortOrder}
              >
                <DropdownMenuRadioItem value="asc">
                  {t(k.ASCENDING)}
                </DropdownMenuRadioItem>
                <DropdownMenuRadioItem value="desc">
                  {t(k.DESCENDING)}
                </DropdownMenuRadioItem>
              </DropdownMenuRadioGroup>
            </DropdownMenuContent>
          </DropdownMenu>
        </div>
        <div className="flex items-center gap-4">
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button variant="outline" className="flex items-center gap-2">
                <span>
                  {filterStatus === "all"
                    ? t(k.STATUS)
                    : t(filterStatus.toUpperCase(), { ns: "statuses" })
                        .charAt(0)
                        .toUpperCase() +
                      t(filterStatus.toUpperCase(), { ns: "statuses" }).slice(
                        1
                      )}
                </span>
                <ChevronDownIcon className="w-4 h-4" />
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="start">
              <DropdownMenuRadioGroup
                value={filterStatus}
                onValueChange={setFilterStatus}
              >
                <DropdownMenuRadioItem value="all">
                  {t(k.STATUS)}
                </DropdownMenuRadioItem>
                {[...new Set(items.map((item) => item.status))].map(
                  (status) => (
                    <DropdownMenuRadioItem key={status} value={status}>
                      {t(status.toUpperCase(), { ns: "statuses" })
                        .charAt(0)
                        .toUpperCase() +
                        t(status.toUpperCase(), { ns: "statuses" }).slice(1)}
                    </DropdownMenuRadioItem>
                  )
                )}
              </DropdownMenuRadioGroup>
            </DropdownMenuContent>
          </DropdownMenu>
        </div>
      </div>
      <Table className="border-spacing-y-4 border-separate">
        <TableHeader>
          <TableRow>
            <TableHead>{t(k.LAST_UPDATED)}</TableHead>
            <TableHead className="text-center">{t(k.AMOUNT)}</TableHead>
            <TableHead className="text-center">{t(k.REFUND_AMOUNT)}</TableHead>
            <TableHead className="text-center">{t(k.STATUS)}</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {filteredItems.map((payment) => (
            <TableRow key={payment._id}>
              <TableCell>
                <div>{new Date(payment.updatedAt).toLocaleDateString()}</div>
              </TableCell>
              <TableCell className="text-center">
                <div>${payment.amount}</div>
              </TableCell>
              <TableCell className="text-center">
                <div>${payment.refundAmount}</div>
              </TableCell>

              <TableCell className="text-center">
                <Badge
                  variant={
                    payment.status === "completed" ? "secondary" : "outline"
                  }
                  className="text-sm"
                >
                  {t(payment.status.toUpperCase(), { ns: "statuses" })
                    .charAt(0)
                    .toUpperCase() +
                    t(payment.status.toUpperCase(), { ns: "statuses" }).slice(
                      1
                    )}
                </Badge>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </>
  );
}
