const userModemsHandler = (socket, setModems, setSpinner) => {
  function handler(payload) {
    setModems(() => payload);
    setSpinner(false);
  }
  socket.on("web:data:user-modems", handler);
  return () => {
    socket.off("web:data:user-modems", handler);
  };
};

const updateUserModemHandler = (socket, setModems) => {
  function handler(updatedModem) {
    setModems((prevUserModems) => {
      const currentUserModems = [...prevUserModems];
      const index = currentUserModems.findIndex(
        (modem) => modem._id === updatedModem._id,
      );
      if (index === -1) return [...prevUserModems, updatedModem];
      else
        return prevUserModems.map((modem) =>
          modem._id === updatedModem._id ? updatedModem : modem,
        );
    });
  }
  socket.on("web:action:update-user-modem", handler);
  return () => {
    socket.off("web:action:update-user-modem", handler);
  };
};

const deleteUserModemHandler = (socket, setModems) => {
  function handler(modemId) {
    setModems((prevUserModems) =>
      prevUserModems.filter((modem) => modem._id !== modemId),
    );
  }
  socket.on("web:action:delete-user-modem", handler);
  return () => {
    socket.off("web:action:delete-user-modem", handler);
  };
};

export { deleteUserModemHandler, updateUserModemHandler, userModemsHandler };
