import { keys as k } from "common/mod.ts";
import AutoForm, { AutoFormSubmit } from "components/auto-form";
import { Button } from "components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "components/ui/dialog";
import React from "react";
import { useTranslation } from "react-i18next";
import { z } from "zod";

import { changeModemDHCP } from "./actions-handler";

export default function DHCPModal({ modemId, modemGatewayIP, setDhcpOpen }) {
  const { t } = useTranslation();
  const gatewayIPSchema = z.object({
    newModemGatewayIP: z
      .string()
      .ip({
        message: "Invalid IP address",
      })
      .default(modemGatewayIP)
      .refine((ip) => ip.startsWith("192.168"), {
        message: "Gateway IP must start with 192.168.X.X",
      })
      .refine((ip) => ip.endsWith(".1"), {
        message: "Gateway IP must end with .1",
      })
      .refine((ip) => ip !== modemGatewayIP, {
        message: "New IP must be different from the current IP",
      })
      .describe(t(k.NEW_GATEWAY_IP)),
  });

  const onSubmit = async (values) => {
    const { newModemGatewayIP } = values;
    changeModemDHCP(modemId, newModemGatewayIP, setDhcpOpen);
  };

  return (
    <Dialog defaultOpen>
      <DialogTrigger></DialogTrigger>
      <DialogContent
        closeClick={() => setDhcpOpen(false)}
        onEscapeKeyDown={() => setDhcpOpen(false)}
        onPointerDownOutside={() => setDhcpOpen(false)}
      >
        <DialogHeader>
          <DialogTitle>{t(k.CHANGE_MODEM_DHCP)}</DialogTitle>
        </DialogHeader>

        <div className="flex flex-col gap-4">
          <div>
            <p className="text-sm text-gray-500 mt-2">
              {t(k.CHANGE_MODEM_DHCP_WARNING)}
            </p>
          </div>
          <AutoForm
            onSubmit={onSubmit}
            formSchema={gatewayIPSchema}
            mode="onChange"
            fieldConfig={{
              newModemGatewayIP: {
                placeholder: "192.168.x.1",
                defaultValue: modemGatewayIP,
                autofocus: true,
              },
            }}
          >
            <DialogFooter>
              <div className="flex gap-4">
                <Button
                  type="button"
                  onClick={() => setDhcpOpen(false)}
                  variant="outline"
                >
                  {t(k.CANCEL)}
                </Button>
                <AutoFormSubmit>{t(k.CHANGE_DHCP)}</AutoFormSubmit>
              </div>
            </DialogFooter>
          </AutoForm>
        </div>
      </DialogContent>
    </Dialog>
  );
}
