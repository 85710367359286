import { keys as k } from "common/mod.ts";
import { t } from "i18next";
import {
  BarChart2 as Statistics,
  DollarSign,
  Headset,
  History,
  LayoutDashboard,
  Rss,
  Server,
  Settings,
  ShoppingCart,
  TicketX,
  User,
  Waypoints,
} from "lucide-react";

import roles from "./roles";

export function getMenuList(pathname, role) {
  const adminMenu = {
    groupLabel: t(k.ADMIN_PANEL),
    menus: [
      {
        href: "/admin/dashboard",
        label: t(k.DASHBOARD),
        active: pathname.includes("/admin/dashboard"),
        icon: LayoutDashboard,
        submenus: [],
      },
      {
        href: "/admin/plans",
        label: t(k.PLANS),
        active: pathname.includes("/admin/plans"),
        icon: Settings,
        submenus: [],
      },
      {
        href: "/admin/proxies",
        label: t(k.PROXIES),
        active: pathname.includes("/admin/proxies"),
        icon: Waypoints,
        submenus: [],
      },
      {
        href: "/admin/servers",
        label: t(k.SERVERS),
        active: pathname.includes("/admin/servers"),
        icon: Server,
        submenus: [],
      },
      {
        href: "/admin/users",
        label: t(k.USERS),
        active: pathname.includes("/admin/users"),
        icon: User,
        submenus: [],
      },
      {
        href: "/admin/coupons",
        label: t(k.COUPONS),
        active: pathname.includes("/admin/coupons"),
        icon: TicketX,
        submenus: [],
      },
      {
        href: "",
        label: t(k.HISTORY),
        active: false,
        icon: History,
        submenus: [
          {
            href: "/admin/history/payment",
            icon: DollarSign,
            label: t(k.PAYMENT),
            active: pathname.includes("/admin/history/payment"),
          },
        ],
      },
    ],
  };
  const menuList = [
    {
      groupLabel: t(k.GENERAL),
      menus: [
        {
          href: "/purchase",
          label: t(k.PURCHASE),
          active: pathname.includes("/purchase"),
          icon: ShoppingCart,
          submenus: [],
        },
        {
          href: "/proxies",
          label: t(k.PROXIES),
          active: pathname.includes("/proxies"),
          icon: Waypoints,
          submenus: [],
        },
        {
          href: "/ip-statistics",
          label: t(k.IP_STATISTICS),
          active: pathname.includes("/ip-statistics"),
          icon: Statistics,
          submenus: [],
        },
      ],
    },
    {
      groupLabel: t(k.HISTORY),
      menus: [
        {
          href: "/history/proxy",
          label: t(k.PROXIES),
          active: pathname.includes("/history/proxy"),
          icon: Waypoints,
          submenus: [],
        },
        {
          href: "/history/payment",
          label: t(k.PAYMENT),
          active: pathname.includes("/history/payment"),
          icon: DollarSign,
          submenus: [],
        },
      ],
    },

    {
      groupLabel: t(k.SETTINGS),
      menus: [
        {
          href: "/account",
          label: t(k.ACCOUNT),
          active: pathname.includes("/account"),
          icon: Settings,
          submenus: [],
        },
        {
          href: "/refill",
          label: t(k.REFILL),
          active: pathname.includes("/refill"),
          icon: DollarSign,
          submenus: [],
        },
      ],
    },
    {
      groupLabel: t(k.CONTACT),
      menus: [
        {
          href: t(k.TELEGRAM_OFFICIAL_LINK),
          label: t(k.TELEGRAM_OFFICIAL),
          active: false,
          icon: Rss,
          submenus: [],
        },
        {
          href: t(k.TELEGRAM_SUPPORT_LINK),
          label: t(k.TELEGRAM_SUPPORT),
          active: false,
          icon: Headset,
          submenus: [],
        },
      ],
    },
  ];

  return role === roles.ADMIN ? [adminMenu, ...menuList] : menuList;
}
