import { t } from "i18next";

const notificationsHandler = (socket, toast) => {
  socket.on("notification:success", (message, params) =>
    toast.info(t(message, params)),
  );
  socket.on("notification:error", (message, params) =>
    toast.error(t(message, params)),
  );
  return () => {
    socket.off("web:notification:info");
    socket.off("web:notification:error");
  };
};

export { notificationsHandler };
