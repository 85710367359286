import { keys as k } from "common/mod.ts";
import { Badge } from "components/ui/badge";
import { Button } from "components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuRadioGroup,
  DropdownMenuRadioItem,
  DropdownMenuTrigger,
} from "components/ui/dropdown-menu";
import { Label } from "components/ui/label";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "components/ui/table";
import { NAMESPACES } from "lib/utils";
import { ChevronDownIcon } from "lucide-react";
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

export default function ProxyHistoryTable({ items }) {
  const { t } = useTranslation(Object.values(NAMESPACES));
  const [sortBy, setSortBy] = useState("date");
  const [sortOrder, setSortOrder] = useState("desc");
  const [filterStatus, setFilterStatus] = useState("all");
  const [filterLocation, setFilterLocation] = useState("all");

  const filteredItems = useMemo(() => {
    return items
      .filter((item) => {
        if (filterStatus === "all") return true;
        return item.status.toLowerCase() === filterStatus;
      })
      .filter((item) => {
        if (filterLocation === "all") return true;
        const [city, country] = filterLocation.split(", ");
        return item.country === country && item.city === city;
      })
      .sort((a, b) => {
        const currentSortBy = sortBy === "date" ? "createdAt" : "status";
        if (sortOrder === "asc") {
          return a[currentSortBy] > b[currentSortBy] ? 1 : -1;
        } else {
          return a[currentSortBy] < b[currentSortBy] ? 1 : -1;
        }
      });
  }, [items, sortBy, sortOrder, filterStatus, filterLocation]);

  return (
    <>
      <div className="flex items-center justify-between mb-4">
        <div className="flex items-center gap-4">
          <Label>{t(k.SORT_BY)}</Label>
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button variant="outline" className="flex items-center gap-2">
                <span>
                  <span>{t("SORT_BY_" + sortBy.toUpperCase())}</span>
                </span>
                <ChevronDownIcon className="w-4 h-4" />
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="start">
              <DropdownMenuRadioGroup value={sortBy} onValueChange={setSortBy}>
                <DropdownMenuRadioItem value="date">
                  {t(k.SORT_BY_DATE)}
                </DropdownMenuRadioItem>
                <DropdownMenuRadioItem value="status">
                  {t(k.SORT_BY_STATUS)}
                </DropdownMenuRadioItem>
              </DropdownMenuRadioGroup>
            </DropdownMenuContent>
          </DropdownMenu>
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button variant="outline" className="flex items-center gap-2">
                <span>
                  {sortOrder === "asc" ? t(k.ASCENDING) : t(k.DESCENDING)}
                </span>
                <ChevronDownIcon className="w-4 h-4" />
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="start">
              <DropdownMenuRadioGroup
                value={sortOrder}
                onValueChange={setSortOrder}
              >
                <DropdownMenuRadioItem value="asc">
                  {t(k.ASCENDING)}
                </DropdownMenuRadioItem>
                <DropdownMenuRadioItem value="desc">
                  {t(k.DESCENDING)}
                </DropdownMenuRadioItem>
              </DropdownMenuRadioGroup>
            </DropdownMenuContent>
          </DropdownMenu>
        </div>
        <div className="flex items-center gap-4">
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button variant="outline" className="flex items-center gap-2">
                <span>
                  {filterStatus === "all"
                    ? t(k.STATUS)
                    : t(filterStatus.toUpperCase(), { ns: "statuses" })
                        .charAt(0)
                        .toUpperCase() +
                      t(filterStatus.toUpperCase(), { ns: "statuses" }).slice(
                        1
                      )}
                </span>
                <ChevronDownIcon className="w-4 h-4" />
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="start">
              <DropdownMenuRadioGroup
                value={filterStatus}
                onValueChange={setFilterStatus}
              >
                <DropdownMenuRadioItem value="all">
                  {t(k.STATUS)}
                </DropdownMenuRadioItem>
                {[...new Set(items.map((item) => item.status))].map(
                  (status) => (
                    <DropdownMenuRadioItem key={status} value={status}>
                      {t(status.toUpperCase(), { ns: "statuses" })
                        .charAt(0)
                        .toUpperCase() +
                        t(status.toUpperCase(), { ns: "statuses" }).slice(1)}
                    </DropdownMenuRadioItem>
                  )
                )}
              </DropdownMenuRadioGroup>
            </DropdownMenuContent>
          </DropdownMenu>
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button variant="outline" className="flex items-center gap-2">
                <span>
                  {filterLocation === "all"
                    ? t(k.LOCATIONS)
                    : t(filterLocation.split(", ")[0], { ns: "cities" }) +
                      ", " +
                      t(filterLocation.split(", ")[1], { ns: "countries" })}
                </span>
                <ChevronDownIcon className="w-4 h-4" />
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="start">
              <DropdownMenuRadioGroup
                value={filterLocation}
                onValueChange={setFilterLocation}
              >
                <DropdownMenuRadioItem value="all">
                  {t(k.LOCATIONS)}
                </DropdownMenuRadioItem>
                {[
                  ...new Set(
                    items.map(({ city, country }) => {
                      return `${city}, ${country}`;
                    })
                  ),
                ].map((location) => (
                  <DropdownMenuRadioItem key={location} value={location}>
                    {t(location.split(", ")[0], { ns: "cities" })},{" "}
                    {t(location.split(", ")[1], { ns: "countries" })}
                  </DropdownMenuRadioItem>
                ))}
              </DropdownMenuRadioGroup>
            </DropdownMenuContent>
          </DropdownMenu>
        </div>
      </div>
      <Table className="border-spacing-y-4 border-separate">
        <TableHeader>
          <TableRow>
            <TableHead>{t(k.LAST_UPDATED)}</TableHead>
            <TableHead>{t(k.LOCATION)}</TableHead>
            <TableHead>{t(k.QUANTITY)}</TableHead>
            <TableHead>{t(k.PERIOD)}</TableHead>
            <TableHead className="text-right">{t(k.PRICE_PER_ITEM)}</TableHead>
            <TableHead className="text-right">{t(k.TOTAL)}</TableHead>
            <TableHead className="text-center">{t(k.STATUS)}</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {filteredItems.map((item) => (
            <TableRow key={item.id}>
              <TableCell>
                <div>{new Date(item.updatedAt).toLocaleDateString()}</div>
              </TableCell>
              <TableCell>
                <div>
                  {t(item.city, { ns: "cities" })},{" "}
                  {t(item.country, { ns: "countries" })}
                </div>
              </TableCell>
              <TableCell>
                <div>{item.quantity}</div>
              </TableCell>

              <TableCell>
                <div>
                  {item.period} {t(k.DAYS)}
                </div>
              </TableCell>
              <TableCell>
                <div className="text-right">
                  ${(item.total / item.quantity).toFixed(2)}
                </div>
              </TableCell>
              <TableCell>
                <div className="text-right">${item.total.toFixed(2)}</div>
              </TableCell>
              <TableCell className="text-center">
                <Badge
                  className="text-xs capitalize"
                  variant={
                    item.status.toLowerCase() === "completed"
                      ? "primary"
                      : "destructive"
                  }
                >
                  {t(item.status.toUpperCase(), { ns: "statuses" })}
                </Badge>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </>
  );
}
