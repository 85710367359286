import { CaretSortIcon, CheckIcon } from "@radix-ui/react-icons";
import { keys as k } from "common/mod.ts";
import { Badge } from "components/ui/badge";
import { Button } from "components/ui/button";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "components/ui/command";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from "components/ui/dialog";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuRadioGroup,
  DropdownMenuRadioItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "components/ui/dropdown-menu";
import { Input } from "components/ui/input";
import { Label } from "components/ui/label";
import { Popover, PopoverContent, PopoverTrigger } from "components/ui/popover";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "components/ui/table";
import { cn, NAMESPACES } from "lib/utils";
import { ChevronDownIcon, MoreHorizontal } from "lucide-react";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { socket } from "socket";
import { toast } from "sonner";

export default function AdminPaymentHistoryTable({ items }) {
  const { t } = useTranslation([NAMESPACES.GENERAL, NAMESPACES.STATUSES]);
  const [refundPayment, setRefundPayment] = useState(null);
  const [maxRefundAmount, setMaxRefundAmount] = useState(0);
  const [refundBalance, setRefundBalance] = useState(0);
  const [sortBy, setSortBy] = useState("date");
  const [sortOrder, setSortOrder] = useState("desc");
  const [filterStatus, setFilterStatus] = useState("all");
  const [userOpen, setUserOpen] = useState(false);
  const [userFilter, setUserFilter] = React.useState("");

  const users = useMemo(() => {
    return [...new Set(items.map((item) => item.user))];
  }, [items]);

  useEffect(() => {
    socket.emit("main:fetch:admin-payment-history");
  }, []);

  const filteredItems = useMemo(() => {
    return items
      .filter((item) => {
        if (filterStatus === "all") return true;
        return item.status === filterStatus;
      })
      .filter((item) => {
        if (!userFilter) return true;
        return item.user === userFilter;
      })
      .sort((a, b) => {
        const currentSortBy = sortBy === "date" ? "updatedAt" : "status";
        if (sortOrder === "asc") {
          return a[currentSortBy] > b[currentSortBy] ? 1 : -1;
        } else {
          return a[currentSortBy] < b[currentSortBy] ? 1 : -1;
        }
      });
  }, [items, sortBy, sortOrder, userFilter, filterStatus]);

  useEffect(() => {
    if (refundPayment) {
      setMaxRefundAmount(refundPayment.amount);
      setRefundBalance(refundPayment.amount);
    } else {
      setMaxRefundAmount(0);
      setRefundBalance(0);
    }
  }, [refundPayment]);

  const submitRefundRequest = () => {
    if (refundBalance > maxRefundAmount)
      return toast.error(
        "Refund amount cannot be greater than the payment amount"
      );

    socket.emit(
      "main:actions:refund-payment",
      refundPayment._id,
      refundBalance
    );
    setRefundPayment(null);
  };

  return (
    <>
      <div className="flex items-center justify-between mb-4">
        <div className="flex items-center gap-4">
          <Label>{t(k.SORT_BY)}</Label>
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button variant="outline" className="flex items-center gap-2">
                <span>{t("SORT_BY_" + sortBy.toUpperCase())}</span>
                <ChevronDownIcon className="w-4 h-4" />
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="start">
              <DropdownMenuRadioGroup value={sortBy} onValueChange={setSortBy}>
                <DropdownMenuRadioItem value="date">
                  {t(k.SORT_BY_DATE)}
                </DropdownMenuRadioItem>
                <DropdownMenuRadioItem value="status">
                  {t(k.SORT_BY_STATUS)}
                </DropdownMenuRadioItem>
              </DropdownMenuRadioGroup>
            </DropdownMenuContent>
          </DropdownMenu>
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button variant="outline" className="flex items-center gap-2">
                <span>
                  {t(sortOrder === "asc" ? k.ASCENDING : k.DESCENDING)}{" "}
                </span>
                <ChevronDownIcon className="w-4 h-4" />
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="start">
              <DropdownMenuRadioGroup
                value={sortOrder}
                onValueChange={setSortOrder}
              >
                <DropdownMenuRadioItem value="asc" checked={true}>
                  {t(k.ASCENDING)}
                </DropdownMenuRadioItem>
                <DropdownMenuRadioItem
                  value="desc"
                  checked={sortOrder === "desc"}
                >
                  {t(k.DESCENDING)}
                </DropdownMenuRadioItem>
              </DropdownMenuRadioGroup>
            </DropdownMenuContent>
          </DropdownMenu>
        </div>
        <div className="flex items-center gap-4">
          <Popover open={userOpen} onOpenChange={setUserOpen}>
            <PopoverTrigger asChild>
              <Button
                variant="outline"
                role="combobox"
                aria-expanded={userOpen}
                className="w-[200px] justify-between"
              >
                {userFilter
                  ? users.find((user) => user === userFilter)
                  : t(k.SELECT_USER)}
                <CaretSortIcon className="ml-2 h-4 w-4 shrink-0 opacity-50" />
              </Button>
            </PopoverTrigger>
            <PopoverContent className="w-[200px] p-0">
              <Command>
                <CommandInput placeholder={t(k.SELECT_USER)} className="h-9" />
                <CommandEmpty>{t(k.NO_USER_FOUND)}</CommandEmpty>
                <CommandGroup>
                  <CommandList>
                    {users
                      .filter((user) => user)
                      .map((user) => (
                        <CommandItem
                          key={user}
                          value={user}
                          onSelect={(currentValue) => {
                            setUserFilter(
                              currentValue === userFilter ? "" : currentValue
                            );
                            setUserOpen(false);
                          }}
                        >
                          {user}
                          <CheckIcon
                            className={cn(
                              "ml-auto h-4 w-4",
                              userFilter === user ? "opacity-100" : "opacity-0"
                            )}
                          />
                        </CommandItem>
                      ))}
                  </CommandList>
                </CommandGroup>
              </Command>
            </PopoverContent>
          </Popover>
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button variant="outline" className="flex items-center gap-2">
                <span>
                  {filterStatus === "all"
                    ? t(k.STATUS)
                    : t(filterStatus.toUpperCase(), { ns: "statuses" })
                        .charAt(0)
                        .toUpperCase() +
                      t(filterStatus.toUpperCase(), { ns: "statuses" }).slice(
                        1
                      )}
                </span>
                <ChevronDownIcon className="w-4 h-4" />
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="start">
              <DropdownMenuRadioGroup
                value={filterStatus}
                onValueChange={setFilterStatus}
              >
                <DropdownMenuRadioItem value="all">
                  {t(k.STATUS)}
                </DropdownMenuRadioItem>
                {[...new Set(items.map((item) => item.status))].map(
                  (status) => (
                    <DropdownMenuRadioItem key={status} value={status}>
                      {t(status.toUpperCase(), { ns: "statuses" })
                        .charAt(0)
                        .toUpperCase() +
                        t(status.toUpperCase(), { ns: "statuses" }).slice(1)}
                    </DropdownMenuRadioItem>
                  )
                )}
              </DropdownMenuRadioGroup>
            </DropdownMenuContent>
          </DropdownMenu>
        </div>
      </div>
      <Table className="border-spacing-y-4 border-separate">
        <TableHeader>
          <TableRow>
            <TableHead>{t(k.USER)}</TableHead>
            <TableHead>{t(k.LAST_UPDATED)}</TableHead>
            <TableHead className="text-center">{t(k.AMOUNT)}</TableHead>
            <TableHead className="text-center">{t(k.REFUND_AMOUNT)}</TableHead>
            <TableHead className="text-center">{t(k.STATUS)}</TableHead>
            <TableHead>{t(k.TRANSACTION_ID)}</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {filteredItems.map((payment) => (
            <TableRow key={payment._id}>
              <TableCell>
                <div>{payment.user}</div>
              </TableCell>
              <TableCell>
                <div>{new Date(payment.updatedAt).toLocaleDateString()}</div>
              </TableCell>
              <TableCell className="text-center">
                <div>{payment.amount}</div>
              </TableCell>
              <TableCell className="text-center">
                <div>{payment.refundAmount}</div>
              </TableCell>

              <TableCell className="text-center">
                <Badge
                  variant={
                    payment.status === "completed" ? "secondary" : "outline"
                  }
                  className="text-xs"
                >
                  {t(payment.status.toUpperCase(), { ns: "statuses" })
                    .charAt(0)
                    .toUpperCase() +
                    t(payment.status.toUpperCase(), { ns: "statuses" }).slice(
                      1
                    )}
                </Badge>
              </TableCell>
              <TableCell>
                <div>{payment.merchantTransactionId}</div>
              </TableCell>
              <TableCell>
                <DropdownMenu>
                  <DropdownMenuTrigger asChild>
                    <Button aria-haspopup="true" size="icon" variant="ghost">
                      <MoreHorizontal className="h-4 w-4" />
                      <span className="sr-only">{t(k.TOGGLE_MENU)}</span>
                    </Button>
                  </DropdownMenuTrigger>
                  <DropdownMenuContent align="end">
                    <DropdownMenuLabel>{t(k.ACTIONS)}</DropdownMenuLabel>
                    <DropdownMenuSeparator />
                    <DropdownMenuItem onClick={() => setRefundPayment(payment)}>
                      {t(k.REFUND)}
                    </DropdownMenuItem>
                  </DropdownMenuContent>
                </DropdownMenu>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      {refundPayment && (
        <Dialog open={true}>
          <DialogContent
            closeClick={() => setRefundPayment(null)}
            onEscapeKeyDown={() => setRefundPayment(null)}
            onPointerDownOutside={() => setRefundPayment(null)}
          >
            <DialogHeader>
              <DialogTitle>{t(k.CONFIRM_REFUND)}</DialogTitle>
              <DialogDescription>{t(k.REFUND_DESCRIPTION)}</DialogDescription>

              <Label>{t(k.REFUND_AMOUNT)}</Label>
              <Input
                type="number"
                placeholder={t(k.ENTER_REFUND_AMOUNT)}
                value={refundBalance}
                max={maxRefundAmount}
                onChange={(e) => setRefundBalance(e.target.value)}
              />

              <DialogDescription>
                {t(k.REFUND_AMOUNT_WARNING)}
              </DialogDescription>

              <div className="flex justify-end">
                <Button onClick={() => setRefundPayment(null)} variant="ghost">
                  {t(k.CANCEL)}
                </Button>
                <Button
                  variant="destructive"
                  className="mr-2"
                  onClick={() => submitRefundRequest()}
                >
                  {t(k.REFUND)}
                </Button>
              </div>
            </DialogHeader>
          </DialogContent>
        </Dialog>
      )}
    </>
  );
}
