import { keys as k } from "common/mod.ts";
import { Button } from "components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "components/ui/dialog";
import { isEqual } from "date-fns";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { extendSession } from "./actions-handler";
import PeriodSelector from "./period-selector";

export default function ExtendSessionModal({
  modemId,
  limitTime,
  setExtendOpen,
}) {
  const { t } = useTranslation();
  const currentPeriod = new Date(limitTime);
  currentPeriod.setSeconds(0);
  currentPeriod.setMilliseconds(0);
  const [endPeriod, setEndPeriod] = useState(currentPeriod);

  return (
    <Dialog defaultOpen>
      <DialogContent
        className="sm:max-w-[600px]"
        closeClick={() => setExtendOpen(false)}
        onEscapeKeyDown={() => setExtendOpen(false)}
        onPointerDownOutside={() => setExtendOpen(false)}
      >
        <DialogHeader>
          <DialogTitle>{t(k.SELECT_PERIOD)}</DialogTitle>
          <DialogDescription>
            {t(k.SELECT_PERIOD_DESCRIPTION)}
          </DialogDescription>
        </DialogHeader>
        <div className="flex flex-col gap-4">
          <PeriodSelector
            startPeriod={currentPeriod}
            endPeriod={endPeriod}
            setEndPeriod={setEndPeriod}
          />
          <DialogFooter>
            <div className="flex gap-4">
              <Button onClick={() => setExtendOpen(false)} variant="outline">
                {t(k.CANCEL)}
              </Button>
              <Button
                variant="destructive"
                disabled={isEqual(currentPeriod, endPeriod)}
                onClick={() => extendSession(modemId, endPeriod, setExtendOpen)}
              >
                {t(k.EXTEND_SESSION)}
              </Button>
            </div>
          </DialogFooter>
        </div>
      </DialogContent>
    </Dialog>
  );
}
