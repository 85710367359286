export default {
  ACTIVE: "Active",
  INACTIVE: "Inactive",
  AVAILABLE: "Available",
  DISABLED: "Disabled",
  BUSY: "Busy",
  FREE: "Free",
  RESERVED: "Reserved",
  UNAVAILABLE: "Unavailable",
  NO_INTERNET: "No internet",
  PENDING: "Pending",
  COMPLETED: "Completed",
  FAILED: "Failed",
  REFUNDED: "Refunded",
};
