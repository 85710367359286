export const notificationsHandler = (socket, setNotifications) => {
  function handler(payload) {
    setNotifications(payload);
  }
  socket.on("web:data:notifications", handler);
  return () => {
    socket.off("web:data:notifications", handler);
  };
};

export const updateNotificationHandler = (socket, setNotifications) => {
  function handler(updatedNotification) {
    setNotifications((prevNotifications) => {
      const currentNotifications = [...prevNotifications];
      const index = currentNotifications.findIndex(
        (notification) => notification._id === updatedNotification._id,
      );
      if (index === -1) return [updatedNotification, ...prevNotifications];
      else
        return prevNotifications.map((notification) =>
          notification._id === updatedNotification._id
            ? updatedNotification
            : notification,
        );
    });
  }
  socket.on("web:action:notification-handler", handler);
  return () => {
    socket.off("web:action:notification-handler", handler);
  };
};
