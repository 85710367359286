import { keys as k } from "common/mod.ts";
import { AlertCircle } from "lucide-react";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { socket } from "socket";

import { Button } from "./button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "./dialog";
import { Input } from "./input";
import { Label } from "./label";

export default function TrialModal({ country, city, setTrialModalOpen }) {
  const { t } = useTranslation();
  const [telegram, setTelegram] = useState(
    JSON.parse(localStorage.getItem("user")).telegram || ""
  );

  const email = JSON.parse(localStorage.getItem("user")).email;
  const [error, setError] = useState("");

  const handleSubmit = () => {
    if (!telegram && !email) {
      setError(k.REQUEST_TRIAL_PROVIDE_HANDLER_WARNING);
      return;
    }
    // Here you would typically send the request to your backend
    socket.emit("main:action:trial-request", {
      telegram,
      email,
      country,
      city,
    });
    setTrialModalOpen(false);
  };

  useEffect(() => {
    if (!telegram) return;
    if (telegram && telegram === "@") return setTelegram("");
    if (telegram && telegram.startsWith("@")) return;
    setTelegram(`@${telegram}`);
  }, [telegram]);

  return (
    <Dialog open={true}>
      <DialogTrigger />

      <DialogContent
        // className="sm:max-w-[42px]"
        closeClick={() => setTrialModalOpen(false)}
        onEscapeKeyDown={() => setTrialModalOpen(false)}
        onPointerDownOutside={() => setTrialModalOpen(false)}
      >
        <DialogHeader>
          <DialogTitle>{t(k.REQUEST_TRIAL_ACCESS)}</DialogTitle>
          <DialogDescription>
            {t(k.REQUEST_TRIAL_ACCESS_DESCRIPTION)}
          </DialogDescription>
        </DialogHeader>
        <form onSubmit={handleSubmit}>
          <div className="grid gap-4 py-4">
            <div className="grid grid-cols-4 items-center gap-4">
              <Label htmlFor="telegram" className="text-right">
                {t(k.TELEGRAM)}
              </Label>
              <Input
                id="telegram"
                value={telegram}
                onChange={(e) => setTelegram(e.target.value)}
                className="col-span-3"
                placeholder={t(k.TELEGRAM_PLACEHOLDER)}
              />
            </div>
            <div className="text-center text-sm text-muted-foreground">
              {t(k.OR)}
            </div>
            <div className="grid grid-cols-4 items-center gap-4">
              <Label htmlFor="email" className="text-right">
                {t(k.EMAIL)}
              </Label>
              <Input
                id="email"
                type="email"
                value={email}
                className="col-span-3"
                disabled
              />
            </div>
            <div className="grid grid-cols-4 items-center gap-4">
              <Label htmlFor="country" className="text-right">
                {t(k.COUNTRY)}
              </Label>
              <Input
                id="country"
                value={country}
                onChange={(e) => e.preventDefault()}
                className="col-span-3"
                disabled
              />
            </div>
            <div className="grid grid-cols-4 items-center gap-4">
              <Label htmlFor="city" className="text-right">
                {t(k.CITY)}
              </Label>
              <Input
                id="city"
                value={city}
                onChange={(e) => e.preventDefault()}
                className="col-span-3"
                disabled
              />
            </div>
          </div>
          {error && <div className="text-red-500 text-sm mb-4">{error}</div>}
          <div className="bg-muted p-4 rounded-md mb-4">
            <div className="flex items-center space-x-2 text-sm">
              <AlertCircle className="h-4 w-4" />
              <span className="font-semibold">{t(k.TRIAL_PROCESS)}</span>
            </div>
            <ul className="list-disc list-inside text-sm mt-2 space-y-1">
              <li>{t(k.TRIAL_PROCESS_STEP_1)}</li>
              <li>{t(k.TRIAL_PROCESS_STEP_2)}</li>
              <li>{t(k.TRIAL_PROCESS_STEP_3)}</li>
            </ul>
          </div>
          <DialogFooter>
            <Button type="submit">{t(k.SUBMIT_REQUEST)}</Button>
          </DialogFooter>
        </form>
      </DialogContent>
    </Dialog>
  );
}
