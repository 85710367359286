export const handleUser = (socket) => {
  function handler(payload) {
    const user = JSON.parse(localStorage.getItem("user"));
    localStorage.setItem("user", JSON.stringify({ ...user, ...payload }));
  }
  socket.on("web:action:update-current-user", handler);

  return () => {
    socket.off("web:action:update-current-user", handler);
  };
};
