export const keys = {
  SORT_BY: "SORT_BY",
  DATE: "DATE",
  STATUS: "STATUS",
  SORT_BY_DATE: "SORT_BY_DATE",
  SORT_BY_STATUS: "SORT_BY_STATUS",
  ASCENDING: "ASCENDING",
  DESCENDING: "DESCENDING",
  SELECT_USER: "SELECT_USER",
  NO_USER_FOUND: "NO_USER_FOUND",
  USER: "USER",
  LAST_UPDATED: "LAST_UPDATED",
  AMOUNT: "AMOUNT",
  REFUND_AMOUNT: "REFUND_AMOUNT",
  TRANSACTION_ID: "TRANSACTION_ID",
  COMPLETED: "COMPLETED",
  TOGGLE_MENU: "TOGGLE_MENU",
  ACTIONS: "ACTIONS",
  REFUND: "REFUND",
  CONFIRM_REFUND: "CONFIRM_REFUND",
  REFUND_DESCRIPTION: "REFUND_DESCRIPTION",
  REFUND_AMOUNT_WARNING: "REFUND_AMOUNT_WARNING",
  CANCEL: "CANCEL",
  MODIFY: "MODIFY",
  CONFIRM: "CONFIRM",
  CREATE: "CREATE",
  PRICING_PLAN: "PRICING_PLAN",
  CLEAR: "CLEAR",
  COUNTRY: "COUNTRY",
  CITY: "CITY",
  OPERATOR: "OPERATOR",
  ADD_SUB_PLAN: "ADD_SUB_PLAN",
  UPDATE: "UPDATE",
  SUBMIT: "SUBMIT",
  PLAN: "PLAN",
  SUBMIT_PLAN: "SUBMIT_PLAN",
  SUB_PLAN: "SUB_PLAN",
  TITLE: "TITLE",
  PRICE: "PRICE",
  DESCRIPTION: "DESCRIPTION",
  FEATURES: "FEATURES",
  ADD_FEATURE: "ADD_FEATURE",
  PRICING_PLANS: "PRICING_PLANS",
  AVAILABLE_MODEMS_COUNT: "AVAILABLE_MODEMS_COUNT",
  PRICING_PLANS_DESCRIPTION: "PRICING_PLANS_DESCRIPTION",
  ALL: "ALL",
  PLANS: "PLANS",
  VIEW_PLANS: "VIEW_PLANS",
  COPY_PLAN: "COPY_PLAN",
  EDIT_PLAN: "EDIT_PLAN",
  DELETE_PLAN: "DELETE_PLAN",
  ADD_PLAN: "ADD_PLAN",
  CHANGE_IP: "CHANGE_IP",
  CHANGE_DHCP: "CHANGE_DHCP",
  REBOOT: "REBOOT",
  RESET: "RESET",
  SWITCH: "SWITCH",
  EXTEND: "EXTEND",
  ASSIGN: "ASSIGN",
  DELETE: "DELETE",
  ASSIGN_MODEM: "ASSIGN_MODEM",
  ASSIGN_TRIAL: "ASSIGN_TRIAL",
  CLEAR_SESSION: "CLEAR_SESSION",
  CLEAR_SESSION_WARNING: "CLEAR_SESSION_WARNING",
  DELETE_MODEM: "DELETE_MODEM",
  DELETE_MODEM_WARNING: "DELETE_MODEM_WARNING",
  CHANGE_MODEM_DHCP: "CHANGE_MODEM_DHCP",
  CHANGE_MODEM_DHCP_WARNING: "CHANGE_MODEM_DHCP_WARNING",
  SELECT_PERIOD: "SELECT_PERIOD",
  SELECT_PERIOD_DESCRIPTION: "SELECT_PERIOD_DESCRIPTION",
  EXTEND_SESSION: "EXTEND_SESSION",
  LOCATIONS: "LOCATIONS",
  SERVER_NUMBER: "SERVER_NUMBER",
  OCCUPANCY: "OCCUPANCY",
  OCCUPANCY_STATUS: "OCCUPANCY_STATUS",
  CLEAR_FILTERS: "CLEAR_FILTERS",
  MANAGE_COLUMNS: "MANAGE_COLUMNS",
  SIGNAL: "SIGNAL",
  PUBLIC_IP: "PUBLIC_IP",
  INTERFACE: "INTERFACE",
  INTERNAL_IP: "INTERNAL_IP",
  GATEWAY_IP: "GATEWAY_IP",
  UPDATED_AT: "UPDATED_AT",
  COMMENT: "COMMENT",
  RENEW: "RENEW",
  RENEW_SUBSCRIPTION: "RENEW_SUBSCRIPTION",
  RENEW_SUBSCRIPTION_DESCRIPTION: "RENEW_SUBSCRIPTION_DESCRIPTION",
  MODEM_STATUS: "MODEM_STATUS",
  USER_LOGIN_INFO: "USER_LOGIN_INFO",
  ADMIN_LOGIN_INFO: "ADMIN_LOGIN_INFO",
  VALID_UNTIL: "VALID_UNTIL",
  UPDATED: "UPDATED",
  SELECT_DATE: "SELECT_DATE",
  SELECT_DURATION: "SELECT_DURATION",
  HOURS: "HOURS",
  MINUTES: "MINUTES",
  DAYS: "DAYS",
  END_PERIOD: "END_PERIOD",
  MODEM_DETAILS: "MODEM_DETAILS",
  GENERAL: "GENERAL",
  ID: "ID",
  SERVER_ID: "SERVER_ID",
  NUMBER: "NUMBER",
  LOCATION_NUMBER: "LOCATION_NUMBER",
  COUNT: "COUNT",
  LAST_REBOOT: "LAST_REBOOT",
  SESSION: "SESSION",
  RESERVED_BY: "RESERVED_BY",
  RESERVED_UNTIL: "RESERVED_UNTIL",
  PURCHASED_BY: "PURCHASED_BY",
  PURCHASED_UNTIL: "PURCHASED_UNTIL",
  USED_TRAFFIC: "USED_TRAFFIC",
  LIMIT_TRAFFIC: "LIMIT_TRAFFIC",
  LATEST_DOWNLOAD_SPEED: "LATEST_DOWNLOAD_SPEED",
  LATEST_UPLOAD_SPEED: "LATEST_UPLOAD_SPEED",
  CONNECTION_DETAILS: "CONNECTION_DETAILS",
  URL: "URL",
  COPY_URL: "COPY_URL",
  HTTP_PORT: "HTTP_PORT",
  COPY_HTTP_PORT: "COPY_HTTP_PORT",
  SOCKS_PORT: "SOCKS_PORT",
  COPY_SOCKS_PORT: "COPY_SOCKS_PORT",
  ADMIN_USERNAME: "ADMIN_USERNAME",
  COPY_USERNAME: "COPY_USERNAME",
  COPY_HTTP_LOGIN: "COPY_HTTP_LOGIN",
  ADMIN_PASSWORD: "ADMIN_PASSWORD",
  COPY_PASSWORD: "COPY_PASSWORD",
  COPY_SOCKS_LOGIN: "COPY_SOCKS_LOGIN",
  CLIENT_USERNAME: "CLIENT_USERNAME",
  CLIENT_PASSWORD: "CLIENT_PASSWORD",
  CLOSE: "CLOSE",
  REBOOT_MODEM: "REBOOT_MODEM",
  REBOOT_MODEM_WARNING: "REBOOT_MODEM_WARNING",
  RESET_MODEM: "RESET_MODEM",
  RESET_MODEM_WARNING: "RESET_MODEM_WARNING",
  ENABLE_MODEM: "ENABLE_MODEM",
  DISABLE_MODEM: "DISABLE_MODEM",
  ENABLE_MODEM_CONFIRM: "ENABLE_MODEM_CONFIRM",
  DISABLE_MODEM_CONFIRM: "DISABLE_MODEM_CONFIRM",
  SHOW_ACTIONS: "SHOW_ACTIONS",
  SHOW_MODEM: "SHOW_MODEM",
  FREE: "FREE",
  RESERVED: "RESERVED",
  BUSY: "BUSY",
  SWITCH_MODEM: "SWITCH_MODEM",
  SELECT: "SELECT",
  SERVER_RESTRICTIONS_MANAGEMENT: "SERVER_RESTRICTIONS_MANAGEMENT",
  PUBLIC_URL: "PUBLIC_URL",
  ANYDESK: "ANYDESK",
  CONNECT: "CONNECT",
  RESTRICTIONS: "RESTRICTIONS",
  NEW_RESTRICTION: "NEW_RESTRICTION",
  IP_RESTRICTIONS_FOR: "IP_RESTRICTIONS_FOR",
  CURRENT_RESTRICTIONS: "CURRENT_RESTRICTIONS",
  REMOVE: "REMOVE",
  RESTRICTIONS_ACTIONS: "RESTRICTIONS_ACTIONS",
  ADD_NEW_RESTRICTIONS: "ADD_NEW_RESTRICTIONS",
  ADD: "ADD",
  OR: "OR",
  COPY_RESTRICTIONS_FROM_ANOTHER: "COPY_RESTRICTIONS_FROM_ANOTHER",
  SELECT_SERVER: "SELECT_SERVER",
  SELECT_COUNTRY: "SELECT_COUNTRY",
  SELECT_CITY: "SELECT_CITY",
  SELECT_OPERATOR: "SELECT_OPERATOR",
  SELECT_LOCATION: "SELECT_LOCATION",
  COPY: "COPY",
  UPDATE_RESTRICTIONS: "UPDATE_RESTRICTIONS",
  AVAILABLE: "AVAILABLE",
  DISABLED: "DISABLED",
  UNAVAILABLE: "UNAVAILABLE",
  NO_INTERNET: "NO_INTERNET",
  EDIT_BALANCE: "EDIT_BALANCE",
  ENTER_BALANCE: "ENTER_BALANCE",
  EDIT: "EDIT",
  ENABLE: "ENABLE",
  DISABLE: "DISABLE",
  EMAIL: "EMAIL",
  TELEGRAM_ID: "TELEGRAM_ID",
  FULL_NAME: "FULL_NAME",
  BALANCE: "BALANCE",
  ROLE: "ROLE",
  EMAIL_PLACEHOLDER: "EMAIL_PLACEHOLDER",
  TELEGRAM_PLACEHOLDER: "TELEGRAM_PLACEHOLDER",
  FIRST_NAME_PLACEHOLDER: "FIRST_NAME_PLACEHOLDER",
  LAST_NAME_PLACEHOLDER: "LAST_NAME_PLACEHOLDER",
  DEFAULT: "DEFAULT",
  GHOST: "GHOST",
  RIGHT: "RIGHT",
  START: "START",
  SIGN_OUT: "SIGN_OUT",
  GLOBALPROXY: "GLOBALPROXY",
  PROFILE: "PROFILE",
  ACCOUNT: "ACCOUNT",
  SELECT_AN_OPTION: "SELECT_AN_OPTION",
  EDIT_COMMENT: "EDIT_COMMENT",
  ENTER_COMMENT: "ENTER_COMMENT",
  SHOW: "SHOW",
  USERNAME: "USERNAME",
  PASSWORD: "PASSWORD",
  COPY_CHANGE_IP_URL: "COPY_CHANGE_IP_URL",
  SAVE_CHANGES: "SAVE_CHANGES",
  IP: "IP",
  LOGIN_INFO: "LOGIN_INFO",
  QUANTITY: "QUANTITY",
  PERIOD: "PERIOD",
  PRICE_PER_ITEM: "PRICE_PER_ITEM",
  SWITCH_THEME: "SWITCH_THEME",
  MORE: "MORE",
  PICK_A_DATE: "PICK_A_DATE",
  COLUMNS: "COLUMNS",
  LOCATION: "LOCATION",
  NO_RESULTS_FOUND: "NO_RESULTS_FOUND",
  CUSTOM: "CUSTOM",
  PURCHASE_PRODUCT: "PURCHASE_PRODUCT",
  PURCHASE_PRODUCT_DESCRIPTION: "PURCHASE_PRODUCT_DESCRIPTION",
  TOTAL: "TOTAL",
  REQUEST_TRIAL: "REQUEST_TRIAL",
  PURCHASE: "PURCHASE",
  DUE: "DUE",
  PURCHASE_REDIRECT_INFO: "PURCHASE_REDIRECT_INFO",
  CURRENT_BALANCE: "CURRENT_BALANCE",
  REFILL_AMOUNT: "REFILL_AMOUNT",
  NEW_BALANCE: "NEW_BALANCE",
  REFILL_BALANCE: "REFILL_BALANCE",
  REQUEST_TRIAL_ACCESS: "REQUEST_TRIAL_ACCESS",
  REQUEST_TRIAL_PROVIDE_HANDLER_WARNING:
    "REQUEST_TRIAL_PROVIDE_HANDLER_WARNING",
  REQUEST_TRIAL_ACCESS_DESCRIPTION: "REQUEST_TRIAL_ACCESS_DESCRIPTION",
  TELEGRAM: "TELEGRAM",
  TRIAL_PROCESS: "TRIAL_PROCESS",
  TRIAL_PROCESS_STEP_1: "TRIAL_PROCESS_STEP_1",
  TRIAL_PROCESS_STEP_2: "TRIAL_PROCESS_STEP_2",
  TRIAL_PROCESS_STEP_3: "TRIAL_PROCESS_STEP_3",
  SUBMIT_REQUEST: "SUBMIT_REQUEST",
  HOME: "HOME",
  DASHBOARD: "DASHBOARD",
  ADMIN_PANEL: "ADMIN_PANEL",
  HISTORY: "HISTORY",
  PAYMENT: "PAYMENT",
  PAYMENT_HISTORY: "PAYMENT_HISTORY",
  PROXY_HISTORY: "PROXY_HISTORY",
  NO_PAYMENT_HISTORY_AVAILABLE: "NO_PAYMENT_HISTORY_AVAILABLE",
  PROXIES: "PROXIES",
  NO_PROXIES_AVAILABLE: "NO_PROXIES_AVAILABLE",
  SERVERS: "SERVERS",
  NO_SERVERS_AVAILABLE: "NO_SERVERS_AVAILABLE",
  USERS: "USERS",
  NO_USERS_AVAILABLE: "NO_USERS_AVAILABLE",
  COUPONS: "COUPONS",
  NO_COUPONS_AVAILABLE: "NO_COUPONS_AVAILABLE",
  COUPON_INVALID: "COUPON_INVALID",
  ADD_COUPON: "ADD_COUPON",
  GENERATE_COUPON: "GENERATE_COUPON",
  UPDATE_COUPON: "UPDATE_COUPON",
  NO_COUPON_FOUND: "NO_COUPON_FOUND",
  SELECT_COUPON: "SELECT_COUPON",
  COUPON_CODE: "COUPON_CODE",
  COUPON_DISCOUNT: "COUPON_DISCOUNT",
  COUPON_TYPE: "COUPON_TYPE",
  COUPON_USES: "COUPON_USES",
  COUPON_LIMIT: "COUPON_LIMIT",
  COUPON_EXPIRY: "COUPON_EXPIRY",
  COUPON_FIXED: "COUPON_FIXED",
  COUPON_PERCENTAGE: "COUPON_PERCENTAGE",
  COUPON_ZERO_IS_UNLIMITED: "COUPON_ZERO_IS_UNLIMITED",
  COUPON_CODE_VALIDATION: "COUPON_CODE_VALIDATION",
  COUPON_PERCENTAGE_VALIDATION: "COUPON_PERCENTAGE_VALIDATION",
  COUPON_AFFECTED_PRODUCTS: "COUPON_AFFECTED_PRODUCTS",
  SELECT_COUNTRIES: "SELECT_COUNTRIES",
  SELECT_COUNTRIES_DESCRIPTION: "SELECT_COUNTRIES_DESCRIPTION",
  SELECT_CITIES: "SELECT_CITIES",
  SELECT_CITIES_DESCRIPTION: "SELECT_CITIES_DESCRIPTION",
  SELECT_OPERATORS: "SELECT_OPERATORS",
  SELECT_OPERATORS_DESCRIPTION: "SELECT_OPERATORS_DESCRIPTION",
  ALL_COUNTRIES: "ALL_COUNTRIES",
  ALL_CITIES: "ALL_CITIES",
  ALL_OPERATORS: "ALL_OPERATORS",
  ALL_LOCATIONS: "ALL_LOCATIONS",
  NONE_INCLUDES_ALL: "NONE_INCLUDES_ALL",
  CONFIRM_EMAIL: "CONFIRM_EMAIL",
  CONFIRM_EMAIL_DESCRIPTION: "CONFIRM_EMAIL_DESCRIPTION",
  TOKEN_UNUSED: "TOKEN_UNUSED",
  TOKEN_INVALID_INFO: "TOKEN_INVALID_INFO",
  REGENERATE_TOKEN: "REGENERATE_TOKEN",
  TOKEN_EXPIRED_INFO: "TOKEN_EXPIRED_INFO",
  ERROR_PAGE_TITLE: "ERROR_PAGE_TITLE",
  ERROR_PAGE_DESCRIPTION: "ERROR_PAGE_DESCRIPTION",
  GO_TO_HOMEPAGE: "GO_TO_HOMEPAGE",
  IP_STATISTICS: "IP_STATISTICS",
  NO_IP_STATISTICS_AVAILABLE: "NO_IP_STATISTICS_AVAILABLE",
  PURCHASE_PROXIES: "PURCHASE_PROXIES",
  NO_PROXIES_HISTORY_AVAILABLE: "NO_PROXIES_HISTORY_AVAILABLE",
  LOGIN: "LOGIN",
  LOGIN_DESCRIPTION: "LOGIN_DESCRIPTION",
  FORGOT_YOUR_PASSWORD: "FORGOT_YOUR_PASSWORD",
  LOGIN_NO_ACCOUNT: "LOGIN_NO_ACCOUNT",
  FORGOT_PASSWORD: "FORGOT_PASSWORD",
  FORGOT_PASSWORD_DESCRIPTION: "FORGOT_PASSWORD_DESCRIPTION",
  RESET_PASSWORD: "RESET_PASSWORD",
  BACK_TO_LOGIN: "BACK_TO_LOGIN",
  SIGN_UP: "SIGN_UP",
  SIGN_UP_DESCRIPTION: "SIGN_UP_DESCRIPTION",
  FIRST_NAME: "FIRST_NAME",
  LAST_NAME: "LAST_NAME",
  CONFIRM_PASSWORD: "CONFIRM_PASSWORD",
  CREATE_AN_ACCOUNT: "CREATE_AN_ACCOUNT",
  ALREADY_HAVE_AN_ACCOUNT: "ALREADY_HAVE_AN_ACCOUNT",
  SIGN_IN: "SIGN_IN",
  SETTINGS: "SETTINGS",
  UPDATE_ACCOUNT: "UPDATE_ACCOUNT",
  REFILL: "REFILL",
  CONTACT: "CONTACT",
  TELEGRAM_OFFICIAL: "TELEGRAM_OFFICIAL",
  TELEGRAM_OFFICIAL_LINK: "TELEGRAM_OFFICIAL_LINK",
  TELEGRAM_SUPPORT: "TELEGRAM_SUPPORT",
  TELEGRAM_SUPPORT_LINK: "TELEGRAM_SUPPORT_LINK",
  INVALID_EMAIL_OR_PASSWORD: "INVALID_EMAIL_OR_PASSWORD",
  USER_NOT_ACTIVE: "USER_NOT_ACTIVE",
  LOGGED_IN: "LOGGED_IN",
  LOGGED_OUT: "LOGGED_OUT",
  AUTHENTICATION_FAILED: "AUTHENTICATION_FAILED",
  GENERAL_ERROR: "GENERAL_ERROR",
  CONNECTION_ERROR: "CONNECTION_ERROR",
  RESET_PASSWORD_EMAIL_SENT: "RESET_PASSWORD_EMAIL_SENT",
  PASSWORDS_DO_NOT_MATCH: "PASSWORDS_DO_NOT_MATCH",
  REGISTER_ERROR: "REGISTER_ERROR",
  REGISTER_SUCCESS: "REGISTER_SUCCESS",
  CHANGE_PASSWORD: "CHANGE_PASSWORD",
  CURRENT_PASSWORD: "CURRENT_PASSWORD",
  NEW_PASSWORD: "NEW_PASSWORD",
  ACCOUNT_UPDATED: "ACCOUNT_UPDATED",
  ACCOUNT_FIRST_NAME_VALIDATION: "ACCOUNT_FIRST_NAME_VALIDATION",
  ACCOUNT_LAST_NAME_VALIDATION: "ACCOUNT_LAST_NAME_VALIDATION",
  ACCOUNT_PASSWORD_VALIDATION: "ACCOUNT_PASSWORD_VALIDATION",
  ACCOUNT_PASSWORD_MATCH_VALIDATION: "ACCOUNT_PASSWORD_MATCH_VALIDATION",
  ACCOUNT_INVALID_CURRENT_PASSWORD: "ACCOUNT_INVALID_CURRENT_PASSWORD",
  ACCOUNT_UNAUTHORIZED: "ACCOUNT_UNAUTHORIZED",
  CONFIRM_USER_SUCCESS: "CONFIRM_USER_SUCCESS",
  CONFIRM_USER_NO_USER_ID: "CONFIRM_USER_NO_USER_ID",
  CONFIRM_USER_TOKEN_REGENERATED: "CONFIRM_USER_TOKEN_REGENERATED",
  INVALID_RESTRICTION: "INVALID_RESTRICTION",
  INVALID_RESTRICTIONS_COPIED: "INVALID_RESTRICTIONS_COPIED",
  ACTIONS_MODEM_REBOOT_INITIATED: "ACTIONS_MODEM_REBOOT_INITIATED",
  ACTIONS_MODEM_RESET_INITIATED: "ACTIONS_MODEM_RESET_INITIATED",
  ACTIONS_MODEM_DELETE_INITIATED: "ACTIONS_MODEM_DELETE_INITIATED",
  ACTIONS_DHCP_CHANGE_INITIATED: "ACTIONS_DHCP_CHANGE_INITIATED",
  ACTIONS_MODEM_SWITCH_INITIATED: "ACTIONS_MODEM_SWITCH_INITIATED",
  ACTIONS_SESSION_CLEARED: "ACTIONS_SESSION_CLEARED",
  ACTIONS_SESSION_EXTENDED: "ACTIONS_SESSION_EXTENDED",
  ACTIONS_MODEM_ASSIGNED: "ACTIONS_MODEM_ASSIGNED",
  ACTIONS_TRIAL_ASSIGNED: "ACTIONS_TRIAL_ASSIGNED",
  ACTIONS_IP_CHANGE_REQUESTED: "ACTIONS_IP_CHANGE_REQUESTED",
  ACTIONS_REBOOT_REQUESTED: "ACTIONS_REBOOT_REQUESTED",
  ACTIONS_RESET_REQUESTED: "ACTIONS_RESET_REQUESTED",
  ACTIONS_RESTRICTIONS_UPDATED: "ACTIONS_RESTRICTIONS_UPDATED",
  ACTIONS_BALANCE_UPDATED: "ACTIONS_BALANCE_UPDATED",
  ACTIONS_COMMENT_UPDATED: "ACTIONS_COMMENT_UPDATED",
  ACTIONS_RENEW_UPDATED: "ACTIONS_RENEW_UPDATED",
  ACTIONS_STATUS_SWITCH_INITIATED: "ACTIONS_STATUS_SWITCH_INITIATED",
  PROXY_LOGIN_INFO_UPDATED: "PROXY_LOGIN_INFO_UPDATED",
  PAYMENT_REQUEST_SUCCESS: "PAYMENT_REQUEST_SUCCESS",
  IP_STATISTICS_ALL: "IP_STATISTICS_ALL",
  IP_STATISTICS_LAST_HOUR: "IP_STATISTICS_LAST_HOUR",
  IP_STATISTICS_LAST_6_HOURS: "IP_STATISTICS_LAST_6_HOURS",
  IP_STATISTICS_LAST_12_HOURS: "IP_STATISTICS_LAST_12_HOURS",
  IP_STATISTICS_LAST_24_HOURS: "IP_STATISTICS_LAST_24_HOURS",
  IP_STATISTICS_LAST_7_DAYS: "IP_STATISTICS_LAST_7_DAYS",
  IP_STATISTICS_LAST_30_DAYS: "IP_STATISTICS_LAST_30_DAYS",
  NEW_GATEWAY_IP: "NEW_GATEWAY_IP",
  NOTIFICATIONS: "NOTIFICATIONS",
  ALL_NOTIFICATIONS: "ALL_NOTIFICATIONS",
  OPERATIONAL_NOTIFICATIONS: "OPERATIONAL_NOTIFICATIONS",
  ACCOUNT_NOTIFICATIONS: "ACCOUNT_NOTIFICATIONS",
  BILLING_NOTIFICATIONS: "BILLING_NOTIFICATIONS",
  MARK_ALL_AS_READ: "MARK_ALL_AS_READ",
  DASHBOARD_GRANULARITY_SELECT: "DASHBOARD_GRANULARITY_SELECT",
  DASHBOARD_GRANULARITY_MINUTELY: "DASHBOARD_GRANULARITY_MINUTELY",
  DASHBOARD_GRANULARITY_HOURLY: "DASHBOARD_GRANULARITY_HOURLY",
  DASHBOARD_GRANULARITY_DAILY: "DASHBOARD_GRANULARITY_DAILY",
  DASHBOARD_GRANULARITY_MONTHLY: "DASHBOARD_GRANULARITY_MONTHLY",
  DASHBOARD_GRANULARITY_YEARLY: "DASHBOARD_GRANULARITY_YEARLY",
  DASHBOARD_AVAILABILITY_CHART_TITLE: "DASHBOARD_AVAILABILITY_CHART_TITLE",
  DASHBOARD_AVAILAILABILITY_FREE: "DASHBOARD_AVAILAILABILITY_FREE",
  DASHBOARD_AVAILAILABILITY_BUSY: "DASHBOARD_AVAILAILABILITY_BUSY",
  DASHBOARD_AVAILAILABILITY_RESERVED: "DASHBOARD_AVAILAILABILITY_RESERVED",
  DASHBOARD_STATUS_CHART_TITLE: "DASHBOARD_STATUS_CHART_TITLE",
  DASHBOARD_STATUS_AVAILABLE: "DASHBOARD_STATUS_AVAILABLE",
  DASHBOARD_STATUS_DISABLED: "DASHBOARD_STATUS_DISABLED",
  DASHBOARD_STATUS_UNAVAILABLE: "DASHBOARD_STATUS_UNAVAILABLE",
  DASHBOARD_STATUS_NO_INTERNET: "DASHBOARD_STATUS_NO_INTERNET",
  NO_NOTIFICATIONS: "NO_NOTIFICATIONS",
  NOTIFICATION_COPIED_TO_CLIPBOARD: "NOTIFICATION_COPIED_TO_CLIPBOARD",
  NOTIFICATION_CLIPBOARD_NOT_SUPPORTED: "NOTIFICATION_CLIPBOARD_NOT_SUPPORTED",
  NOTIFICATION_IP_CHANGE_SUCCESS: "NOTIFICATION_IP_CHANGE_SUCCESS",
  NOTIFICATION_REBOOT_SUCCESS: "NOTIFICATION_REBOOT_SUCCESS",
  NOTIFICATION_RESET_SUCCESS: "NOTIFICATION_RESET_SUCCESS",
  NOTIFICATION_USER_UPDATE_SUCCESS: "NOTIFICATION_USER_UPDATE_SUCCESS",
  NOTIFICATION_PLAN_CREATE_SUCCESS: "NOTIFICATION_PLAN_CREATE_SUCCESS",
  NOTIFICATION_PLAN_UPDATE_SUCCESS: "NOTIFICATION_PLAN_UPDATE_SUCCESS",
  NOTIFICATION_PLAN_DELETE_SUCCESS: "NOTIFICATION_PLAN_DELETE_SUCCESS",
  NOTIFICATION_REFUND_SUCCESS: "NOTIFICATION_REFUND_SUCCESS",
  NOTIFICATION_TRIAL_REQUEST_SUCCESS: "NOTIFICATION_TRIAL_REQUEST_SUCCESS",
  NOTIFICATION_COUPON_CREATE_SUCCESS: "NOTIFICATION_COUPON_CREATE_SUCCESS",
  NOTIFICATION_COUPON_UPDATE_SUCCESS: "NOTIFICATION_COUPON_UPDATE_SUCCESS",
  NOTIFICATION_SESSION_PURCHASE_SUCCESS_SUBJECT:
    "NOTIFICATION_SESSION_PURCHASE_SUCCESS_SUBJECT",
  NOTIFICATION_SESSION_PURCHASE_SUCCESS_BODY:
    "NOTIFICATION_SESSION_PURCHASE_SUCCESS_BODY",
  NOTIFICATION_SESSION_PURCHASE_FAILED_SUBJECT:
    "NOTIFICATION_SESSION_PURCHASE_FAILED_SUBJECT",
  NOTIFICATION_SESSION_PURCHASE_FAILED_BODY:
    "NOTIFICATION_SESSION_PURCHASE_FAILED_BODY",
  NOTIFICATION_SESSION_EXPIRATION_SUBJECT:
    "NOTIFICATION_SESSION_EXPIRATION_SUBJECT",
  NOTIFICATION_SESSION_EXPIRATION_BODY: "NOTIFICATION_SESSION_EXPIRATION_BODY",
  NOTIFICATION_SESSION_RENEWAL_SUBJECT: "NOTIFICATION_SESSION_RENEWAL_SUBJECT",
  NOTIFICATION_SESSION_RENEWAL_BODY: "NOTIFICATION_SESSION_RENEWAL_BODY",
  NOTIFICATION_SESSION_RENEWAL_FAILED_SUBJECT:
    "NOTIFICATION_SESSION_RENEWAL_FAILED_SUBJECT",
  NOTIFICATION_SESSION_RENEWAL_FAILED_BODY:
    "NOTIFICATION_SESSION_RENEWAL_FAILED_BODY",
  NOTIFICATION_SESSION_RENEWAL_SUCCESS_SUBJECT:
    "NOTIFICATION_SESSION_RENEWAL_SUCCESS_SUBJECT",
  NOTIFICATION_SESSION_RENEWAL_SUCCESS_BODY:
    "NOTIFICATION_SESSION_RENEWAL_SUCCESS_BODY",

  ERROR_USER_NOT_FOUND: "ERROR_USER_NOT_FOUND",
  ERROR_NO_TELEGRAM_ID: "ERROR_NO_TELEGRAM_ID",
  ERROR_NO_MODEMS_AVAILABLE: "ERROR_NO_MODEMS_AVAILABLE",
  ERROR_GATEWAY_EXISTS: "ERROR_GATEWAY_EXISTS",
  ERROR_PROXY_SERVER_NOT_CONNECTED: "ERROR_PROXY_SERVER_NOT_CONNECTED",
  ERROR_MODEM_NOT_FOUND: "ERROR_MODEM_NOT_FOUND",
  ERROR_MODEM_ASSIGNED: "ERROR_MODEM_ASSIGNED",
  ERROR_IP_CHANGE: "ERROR_IP_CHANGE",
  ERROR_REBOOT_FAILED: "ERROR_REBOOT_FAILED",
  ERROR_RESET_FAILED: "ERROR_RESET_FAILED",
  ERROR_REBOOT_LIMIT: "ERROR_REBOOT_LIMIT",
  ERROR_MODEM_NOT_PURCHASED: "ERROR_MODEM_NOT_PURCHASED",
  ERROR_UNAUTHORIZED_ACTION: "ERROR_UNAUTHORIZED_ACTION",
  ERROR_SERVER_NOT_FOUND: "ERROR_SERVER_NOT_FOUND",
  ERROR_PRICING_PLAN_NOT_FOUND: "ERROR_PRICING_PLAN_NOT_FOUND",
  ERROR_PURCHASE_INVALID_QUANTITY: "ERROR_PURCHASE_INVALID_QUANTITY",
  ERROR_PAYMENT_NOT_FOUND: "ERROR_PAYMENT_NOT_FOUND",
  ERROR_PAYMENT_NOT_COMPLETED: "ERROR_PAYMENT_NOT_COMPLETED",
  ERROR_REFUND_AMOUNT_EXCEEDS: "ERROR_REFUND_AMOUNT_EXCEEDS",
  ERROR_COUPON_EXISTS: "ERROR_COUPON_EXISTS",
  ERROR_COUPON_NOT_FOUND: "ERROR_COUPON_NOT_FOUND",
};
