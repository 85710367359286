import { keys as k } from "common/mod.ts";
import { Button } from "components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "components/ui/dialog";
import { Input } from "components/ui/input";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { socket } from "socket";
import { toast } from "sonner";

export default function EditBalanceModal({
  userId,
  currentBalance,
  setEditBalanceOpen,
}) {
  const { t } = useTranslation();
  const [balance, setBalance] = useState(currentBalance);

  const handleEditBalance = () => {
    toast.success(t(k.ACTIONS_BALANCE_UPDATED));
    socket.emit("main:action:update-user", userId, { balance });
    setEditBalanceOpen(false);
  };

  return (
    <Dialog defaultOpen>
      <DialogTrigger></DialogTrigger>
      <DialogContent
        closeClick={() => setEditBalanceOpen(false)}
        onEscapeKeyDown={() => setEditBalanceOpen(false)}
        onPointerDownOutside={() => setEditBalanceOpen(false)}
      >
        <DialogHeader>
          <DialogTitle>{t(k.EDIT_BALANCE)}</DialogTitle>
        </DialogHeader>
        <div className="flex flex-col gap-4">
          <Input
            label="Balance"
            type="number"
            placeholder={t(k.ENTER_BALANCE)}
            value={balance}
            onChange={(e) => setBalance(parseInt(e.target.value))}
          />

          <DialogFooter>
            <div className="flex gap-4">
              <Button
                onClick={() => setEditBalanceOpen(false)}
                variant="outline"
              >
                {t(k.CANCEL)}
              </Button>
              <Button
                variant="destructive"
                disabled={isNaN(balance) || balance === currentBalance}
                onClick={() => handleEditBalance()}
              >
                {t(k.EDIT)}
              </Button>
            </div>
          </DialogFooter>
        </div>
      </DialogContent>
    </Dialog>
  );
}
