import { keys as k } from "common/mod.ts";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "components/ui/table";
import { NAMESPACES } from "lib/utils";
import React from "react";
import { useTranslation } from "react-i18next";

export default function IPStatisticsTable({
  items,
  selectedOperatorColumn,
  selectedServerColumn,
  selectedCityColumn,
}) {
  const { t } = useTranslation([
    NAMESPACES.GENERAL,
    NAMESPACES.COUNTRIES,
    NAMESPACES.CITIES,
  ]);

  const renderRows = (item) => {
    if (selectedOperatorColumn) {
      return item.cities.map((city) =>
        city.servers.map((server) =>
          server.operators.map((operator) => (
            <TableRow key={operator.operator}>
              <TableCell>{t(item.country, { ns: "countries" })}</TableCell>
              <TableCell>{t(city.city, { ns: "cities" })}</TableCell>
              <TableCell>#{server.serverNumber}</TableCell>
              <TableCell>{operator.operator}</TableCell>
              <TableCell>{operator.count}</TableCell>
            </TableRow>
          ))
        )
      );
    } else if (selectedServerColumn) {
      return item.cities.map((city) =>
        city.servers.map((server) => (
          <TableRow key={server.serverNumber}>
            <TableCell>{t(item.country, { ns: "countries" })}</TableCell>
            <TableCell>{t(city.city, { ns: "cities" })}</TableCell>
            <TableCell>#{server.serverNumber}</TableCell>
            <TableCell>{server.count}</TableCell>
          </TableRow>
        ))
      );
    } else if (selectedCityColumn) {
      return item.cities.map((city) => (
        <TableRow key={city.city}>
          <TableCell>{t(item.country, { ns: "countries" })}</TableCell>
          <TableCell>{t(city.city, { ns: "cities" })}</TableCell>
          <TableCell>{city.count}</TableCell>
        </TableRow>
      ));
    } else {
      return (
        <TableRow key={item.country}>
          <TableCell>{t(item.country, { ns: "countries" })}</TableCell>
          <TableCell>{item.count}</TableCell>
        </TableRow>
      );
    }
  };

  return (
    <>
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead>{t(k.COUNTRY)}</TableHead>
            {selectedCityColumn && <TableHead>{t(k.CITY)}</TableHead>}
            {selectedServerColumn && (
              <TableHead>{t(k.LOCATION_NUMBER)}</TableHead>
            )}
            {selectedOperatorColumn && <TableHead>{t(k.OPERATOR)}</TableHead>}
            <TableHead>{t(k.COUNT)}</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>{items.map((item) => renderRows(item))}</TableBody>
      </Table>
    </>
  );
}
