import { keys as k } from "common/mod.ts";
import UsersTable from "components/layout/admin/users";
import { ContentLayout } from "components/layout/panel/content-layout";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from "components/ui/breadcrumb";
import { Card, CardContent, CardFooter, CardHeader } from "components/ui/card";
import { Spinner } from "components/ui/spinner";
import { updateUserHandler, usersHandler } from "handlers/users";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { socket } from "socket";

export default function Users() {
  const { t } = useTranslation();
  const [users, setUsers] = useState([]);
  const [spinner, setSpinner] = useState(true);

  useEffect(() => {
    socket.emit("main:fetch:users-full");
  }, []);
  useEffect(() => usersHandler(socket, setUsers, setSpinner), []);
  useEffect(() => updateUserHandler(socket, setUsers), []);

  return (
    <ContentLayout title={t(k.USERS)}>
      <Card className="rounded-lg border-none mt-6">
        <CardHeader>
          <Breadcrumb>
            <BreadcrumbList>
              <BreadcrumbItem>
                <BreadcrumbPage>{t(k.ADMIN_PANEL)}</BreadcrumbPage>
              </BreadcrumbItem>
              <BreadcrumbSeparator />
              <BreadcrumbItem>
                <BreadcrumbPage>{t(k.USERS)}</BreadcrumbPage>
              </BreadcrumbItem>
            </BreadcrumbList>
          </Breadcrumb>
        </CardHeader>
        <CardContent>
          {spinner && <Spinner className="text-primary flex justify-center" />}
          {users.length ? (
            <UsersTable users={users} />
          ) : (
            <p className="text-center text-muted-foreground">
              {t(k.NO_USERS_AVAILABLE)}
            </p>
          )}
        </CardContent>
        <CardFooter></CardFooter>
      </Card>
    </ContentLayout>
  );
}
