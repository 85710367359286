import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import { socket } from "socket";

import en from "./locales/en";
import ru from "./locales/ru";

// eslint-disable-next-line no-undef
const isDev = process.env.NODE_ENV === "development";

i18n

  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    ns: ["general", "statuses", "countries", "cities"],
    defaultNS: "general",
    debug: isDev,
    supportedLngs: ["en", "ru"],
    fallbackLng: "en",
    parseMissingKeyHandler: (key) => key,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    saveMissing: true,
    missingKeyHandler: (lngs, ns, key) => {
      socket.emit("main:translation:missing", { lngs, ns, key });
    },
    resources: {
      en,
      ru,
    },
    detection: {
      cookieDomain: isDev ? "localhost" : "globalproxy.org",
      caches: ["cookie"],
    },
  });

export default i18n;
