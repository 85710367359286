import { keys as k } from "common/mod.ts";
import {
  couponInfoHandler,
  paymentRequestHandler,
  purchaseSuccessHandler,
} from "handlers/purchase";
import { NAMESPACES } from "lib/utils";
import { usePurchase } from "providers/PurchaseProvider";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { socket } from "socket";

import { Button } from "./button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "./dialog";
import { Input } from "./input";
import { Label } from "./label";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "./select";
import { Separator } from "./separator";
import TrialModal from "./trial-modal";

export default function PurchaseDialog() {
  const { t } = useTranslation([
    NAMESPACES.GENERAL,
    NAMESPACES.COUNTRIES,
    NAMESPACES.CITIES,
  ]);
  const {
    selectedPlan,
    selectedSubPlan,
    setSelectedSubPlan,
    selectedCountry,
    setSelectedCountry,
    selectedCity,
    setSelectedCity,
    selectedOperator,
    setSelectedOperator,
    countries,
    cities,
    operators,
    availableModemsCount,
  } = usePurchase();
  const navigate = useNavigate();
  const balance = JSON.parse(localStorage.getItem("user")).balance;
  const trialUsed = JSON.parse(localStorage.getItem("user")).trialUsed ?? true;
  const [trialModalOpen, setTrialModalOpen] = useState(false);
  const [couponInfo, setCouponInfo] = useState();
  const [couponCode, setCouponCode] = useState("");
  const [couponError, setCouponError] = useState(false);
  const [couponAmount, setCouponAmount] = useState(0);
  const [currentPrice, setCurrentPrice] = useState(selectedPlan?.price || 0);
  const [currentPeriod, setCurrentPeriod] = useState(selectedPlan?.period || 0);
  const [quantity, setQuantity] = useState(1);
  const [total, setTotal] = useState(quantity * currentPrice);
  const balanceDifference = total > balance ? total - balance : 0;

  useEffect(() => {
    setCurrentPrice(selectedSubPlan.price);
    setCurrentPeriod(selectedSubPlan.period);
  }, [selectedSubPlan]);

  useEffect(() => {
    setTotal(quantity * currentPrice - couponAmount);
  }, [quantity, currentPrice, couponAmount]);

  useEffect(() => {
    purchaseSuccessHandler(socket, navigate);
  }, [navigate]);

  useEffect(() => {
    paymentRequestHandler(socket);
  }, [navigate]);

  useEffect(() => {
    couponInfoHandler(socket, setCouponInfo, setCouponError);
  }, []);

  useEffect(() => {
    if (couponInfo)
      if (couponInfo.type === "percent")
        setCouponAmount((couponInfo.discount / 100) * quantity * currentPrice);
      else setCouponAmount(couponInfo.discount);
    else setCouponAmount(0);
  }, [couponInfo, quantity, currentPrice]);

  const submitPurchaseRequest = () => {
    socket.emit("main:action:purchase-request", {
      pricingPlanId: selectedPlan._id,
      pricingSubPlanId: selectedSubPlan._id,
      quantity,
      couponCode,
      currentUrl: window.location.href,
    });
  };

  const handleCouponCode = (couponCode) => {
    if (!couponCode) {
      setCouponAmount(0);
      setCouponError(false);
      return;
    }
    socket.emit("main:fetch:coupon-info", {
      couponCode,
      country: selectedCountry,
      city: selectedCity,
      operator: selectedOperator,
    });
  };

  return (
    <Dialog open={selectedSubPlan}>
      <DialogContent
        onEscapeKeyDown={() => setSelectedSubPlan(null)}
        onPointerDownOutside={() => setSelectedSubPlan(null)}
        className="sm:max-w-[600px]"
        closeClick={() => setSelectedSubPlan(null)}
      >
        <DialogTrigger></DialogTrigger>
        <DialogHeader>
          <DialogTitle>{t(k.PURCHASE_PRODUCT)}</DialogTitle>
          <DialogDescription>
            {t(k.PURCHASE_PRODUCT_DESCRIPTION)}
          </DialogDescription>
        </DialogHeader>
        <div className="grid gap-6 pt-4">
          <div className="grid sm:grid-cols-3 gap-4">
            <div className="grid gap-2">
              <Label htmlFor="country">{t(k.COUNTRY)}</Label>
              <Select
                id="country"
                value={selectedCountry}
                onChange={(e) => e.preventDefault()}
                onValueChange={(country) => setSelectedCountry(country)}
              >
                <SelectTrigger>
                  <SelectValue placeholder={t(k.SELECT_COUNTRY)} />
                </SelectTrigger>
                <SelectContent>
                  {countries.map((country) => (
                    <SelectItem key={country} value={country}>
                      {t(country, { ns: "countries" })}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            </div>
            <div className="grid gap-2">
              <Label htmlFor="city">{t(k.CITY)}</Label>
              <Select
                id="city"
                value={selectedCity}
                onChange={(e) => e.preventDefault()}
                onValueChange={(city) => setSelectedCity(city)}
              >
                <SelectTrigger>
                  <SelectValue placeholder={t(k.SELECT_CITY)} />
                </SelectTrigger>
                <SelectContent>
                  {cities.map((city) => (
                    <SelectItem key={city} value={city}>
                      {t(city, { ns: "cities" })}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            </div>

            <div className="grid gap-2">
              <Label htmlFor="operator">{t(k.OPERATOR)}</Label>
              <Select
                id="operator"
                value={selectedOperator}
                onChange={(e) => e.preventDefault()}
                onValueChange={(operator) => setSelectedOperator(operator)}
              >
                <SelectTrigger>
                  <SelectValue placeholder={t(k.SELECT_OPERATOR)} />
                </SelectTrigger>
                <SelectContent>
                  {operators.map((operator) => (
                    <SelectItem key={operator} value={operator}>
                      {operator}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            </div>
          </div>
          {selectedPlan && (
            <>
              <div className="flex flex-row gap-4 items-baseline">
                <div className="grid gap-2 w-full">
                  <Label htmlFor="period">{t(k.PERIOD)}</Label>
                  <Select
                    value={currentPeriod}
                    onValueChange={(value) =>
                      setSelectedSubPlan(
                        selectedPlan.subPlans?.find((sp) => sp.period === value)
                      )
                    }
                  >
                    <SelectTrigger>
                      <SelectValue placeholder={t(k.SELECT_PERIOD)} />
                    </SelectTrigger>
                    <SelectContent>
                      {selectedPlan.subPlans?.map((sp) => (
                        <SelectItem
                          key={sp.period}
                          value={sp.period}
                          selected={sp.period === currentPeriod}
                        >
                          {sp.period} {t(k.DAYS)}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                </div>
                <div className="grid gap-2 w-full">
                  <Label htmlFor="price">{t(k.PRICE)}</Label>
                  <Input
                    id="price"
                    type="number"
                    value={currentPrice}
                    disabled
                  />
                </div>
                <div className="grid gap-2 w-full">
                  <Label htmlFor="quantity">{t(k.QUANTITY)}</Label>
                  <Input
                    id="quantity"
                    type="text"
                    value={quantity}
                    // onChange={(e) => setQuantity(parseInt(e.target.value))}
                    onChange={(e) =>
                      setQuantity(
                        isNaN(parseInt(e.target.value))
                          ? 0
                          : Math.min(
                              parseInt(e.target.value),
                              availableModemsCount
                            )
                      )
                    }
                    min={1}
                    max={availableModemsCount}
                  />
                </div>
              </div>
              <div className="flex flex-row gap-4 items-baseline">
                <div className="grid gap-2 ml-auto">
                  <Label
                    htmlFor="coupon"
                    className={`${couponAmount && "text-green-500"} ${couponError && "text-red-500"}`}
                  >
                    {t(k.COUPON_CODE)}
                  </Label>
                  <Input
                    id="coupon"
                    type="text"
                    value={couponCode}
                    onChange={(e) => setCouponCode(e.target.value)}
                    onBlur={() => handleCouponCode(couponCode)}
                  />
                  {couponError && (
                    <Label className="text-red-500">{couponError}</Label>
                  )}
                </div>
              </div>

              <div className="flex flex-col mt-4 md:mt-0">
                <Label className="text-md md:text-right">
                  {t(k.BALANCE)}: {balance}$
                </Label>
                {couponAmount > 0 && (
                  <Label className="text-md md:text-right">
                    {t(k.COUPON_CODE)}: -{couponAmount || 0}$
                  </Label>
                )}
                <Label className="text-lg underline underline-offset-4 md:text-right">
                  {t(k.TOTAL)}: {total}$
                </Label>
              </div>
            </>
          )}
        </div>

        <Separator />

        <div className="flex flex-col justify-center items-center space-y-3">
          {!trialUsed && (
            <>
              <Button
                disabled={!quantity}
                onClick={() => setTrialModalOpen(true)}
              >
                {t(k.REQUEST_TRIAL)}
              </Button>
              <p className="text-sm font-bold">{t(k.OR)}</p>
            </>
          )}
          <div className="flex flex-col self-right">
            <Button
              disabled={!quantity}
              onClick={() => submitPurchaseRequest()}
            >
              {balanceDifference > 0
                ? `${t(k.PURCHASE)}($${balanceDifference}) ${t(k.DUE)}`
                : t(k.PURCHASE)}
            </Button>
            <p className="text-xs font-semibold text-muted-foreground text-right">
              {t(k.PURCHASE_REDIRECT_INFO)}
            </p>
          </div>
        </div>
        {trialModalOpen && (
          <TrialModal
            setTrialModalOpen={setTrialModalOpen}
            country={selectedCountry}
            city={selectedCity}
          />
        )}
      </DialogContent>
    </Dialog>
  );
}
