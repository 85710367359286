import { MoonIcon, SunIcon } from "@radix-ui/react-icons";
import { keys as k } from "common/mod.ts";
import { Button } from "components/ui/button";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "components/ui/tooltip";
import React from "react";
import { useTranslation } from "react-i18next";

import { useTheme } from "./theme-provider";

export function ModeToggle() {
  const { t } = useTranslation();
  const { setTheme, theme } = useTheme();

  return (
    <TooltipProvider disableHoverableContent>
      <Tooltip delayDuration={100}>
        <TooltipTrigger asChild>
          <Button
            className="rounded-full w-8 h-8 bg-background min-w-8"
            variant="outline"
            size="icon"
            onClick={() => setTheme(theme === "dark" ? "light" : "dark")}
          >
            <SunIcon className="w-[1.2rem] h-[1.2rem] rotate-90 scale-0 transition-transform ease-in-out duration-500 dark:rotate-0 dark:scale-100" />
            <MoonIcon className="absolute w-[1.2rem] h-[1.2rem] rotate-0 scale-1000 transition-transform ease-in-out duration-500 dark:-rotate-90 dark:scale-0" />
            <span className="sr-only">{t(k.SWITCH_THEME)}</span>
          </Button>
        </TooltipTrigger>
        <TooltipContent side="bottom">{t(k.SWITCH_THEME)}</TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
}
