import { Popover, PopoverTrigger } from "@radix-ui/react-popover";
import { keys as k } from "common/mod.ts";
import { Button } from "components/ui/button";
import { Label } from "components/ui/label";
import { PopoverContent } from "components/ui/popover";
import { ScrollArea } from "components/ui/scroll-area";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "components/ui/tabs";
import { format } from "date-fns";
import {
  notificationsHandler,
  updateNotificationHandler,
} from "handlers/notifications";
import i18n from "i18n";
import { DATETIME_FORMAT, localeMap } from "lib/utils";
import { AlertTriangle, Bell, CheckCircle, Info, X } from "lucide-react";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { socket } from "socket";

const categories = ["ALL", "ACCOUNT", "BILLING", "OPERATIONAL"];

export default function NotificationsComponent() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [selectedCategory, setSelectedCategory] = useState("ALL");
  const [notifications, setNotifications] = useState([]);
  const [filteredNotifications, setFilteredNotifications] = useState([]);

  useEffect(() => {
    socket.emit("main:fetch:notifications");
  }, []);

  useEffect(() => notificationsHandler(socket, setNotifications), []);
  useEffect(() => updateNotificationHandler(socket, setNotifications), []);

  useEffect(() => {
    setFilteredNotifications(
      selectedCategory === "ALL"
        ? notifications
        : notifications.filter(
            (n) => n.category.toLowerCase() === selectedCategory.toLowerCase()
          )
    );
  }, [selectedCategory, notifications, filteredNotifications]);

  const getNotificationIcon = (type) => {
    switch (type) {
      case "warning":
        return <Info className="h-5 w-5 text-blue-500" />;
      case "info":
        return <CheckCircle className="h-5 w-5 text-green" />;
      case "error":
        return <AlertTriangle className="h-5 w-5 text-yellow-500" />;
    }
  };

  const setNotificationsRead = (ids) => {
    socket.emit("main:action:update-notification", ids, { isRead: true });
    setNotifications(notifications.filter((n) => !ids.includes(n._id)));
  };

  // const createNotification = () => {
  //   const newNotification = {
  //     subject: "New Notification",
  //     to: "66d7bf516f8c7f2ba0a2ebed",
  //     channelType: "app",
  //     isRead: false,
  //     redirect: "/proxies",
  //     type:
  //       Math.random() > 0.5
  //         ? Math.random() > 0.75
  //           ? "info"
  //           : "error"
  //         : "warning",
  //     category:
  //       Math.random() > 0.5
  //         ? Math.random() > 0.75
  //           ? "account"
  //           : "billing"
  //         : "operational",
  //   };
  //   socket.emit("main:action:notification-create", newNotification);
  // };

  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button
          className="rounded-full  bg-background relative"
          variant="outline"
          size="icon"
        >
          <Bell className="w-5 h-5" />
          {notifications?.length > 0 && (
            <span className="absolute -top-2 -right-2 h-5 w-5 rounded-full bg-red-500 flex items-center justify-center text-white text-xs font-bold">
              {notifications?.length}
            </span>
          )}
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-[400px] sm:w-[540px]">
        <Label className="text-2xl font-bold">{t(k.NOTIFICATIONS)}</Label>
        <div className="mt-6">
          <Tabs
            defaultValue="ALL"
            onValueChange={(category) => setSelectedCategory(category)}
          >
            <TabsList className="grid w-full grid-cols-4">
              {categories.map((category) => (
                <TabsTrigger
                  key={category}
                  value={category}
                  className="text-xs"
                >
                  {t(k[category + "_NOTIFICATIONS"])}
                </TabsTrigger>
              ))}
            </TabsList>
            {categories.map((category) => (
              <TabsContent key={category} value={category} className="mt-4">
                <div className="flex justify-between items-center mb-4">
                  <h3 className="text-lg font-semibold">
                    {t(k[category + "_NOTIFICATIONS"])}{" "}
                    {t(k.NOTIFICATIONS).toLowerCase()}
                  </h3>

                  {/* <Button
                    variant="outline"
                    size="sm"
                    onClick={createNotification}
                  >
                    Create
                  </Button> */}

                  {filteredNotifications?.length > 0 && (
                    <Button
                      variant="outline"
                      size="sm"
                      onClick={() =>
                        setNotificationsRead(
                          filteredNotifications.map((n) => n._id)
                        )
                      }
                    >
                      {t(k.MARK_ALL_AS_READ)}
                    </Button>
                  )}
                </div>
                <ScrollArea className="h-[calc(100vh-400px)]">
                  {filteredNotifications?.length ? (
                    <ul className="space-y-4">
                      {filteredNotifications?.map((notification) => (
                        <li
                          key={notification._id}
                          className="bg-gray-50 rounded-lg p-4 flex items-start space-x-4 items-center cursor-pointer relative"
                          onClick={() => navigate(notification.redirect)}
                        >
                          <div className="flex-shrink-0">
                            {getNotificationIcon(notification.type)}
                          </div>
                          <div className="flex-grow">
                            <p className="text-sm font-medium">
                              {t(notification.subject)}
                            </p>
                            <p className="text-xs text-gray-500 font-small">
                              {t(notification.body)}
                            </p>
                            <p className="text-xs text-gray-500 mt-2 text-right">
                              {format(notification.createdAt, DATETIME_FORMAT, {
                                locale: localeMap[i18n.language],
                              })}
                            </p>
                          </div>
                          <Button
                            variant="ghost"
                            size="icon"
                            onClick={(e) => {
                              e.stopPropagation();
                              setNotificationsRead([notification._id]);
                            }}
                            className="flex-shrink-0 absolute top-2 right-2"
                          >
                            <X className="h-4 w-4" />
                          </Button>
                        </li>
                      ))}
                    </ul>
                  ) : (
                    <div className="flex items-center justify-center h-full">
                      <p className="text-md text-semibold text-gray-500">
                        {t(k.NO_NOTIFICATIONS)}
                      </p>
                    </div>
                  )}
                </ScrollArea>
              </TabsContent>
            ))}
          </Tabs>
        </div>
      </PopoverContent>
    </Popover>
  );
}
