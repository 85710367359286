import { io } from "socket.io-client";

const URL = "/web";

export const socket = io(URL, {
  path: "/api/socket.io",
  auth: {
    accessToken: localStorage.getItem("accessToken"),
    refreshToken: localStorage.getItem("refreshToken"),
  },
});
