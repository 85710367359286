import { keys as k } from "common/mod.ts";
import { t } from "i18next";
import { socket } from "socket";
import { toast } from "sonner";

export const switchStatusModem = (modemId, newStatus, setModalOpen) => {
  socket.emit("main:action:update-status", modemId, newStatus);
  toast.success(t(k.ACTIONS_STATUS_SWITCH_INITIATED));
  setModalOpen(false);
};
export const rebootModem = (modemId, setModalOpen) => {
  socket.emit("main:action:reboot", modemId);
  toast.success(t(k.ACTIONS_MODEM_REBOOT_INITIATED));
  setModalOpen(false);
};

export const resetModem = (modemId, setModalOpen) => {
  socket.emit("main:action:reset", modemId);
  toast.success(t(k.ACTIONS_MODEM_RESET_INITIATED));
  setModalOpen(false);
};

export const deleteModem = (modemId, setModalOpen) => {
  socket.emit("main:action:delete-modem", modemId);
  toast.success(t(k.ACTIONS_MODEM_DELETE_INITIATED));
  setModalOpen(false);
};

export const changeModemDHCP = (modemId, newModemGatewayIP, setModalOpen) => {
  socket.emit("main:action:update-gateway", modemId, newModemGatewayIP);
  toast.success(t(k.ACTIONS_DHCP_CHANGE_INITIATED));
  setModalOpen(false);
};

export const switchModem = (modemId, newModemId, setModalOpen) => {
  socket.emit("main:action:client-switch-modem", modemId, newModemId);
  toast.success(t(k.ACTIONS_MODEM_SWITCH_INITIATED));
  setModalOpen(false);
};

export const clearSession = (modemId, setModalOpen) => {
  socket.emit("main:action:clear-session", modemId);
  toast.success(t(k.ACTIONS_SESSION_CLEARED));
  setModalOpen(false);
};

export const extendSession = (modemId, period, setModalOpen) => {
  socket.emit("main:action:update-session", modemId, { limitTime: period });
  toast.success(t(k.ACTIONS_SESSION_EXTENDED));
  setModalOpen(false);
};

export const assignModem = (modemId, userId, period, setModalOpen) => {
  socket.emit("main:action:create-session", modemId, userId, period);
  toast.success(t(k.ACTIONS_MODEM_ASSIGNED));
  setModalOpen(false);
};

export const assignTrial = (modemId, userId, setModalOpen) => {
  socket.emit("main:action:create-trial", modemId, userId);
  toast.success(t(k.ACTIONS_TRIAL_ASSIGNED));
  setModalOpen(false);
};
