"use client";

import { keys as k } from "common/mod.ts";
import { Button } from "components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "components/ui/dialog";
import { Input } from "components/ui/input";
import { Label } from "components/ui/label";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "components/ui/select";
import { Separator } from "components/ui/separator";
import { NAMESPACES } from "lib/utils";
import { Settings } from "lucide-react";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

export default function RenewalModal({
  pricingPlan,
  period,
  handleRenew,
  setRenewModalOpen,
}) {
  const { t } = useTranslation([
    NAMESPACES.GENERAL,
    NAMESPACES.COUNTRIES,
    NAMESPACES.CITIES,
    NAMESPACES.OPERATORS,
  ]);

  const { country, city, operator, subPlans } = pricingPlan || {};

  const [selectedPeriod, setSelectedPeriod] = useState(
    period || subPlans?.at(subPlans.length - 1)?.period
  );

  const [selectedSubPlan, setSelectedSubPlan] = useState(null);

  useEffect(() => {
    setSelectedSubPlan(
      subPlans?.find(
        (subPlan) =>
          subPlan.period === selectedPeriod ||
          (subPlan.country === country &&
            subPlan.city === city &&
            subPlan.operator === operator)
      )
    );
  }, [subPlans, selectedPeriod, country, city, operator]);

  const handleRenewUpdate = () => {
    handleRenew({
      plan: pricingPlan._id,
      renewPeriod: selectedPeriod,
    });
    setRenewModalOpen(false);
  };

  return (
    <Dialog defaultOpen>
      <DialogTrigger></DialogTrigger>
      <DialogContent
        closeClick={() => setRenewModalOpen(false)}
        onEscapeKeyDown={() => setRenewModalOpen(false)}
        onPointerDownOutside={() => setRenewModalOpen(false)}
        className="w-80"
      >
        <DialogHeader>
          <DialogTitle className="flex gap-2 mb-4 items-center">
            <Settings size={16} />
            {t(k.RENEW_SUBSCRIPTION)}
          </DialogTitle>
          <DialogDescription className="text-sm">
            {t(k.RENEW_SUBSCRIPTION_DESCRIPTION)}
          </DialogDescription>
        </DialogHeader>
        <div className="flex flex-col gap-4">
          <div className="flex flex-col gap-4">
            <div className="grid grid-cols-3 items-center gap-4">
              <Label htmlFor="country">{t(k.COUNTRY, { ns: "general" })}</Label>
              <Input
                className="col-span-2"
                value={t(country, { ns: "countries" })}
                disabled
              />
            </div>
            <div className="grid grid-cols-3 items-center gap-4">
              <Label htmlFor="city">{t(k.CITY, { ns: "general" })}</Label>
              <Input
                className="col-span-2"
                value={t(city, { ns: "cities" })}
                disabled
              />
            </div>
            <div className="grid grid-cols-3 items-center gap-4">
              <Label htmlFor="operator">
                {t(k.OPERATOR, { ns: "general" })}
              </Label>
              <Input className="col-span-2" value={operator} disabled />
            </div>
            <div className="grid grid-cols-3 items-center gap-4">
              <Label htmlFor="period">{t(k.PERIOD, { ns: "general" })}</Label>
              <Select
                value={selectedPeriod || ""}
                onValueChange={(value) =>
                  setSelectedPeriod(parseInt(value, 10))
                }
              >
                <SelectTrigger className="col-span-2">
                  <SelectValue
                    placeholder={t(k.SELECT_PERIOD, { ns: "general" })}
                  >
                    {selectedSubPlan?.period} {t(k.DAYS, { ns: "general" })}
                  </SelectValue>
                </SelectTrigger>
                <SelectContent>
                  {subPlans?.map((subPlan) => (
                    <SelectItem key={subPlan.period} value={subPlan.period}>
                      {subPlan.period} {t(k.DAYS, { ns: "general" })}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            </div>
            {selectedSubPlan && (
              <div className="flex flex-col pt-4">
                <Separator />
                <p className="font-bold mt-4">
                  {t(k.PRICE, { ns: "general" })}: {selectedSubPlan.price}$
                </p>
              </div>
            )}
          </div>
        </div>

        <DialogFooter>
          <div className="flex gap-4">
            <Button onClick={() => setRenewModalOpen(false)} variant="outline">
              {t(k.CANCEL, { ns: "general" })}
            </Button>
            <Button onClick={() => handleRenewUpdate()}>
              {t(k.RENEW, { ns: "general" })}
            </Button>
          </div>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
