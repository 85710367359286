export const handlePaymentHistory = (socket, setItems, setSpinner) => {
  function handler(payload) {
    setItems(() => payload);
    setSpinner(false);
  }

  socket.on("web:data:admin-payment-history", handler);

  return () => {
    socket.off("web:data:admin-payment-history", handler);
  };
};
