export default {
  ACTIVE: "Активный",
  INACTIVE: "Неактивный",
  AVAILABLE: "Доступный",
  DISABLED: "Отключен",
  BUSY: "Занят",
  FREE: "Свободен",
  RESERVED: "Зарезервирован",
  UNAVAILABLE: "Недоступный",
  NO_INTERNET: "Нет сети",
  PENDING: "В ожидании",
  COMPLETED: "Завершен",
  FAILED: "Неуспешно",
  REFUNDED: "Возвращено",
};
