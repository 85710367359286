const ipStatisicsHandler = async (socket, setIpStats, setSpinner) => {
  function handler(payload) {
    setIpStats(() => payload);
    setSpinner(false);
  }
  socket.on("web:data:ip-stats", handler);
  return () => {
    socket.off("web:data:ip-stats", handler);
  };
};
export { ipStatisicsHandler };
