import { zodResolver } from "@hookform/resolvers/zod";
import { keys as k } from "common/mod.ts";
import { Button } from "components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "components/ui/dialog";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "components/ui/form";
import { Input } from "components/ui/input";
import {
  MultiSelector,
  MultiSelectorItem,
  MultiSelectorList,
} from "components/ui/multi-selector";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
} from "components/ui/select";
import { Switch } from "components/ui/switch";
import { couponSelectionHandler } from "handlers/coupons";
import { couponTypes } from "lib/utils";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { socket } from "socket";
import { generate as generateVoucher } from "voucher-code-generator";
import { z } from "zod";

import PeriodSelector from "../proxies/period-selector";

const upperCaseChars = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
const defaultExpiration = new Date(2099, 0, 1);

export default function CouponForm({ selectedCoupon, setCouponFormOpen }) {
  const { t } = useTranslation();
  const [enableExpiration, setEnableExpiration] = useState(
    selectedCoupon?.expiration &&
      selectedCoupon?.expiration !== defaultExpiration
  );
  const [selectionItems, setSelectionItems] = useState({});
  const [selectedCountries, setSelectedCountries] = useState(
    selectedCoupon?.selectedCountries || []
  );
  const [availableCities, setAvailableCities] = useState([]);
  const [selectedCities, setSelectedCities] = useState(
    selectedCoupon?.selectedCities || []
  );
  const [availableOperators, setAvailableOperators] = useState([]);
  const [selectedOperators, setSelectedOperators] = useState(
    selectedCoupon?.selectedOperators || []
  );

  const formSchema = z
    .object({
      code: z.string().min(2, { message: t(k.COUPON_CODE_VALIDATION) }),
      discount: z.coerce.number().int(),
      limit: z.coerce.number().int(),
      used: z.coerce.number().int(),
      type: z.enum(Object.keys(couponTypes)),
      status: z.enum(["active", "inactive"]),
      expiration: z.coerce.date(),
      selectedCountries: z.array(z.string()),
      selectedCities: z.array(z.string()),
      selectedOperators: z.array(z.string()),
    })
    .refine(
      (data) =>
        data.type == "fixed" ||
        (data.type === "percent" && data.discount <= 100),
      {
        message: t(k.COUPON_PERCENTAGE_VALIDATION),
        path: ["discount"],
      }
    );

  const form = useForm({
    resolver: zodResolver(formSchema),
    defaultValues: selectedCoupon || {
      code: "",
      discount: 0,
      used: 0,
      limit: 0,
      type: "percent",
      status: "active",
      expiration: defaultExpiration,
      selectedCountries: [],
      selectedCities: [],
      selectedOperators: [],
    },
  });

  const handleSubmit = (coupon) => {
    if (selectedCoupon) {
      socket.emit("main:action:update-coupon", coupon);
    } else {
      socket.emit("main:action:create-coupon", coupon);
    }
    setCouponFormOpen(false);
  };

  useEffect(() => {
    socket.emit("main:fetch:coupon-selection");
  }, []);

  useEffect(() => couponSelectionHandler(socket, setSelectionItems), []);

  useEffect(() => {
    if (!Object.keys(selectionItems).length) return;
    if (selectedCountries.length == 1) {
      setAvailableCities(Object.keys(selectionItems[selectedCountries[0]]));
    } else {
      setAvailableCities([]);
    }
  }, [selectedCountries, selectionItems, setAvailableCities]);

  useEffect(() => {
    if (!Object.keys(selectionItems).length) return;
    if (selectedCities.length == 1) {
      setAvailableOperators(
        selectionItems[selectedCountries[0]][selectedCities[0]]
      );
    } else {
      setAvailableOperators([]);
    }
  }, [
    selectedCities,
    selectionItems,
    setAvailableOperators,
    selectedCountries,
  ]);

  useEffect(() => {
    if (!enableExpiration) form.setValue("expiration", defaultExpiration);
  }, [enableExpiration, form]);

  useEffect(() => {
    form.setValue("selectedCities", selectedCities);
  }, [selectedCities, form]);

  useEffect(() => {
    form.setValue("selectedOperators", selectedOperators);
  }, [selectedOperators, form]);

  useEffect(() => {
    form.setValue("selectedCountries", selectedCountries);
  }, [selectedCountries, form]);

  return (
    <Dialog defaultOpen>
      <DialogTrigger></DialogTrigger>
      <DialogContent
        closeClick={() => setCouponFormOpen(false)}
        onEscapeKeyDown={() => setCouponFormOpen(false)}
        onPointerDownOutside={() => setCouponFormOpen(false)}
        className="w-full max-w-[70%]"
      >
        <Form {...form}>
          <DialogHeader>
            <DialogTitle className="mb-4">
              {selectedCoupon ? t(k.UPDATE_COUPON) : t(k.ADD_COUPON)}
            </DialogTitle>
          </DialogHeader>
          <form className="space-y-8">
            <div className="flex gap-4">
              <div className="flex flex-col gap-2 w-full">
                <FormField
                  name="code"
                  control={form.control}
                  error={form.formState.errors.code}
                  render={({ field }) => (
                    <FormItem className="flex flex-col gap-2 w-full">
                      <FormLabel>{t(k.COUPON_CODE)}</FormLabel>
                      <div className="flex gap-4">
                        <FormControl>
                          <Input
                            type="text"
                            {...field}
                            disabled={!!selectedCoupon}
                          />
                        </FormControl>
                        {!selectedCoupon && (
                          <Button
                            onClick={() =>
                              form.setValue(
                                "code",
                                generateVoucher({
                                  length: 8,
                                  charset: upperCaseChars,
                                }).toString()
                              )
                            }
                            type="button"
                          >
                            {t(k.GENERATE_COUPON)}
                          </Button>
                        )}
                      </div>
                      <FormMessage>
                        {form.formState.errors.code?.message}
                      </FormMessage>
                    </FormItem>
                  )}
                />
                <div className="flex gap-4">
                  <FormField
                    name="discount"
                    control={form.control}
                    error={form.formState.errors.discount}
                    render={({ field }) => (
                      <FormItem className="flex flex-col gap-2 w-full">
                        <FormLabel>{t(k.COUPON_DISCOUNT)}</FormLabel>
                        <FormControl>
                          <Input type="number" {...field} />
                        </FormControl>
                        <FormMessage>
                          {form.formState.errors.discount?.message}
                        </FormMessage>
                      </FormItem>
                    )}
                  />
                  <FormField
                    name="type"
                    control={form.control}
                    error={form.formState.errors.type}
                    render={({ field }) => (
                      <FormItem className="flex flex-col gap-2 w-full">
                        <FormLabel>{t(k.COUPON_TYPE)}</FormLabel>
                        <FormControl>
                          <Select onValueChange={field.onChange}>
                            <SelectTrigger>
                              {t(couponTypes[field.value])}
                            </SelectTrigger>
                            <SelectContent>
                              {Object.entries(couponTypes).map(
                                ([key, value]) => (
                                  <SelectItem key={key} value={key}>
                                    {t(value)}
                                  </SelectItem>
                                )
                              )}
                            </SelectContent>
                          </Select>
                        </FormControl>
                        <FormMessage>
                          {form.formState.errors.type?.message}
                        </FormMessage>
                      </FormItem>
                    )}
                  />
                </div>
              </div>

              <div className="flex flex-col gap-2 w-full">
                <FormField
                  name="status"
                  control={form.control}
                  error={form.formState.errors.status}
                  render={({ field }) => (
                    <FormItem className="flex flex-col gap-2 w-full">
                      <FormLabel>{t(k.STATUS)}</FormLabel>
                      <FormControl>
                        <Select
                          {...field}
                          onValueChange={field.onChange}
                          value={field.value}
                        >
                          <SelectTrigger>
                            {t(field.value?.toUpperCase(), { ns: "statuses" })}
                          </SelectTrigger>
                          <SelectContent>
                            <SelectItem value="active" selected={true}>
                              {t("ACTIVE", { ns: "statuses" })}
                            </SelectItem>
                            <SelectItem value="inactive">
                              {t("INACTIVE", { ns: "statuses" })}
                            </SelectItem>
                          </SelectContent>
                        </Select>
                      </FormControl>
                      <FormMessage>
                        {form.formState.errors.status?.message}
                      </FormMessage>
                    </FormItem>
                  )}
                />
                <FormField
                  name="limit"
                  control={form.control}
                  error={form.formState.errors.limit}
                  render={({ field }) => (
                    <FormItem className="flex flex-col gap-2 w-full">
                      <FormLabel>
                        {t(k.COUPON_LIMIT)}{" "}
                        {`(${t(k.COUPON_ZERO_IS_UNLIMITED)})`}
                      </FormLabel>
                      <FormControl>
                        <Input type="number" {...field} />
                      </FormControl>
                      <FormMessage>
                        {form.formState.errors.limit?.message}
                      </FormMessage>
                    </FormItem>
                  )}
                />

                <FormField
                  name="expiration"
                  control={form.control}
                  error={form.formState.errors.expiration}
                  render={({ field }) => (
                    <FormItem className="flex flex-col gap-2 w-full">
                      <FormLabel className="flex items-center gap-4">
                        {t(k.COUPON_EXPIRY)}
                        <Switch
                          checked={enableExpiration}
                          onCheckedChange={setEnableExpiration}
                        />
                      </FormLabel>
                      {enableExpiration && (
                        <>
                          <PeriodSelector
                            startPeriod={new Date()}
                            endPeriod={field.value || defaultExpiration}
                            setEndPeriod={field.onChange}
                          />
                          <FormMessage>
                            {form.formState.errors.expiration?.message}
                          </FormMessage>
                        </>
                      )}
                    </FormItem>
                  )}
                />
              </div>
              <div className="flex flex-col gap-2 w-full">
                <FormField
                  name="selectedCountries"
                  control={form.control}
                  error={form.formState.errors.selectedCountries}
                  render={() => (
                    <FormItem className="flex flex-col gap-2 w-full">
                      <FormLabel>{t(k.SELECT_COUNTRIES)}</FormLabel>
                      <FormDescription>
                        {t(k.SELECT_COUNTRIES_DESCRIPTION)}
                      </FormDescription>
                      <FormControl>
                        <MultiSelector
                          onValuesChange={(values) =>
                            setSelectedCountries(values)
                          }
                          values={selectedCountries}
                        >
                          <MultiSelectorList>
                            {Object.keys(selectionItems)?.map((country) => (
                              <MultiSelectorItem key={country} value={country}>
                                {t(country, { ns: "countries" })}
                              </MultiSelectorItem>
                            ))}
                          </MultiSelectorList>
                        </MultiSelector>
                      </FormControl>
                      <FormMessage>
                        {form.formState.errors.selectedCountries?.message}
                      </FormMessage>
                    </FormItem>
                  )}
                />
                <FormField
                  name="selectedCities"
                  control={form.control}
                  error={form.formState.errors.selectedCities}
                  render={() => (
                    <FormItem className="flex flex-col gap-2 w-full">
                      <FormLabel>{t(k.SELECT_CITIES)}</FormLabel>
                      <FormDescription>
                        {t(k.SELECT_CITIES_DESCRIPTION)}
                      </FormDescription>
                      <FormControl>
                        <MultiSelector
                          onValuesChange={(values) => setSelectedCities(values)}
                          values={selectedCities}
                          open={true}
                        >
                          <MultiSelectorList>
                            {availableCities.map((city) => (
                              <MultiSelectorItem key={city} value={city}>
                                {t(city, { ns: "cities" })}
                              </MultiSelectorItem>
                            ))}
                          </MultiSelectorList>
                        </MultiSelector>
                      </FormControl>
                      <FormMessage>
                        {form.formState.errors.selectedCities?.message}
                      </FormMessage>
                    </FormItem>
                  )}
                />
                <FormField
                  name="selectedOperators"
                  control={form.control}
                  error={form.formState.errors.selectedOperators}
                  render={() => (
                    <FormItem className="flex flex-col gap-2 w-full">
                      <FormLabel>{t(k.SELECT_OPERATORS)}</FormLabel>
                      <FormDescription>
                        {t(k.SELECT_OPERATORS_DESCRIPTION)}
                      </FormDescription>
                      <FormControl>
                        <MultiSelector
                          onValuesChange={(values) =>
                            setSelectedOperators(values)
                          }
                          values={selectedOperators}
                          open={true}
                        >
                          <MultiSelectorList>
                            {availableOperators.map((operator) => (
                              <MultiSelectorItem
                                key={operator}
                                value={operator}
                              >
                                {operator}
                              </MultiSelectorItem>
                            ))}
                          </MultiSelectorList>
                        </MultiSelector>
                      </FormControl>
                      <FormMessage>
                        {form.formState.errors.selectedCountries?.message}
                      </FormMessage>
                    </FormItem>
                  )}
                />
              </div>
            </div>

            <DialogFooter>
              <div className="flex gap-4">
                <Button
                  onClick={() => setCouponFormOpen(false)}
                  variant="outline"
                >
                  {t(k.CANCEL)}
                </Button>
                <Button
                  onClick={form.handleSubmit((values) => handleSubmit(values))}
                >
                  {selectedCoupon ? t(k.UPDATE_COUPON) : t(k.ADD_COUPON)}
                </Button>
              </div>
            </DialogFooter>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
}
