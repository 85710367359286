import { keys as k } from "common/mod.ts";
import {
  Table,
  TableBody,
  TableHead,
  TableHeader,
  TableRow,
} from "components/ui/table";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import ProxyFilters from "./filters";
import ProxyModal from "./proxy-modal";
import ProxyRow from "./row";

export default function AdminProxiesTable({
  modems,
  users,
  backconnectServers,
}) {
  const { t } = useTranslation();
  const [selectedModem, setSelectedModem] = useState(null);
  const [filteredItems, setFilteredItems] = useState([]);
  const storageColumns = JSON.parse(localStorage.getItem("admin-proxies-cols"));
  const modemInterface = useState(storageColumns?.modemInterface ?? true);
  const internalIP = useState(storageColumns?.internalIP ?? true);
  const gatewayIP = useState(storageColumns?.gatewayIP ?? true);
  const occupancyStatus = useState(storageColumns?.occupancyStatus ?? true);
  const modemStatus = useState(storageColumns?.modemStatus ?? true);
  const loginInfo = useState(storageColumns?.loginInfo ?? true);
  const validUntil = useState(storageColumns?.validUntil ?? true);
  const updatedAt = useState(storageColumns?.updatedAt ?? true);
  const comment = useState(storageColumns?.comment ?? true);
  const actions = useState(storageColumns?.actions ?? true);
  const serverNumber = useState(storageColumns?.serverNumber ?? true);

  const columns = useMemo(
    () => ({
      serverNumber,
      modemInterface,
      internalIP,
      gatewayIP,
      occupancyStatus,
      modemStatus,
      loginInfo,
      validUntil,
      updatedAt,
      comment,
      actions,
    }),
    [
      serverNumber,
      modemInterface,
      internalIP,
      gatewayIP,
      occupancyStatus,
      modemStatus,
      loginInfo,
      validUntil,
      updatedAt,
      comment,
      actions,
    ]
  );

  useEffect(() => {
    localStorage.setItem(
      "admin-proxies-cols",
      JSON.stringify(
        Object.entries(columns).reduce(
          (acc, [key, [value]]) => ({ ...acc, [key]: value }),
          {}
        )
      )
    );
  }, [columns]);

  useEffect(() => {
    setSelectedModem(modems.find((modem) => modem._id === selectedModem?._id));
  }, [modems]);

  return (
    <>
      <ProxyFilters
        users={users}
        modems={modems}
        columns={columns}
        setFilteredItems={setFilteredItems}
      />

      <Table>
        <TableHeader>
          <h2 className="text-lg font-bold text-primary mb-5">
            {t(k.TOTAL)}: {filteredItems.length}
          </h2>
          <TableRow>
            <TableHead>{t(k.LOCATION)}</TableHead>
            {serverNumber[0] && <TableHead>{t(k.SERVER_NUMBER)}</TableHead>}
            {modemInterface[0] && <TableHead>{t(k.INTERFACE)}</TableHead>}
            <TableHead className="hidden md:table-cell">
              {t(k.OPERATOR)}
            </TableHead>
            <TableHead className="text-center">{t(k.SIGNAL)}</TableHead>
            <TableHead>{t(k.PUBLIC_IP)}</TableHead>
            {internalIP[0] && <TableHead>{t(k.INTERNAL_IP)}</TableHead>}
            {gatewayIP[0] && <TableHead>{t(k.GATEWAY_IP)}</TableHead>}
            {modemStatus[0] && (
              <TableHead className="text-center">{t(k.MODEM_STATUS)}</TableHead>
            )}
            {occupancyStatus[0] && (
              <TableHead className="text-center">
                {t(k.OCCUPANCY_STATUS)}
              </TableHead>
            )}

            {loginInfo[0] && (
              <>
                <TableHead>{t(k.USER_LOGIN_INFO)}</TableHead>
                <TableHead>{t(k.ADMIN_LOGIN_INFO)}</TableHead>
              </>
            )}
            {validUntil[0] && (
              <TableHead className="w-[170px] text-center">
                {t(k.VALID_UNTIL)}
              </TableHead>
            )}
            {updatedAt[0] && (
              <TableHead className="w-[170px] text-center">
                {t(k.UPDATED)}
              </TableHead>
            )}
            {comment[0] && (
              <TableHead className="hidden md:table-cell">
                {t(k.COMMENT)}
              </TableHead>
            )}
            {actions[0] && <TableHead>{t(k.ACTIONS)}</TableHead>}
          </TableRow>
        </TableHeader>
        <TableBody>
          {filteredItems.map((modem) => (
            <ProxyRow
              key={modem._id}
              {...modem}
              columns={columns}
              setSelectedModem={setSelectedModem}
              backconnectServers={backconnectServers}
            />
          ))}
        </TableBody>
      </Table>

      {selectedModem && (
        <ProxyModal
          selectedModem={selectedModem}
          setSelectedModem={setSelectedModem}
        />
      )}
    </>
  );
}
