import { AuthProvider } from "providers/AuthProvider";
import React, { useEffect } from "react";
import {
  createBrowserRouter,
  Navigate,
  Outlet,
  RouterProvider,
} from "react-router-dom";
import { toast } from "sonner";

import { ThemeProvider } from "./components/theme-provider";
import LanguageSwitcher from "./components/ui/language-switcher";
import { Toaster } from "./components/ui/sonner";
import { notificationsHandler } from "./handlers/toasts";
import Layout from "./Layout";
import AdminCoupons from "./pages/Admin/Coupons";
import AdminDashboard from "./pages/Admin/Dashboard";
import AdminPaymentHistory from "./pages/Admin/PaymentHistory";
import PricingPlanManagement from "./pages/Admin/PricingPlanManagement";
import AdminProxies from "./pages/Admin/Proxies";
import AdminServers from "./pages/Admin/Servers";
import Users from "./pages/Admin/Users";
import ConfirmUser from "./pages/ConfirmUser";
import ErrorPage from "./pages/ErrorPage";
import IPStatistics from "./pages/General/IPStatistics";
import Proxies from "./pages/General/Proxies";
import Purchase from "./pages/General/Purchase";
import PaymentHistory from "./pages/History/PaymentHistory";
import ProxyHistory from "./pages/History/ProxyHistory";
import Login from "./pages/Login";
import Register from "./pages/Register";
import Account from "./pages/Settings/Account";
import Refill from "./pages/Settings/Refill";
import { socket } from "./socket";

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <AuthProvider>
        <Layout>
          <Outlet />
        </Layout>
      </AuthProvider>
    ),

    children: [
      {
        path: "/",
        element: <Navigate replace to="/proxies" />,
      },

      {
        path: "/proxies",
        element: <Proxies />,
      },
      {
        path: "/account",
        element: <Account />,
      },
      {
        path: "/purchase",
        element: <Purchase />,
      },
      {
        path: "/refill",
        element: <Refill />,
      },
      {
        path: "/history/proxy",
        element: <ProxyHistory />,
      },
      {
        path: "/history/payment",
        element: <PaymentHistory />,
      },
      {
        path: "/admin/dashboard",
        element: <AdminDashboard />,
      },
      {
        path: "/admin/coupons",
        element: <AdminCoupons />,
      },
      {
        path: "/admin/plans",
        element: <PricingPlanManagement />,
      },
      {
        path: "/admin/history/payment",
        element: <AdminPaymentHistory />,
      },
      {
        path: "/admin/proxies",
        element: <AdminProxies />,
      },
      {
        path: "/admin/servers",
        element: <AdminServers />,
      },
      {
        path: "/admin/users",
        element: <Users />,
      },
      {
        path: "/ip-statistics",
        element: <IPStatistics />,
      },
    ],
  },
  {
    path: "/login",
    element: (
      <>
        <LanguageSwitcher className="absolute right-0 top-0 mt-5 mr-5" />
        <Login />
      </>
    ),
  },
  {
    path: "/register",
    element: (
      <>
        <LanguageSwitcher className="absolute right-0 top-0 mt-5 mr-5" />
        <Register />
      </>
    ),
  },
  {
    path: "/confirm/:userId/:token?",
    element: (
      <>
        <LanguageSwitcher className="absolute right-0 top-0 mt-5 mr-5" />
        <ConfirmUser />
      </>
    ),
  },
  {
    path: "*",
    element: (
      <>
        <LanguageSwitcher className="absolute right-0 top-0 mt-5 mr-5" />
        <ErrorPage />
      </>
    ),
  },
]);

function App() {
  useEffect(() => {
    notificationsHandler(socket, toast);
  }, []);

  return (
    <ThemeProvider>
      <Toaster richColors />
      <RouterProvider router={router} />
    </ThemeProvider>
  );
}

export default App;
