import { keys as k } from "common/mod.ts";
import PricingPlanTable from "components/layout/admin/plans";
import { ContentLayout } from "components/layout/panel/content-layout";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from "components/ui/breadcrumb";
import { Card, CardContent, CardFooter, CardHeader } from "components/ui/card";
import React from "react";
import { useTranslation } from "react-i18next";

export default function PricingPlanManagement() {
  const { t } = useTranslation();
  return (
    <ContentLayout title={t(k.PRICING_PLANS)}>
      <Card className="rounded-lg border-none mt-6">
        <CardHeader>
          <Breadcrumb>
            <BreadcrumbList>
              <BreadcrumbItem>
                <BreadcrumbPage>{t(k.ADMIN_PANEL)}</BreadcrumbPage>
              </BreadcrumbItem>
              <BreadcrumbSeparator />
              <BreadcrumbItem>
                <BreadcrumbPage>{t(k.PRICING_PLANS)}</BreadcrumbPage>
              </BreadcrumbItem>
            </BreadcrumbList>
          </Breadcrumb>
        </CardHeader>
        <CardContent>
          <PricingPlanTable />
        </CardContent>
        <CardFooter>
          <div className="text-xs text-muted-foreground"></div>
        </CardFooter>
      </Card>
    </ContentLayout>
  );
}
