import cities from "./cities";
import countries from "./countries";
import general from "./general";
import statuses from "./statuses";

export default {
  cities,
  statuses,
  countries,
  general,
};
