import { keys as k } from "common/mod.ts";
import { Button } from "components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "components/ui/dialog";
import { Input } from "components/ui/input";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { socket } from "socket";
import { toast } from "sonner";

export default function EditCommentModal({
  modemId,
  currentComment = "",
  setEditCommentOpen,
}) {
  const { t } = useTranslation();
  const [comment, setComment] = useState("");

  const handleEditComment = () => {
    toast.success(t(k.ACTIONS_COMMENT_UPDATED));
    socket.emit("main:action:update-comment", modemId, comment);
    setEditCommentOpen(false);
  };

  return (
    <Dialog defaultOpen>
      <DialogTrigger></DialogTrigger>
      <DialogContent
        closeClick={() => setEditCommentOpen(false)}
        onEscapeKeyDown={() => setEditCommentOpen(false)}
        onPointerDownOutside={() => setEditCommentOpen(false)}
      >
        <DialogHeader>
          <DialogTitle>{t(k.EDIT_COMMENT)}</DialogTitle>
        </DialogHeader>
        <div className="flex flex-col gap-4">
          <div>
            <Input
              label="Comment"
              defaultValue={currentComment}
              placeholder={t(k.ENTER_COMMENT)}
              onChange={(e) => setComment(e.target.value)}
            />
          </div>
          <DialogFooter>
            <div className="flex gap-4">
              <Button
                onClick={() => setEditCommentOpen(false)}
                variant="outline"
              >
                {t(k.CANCEL)}
              </Button>
              <Button
                variant="destructive"
                disabled={comment === currentComment}
                onClick={() => handleEditComment()}
              >
                {t(k.EDIT_COMMENT)}
              </Button>
            </div>
          </DialogFooter>
        </div>
      </DialogContent>
    </Dialog>
  );
}
