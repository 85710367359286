import { keys as k } from "common/mod.ts";
import { SignalStrengthIcon } from "components/icons/signal";
import LoginInfo from "components/layout/general/proxies/login-info";
import { Badge } from "components/ui/badge";
import { Button } from "components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "components/ui/dropdown-menu";
import { TableCell, TableRow } from "components/ui/table";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "components/ui/tooltip";
import { format, formatDuration, intervalToDuration } from "date-fns";
import i18n from "i18n";
import {
  DATETIME_FORMAT,
  LESS_THAN_1_DAY_FORMAT,
  LESS_THAN_1_MONTH_FORMAT,
  localeMap,
  MORE_THAN_1_MONTH_FORMAT,
  NAMESPACES,
  occupancyStatusMap,
  statusMap,
} from "lib/utils";
import { MoreHorizontal } from "lucide-react";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { socket } from "socket";
import { toast } from "sonner";

import AssignModemModal from "./assign-modal";
import AssignTrialModal from "./assign-trial-modal";
import ClearSession from "./clear-session-modal";
import DeleteModal from "./delete-modal";
import DHCPModal from "./dhcp-modal";
import ExtendSessionModal from "./extend-modal";
import RebootModal from "./reboot-modal";
import ResetModal from "./reset-modal";
import StatusSwitchOpen from "./status-switcher-modal";
import SwitchModal from "./switch-modal";

export default function ProxyRow({
  columns,
  setSelectedModem,
  backconnectServers,
  ...modem
}) {
  const { t } = useTranslation(Object.values(NAMESPACES));
  const [durationLeft, setDurationLeft] = useState("");
  const [rebootOpen, setRebootOpen] = useState(false);
  const [resetOpen, setResetOpen] = useState(false);
  const [statusSwitchOpen, setStatusSwitchOpen] = useState(false);
  const [dhcpOpen, setDhcpOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [switchOpen, setSwitchOpen] = useState(false);
  const [clearSessionOpen, setClearSessionOpen] = useState(false);
  const [assignModemOpen, setAssignModemOpen] = useState(false);
  const [assignTrialOpen, setAssignTrialOpen] = useState(false);
  const [extendOpen, setExtendOpen] = useState(false);

  const {
    _id,
    operator,
    publicIP,
    status,
    session,
    server,
    httpPort,
    socks5Port,
    adminUser,
    signal,
    internalIP,
    gatewayIP,
    count,
    occupancyStatus,
    interface: interfaceName,
    direct,
  } = modem;

  let user;
  let limitTime;
  let comment;
  let authKey;
  if (session) ({ user, limitTime, comment, authKey } = session);

  const { city, country, publicURL, serverNumber } = server;

  const backConnectSocks5Port = `2${serverNumber}${interfaceName.replace("modem_", "").padStart(3, "0")}`;
  const backConnectHttpPort = `4${serverNumber}${interfaceName.replace("modem_", "").padStart(3, "0")}`;

  const handleChangeIp = () => {
    toast.success(t(k.ACTIONS_IP_CHANGE_REQUESTED));
    socket.emit("main:action:changeIp", _id);
  };

  useEffect(() => {
    let durationFormat;
    const interval = intervalToDuration({ start: Date.now(), end: limitTime });
    if (interval.months > 0) durationFormat = MORE_THAN_1_MONTH_FORMAT;
    else {
      if (interval.days > 0) durationFormat = LESS_THAN_1_MONTH_FORMAT;
      else durationFormat = LESS_THAN_1_DAY_FORMAT;
    }
    setDurationLeft(
      formatDuration(
        intervalToDuration({ start: Date.now(), end: limitTime }),
        { format: durationFormat, locale: localeMap[i18n.language] }
      )
    );
  }, [limitTime]);

  return (
    <>
      <TableRow>
        <TableCell className="font-medium">
          {t(city, { ns: "cities" })}, {t(country, { ns: "countries" })}
        </TableCell>
        {columns.serverNumber[0] && <TableCell>#{serverNumber}</TableCell>}
        {columns.modemInterface[0] && (
          <TableCell className="hidden md:table-cell">
            {interfaceName}
          </TableCell>
        )}
        <TableCell className="hidden md:table-cell">{operator}</TableCell>
        <TableCell className="text-center">
          <SignalStrengthIcon signal={signal} className="w-[25px] mx-auto" />
        </TableCell>
        <TableCell> {publicIP} </TableCell>
        {columns.internalIP[0] && <TableCell> {internalIP}</TableCell>}
        {columns.gatewayIP[0] && <TableCell> {gatewayIP}</TableCell>}
        {columns.modemStatus[0] && (
          <TableCell className="text-center">
            <Badge
              className={`text-xs ${statusMap[status].color} relative`}
              variant="outline"
            >
              {t(statusMap[status].text.toUpperCase(), { ns: "statuses" })}
              {count > 0 && (
                <Badge className="absolute -top-2 -right-4 text-white px-1 rounded-full text-xs font-medium">
                  {count}
                </Badge>
              )}
            </Badge>
          </TableCell>
        )}
        {columns.occupancyStatus[0] && (
          <TableCell className="text-center">
            <Badge
              className={`text-xs ${occupancyStatusMap[occupancyStatus].color}`}
              variant="outline"
            >
              {t(occupancyStatusMap[occupancyStatus].text.toUpperCase(), {
                ns: "statuses",
              })}
            </Badge>
          </TableCell>
        )}
        {columns.loginInfo[0] && (
          <>
            <TableCell>
              {user?.username && (
                <LoginInfo
                  modemId={_id}
                  username={user.username}
                  password={user.password}
                  serverNumber={serverNumber}
                  httpPort={httpPort}
                  socks5Port={socks5Port}
                  backConnectHttpPort={backConnectHttpPort}
                  backConnectSocks5Port={backConnectSocks5Port}
                  url={publicURL}
                  direct={direct}
                  backconnectServers={backconnectServers}
                  changeIpUrl={`https://${window.location.host}/api/proxy/modems/${_id}/changeIp/${authKey}`}
                />
              )}
            </TableCell>
            <TableCell>
              <LoginInfo
                username={adminUser.username}
                password={adminUser.password}
                serverNumber={serverNumber}
                httpPort={httpPort}
                socks5Port={socks5Port}
                backConnectHttpPort={backConnectHttpPort}
                backConnectSocks5Port={backConnectSocks5Port}
                url={publicURL}
                backconnectServers={backconnectServers}
              />
            </TableCell>
          </>
        )}
        {columns.validUntil[0] && (
          <TableCell className="flex flex-col items-center md:w-[170px]">
            {limitTime && (
              <>
                <Badge className="text-center">{durationLeft}</Badge>
                <span className="hidden md:block">
                  {format(limitTime, DATETIME_FORMAT, {
                    locale: localeMap[i18n.language],
                  })}
                </span>
              </>
            )}
          </TableCell>
        )}
        {columns.comment[0] && (
          <TableCell>
            <TooltipProvider delayDuration={100}>
              <Tooltip>
                <TooltipTrigger className="w-[100px] whitespace-nowrap overflow-hidden text-ellipsis text-left">
                  {comment}
                </TooltipTrigger>
                <TooltipContent>
                  <p>{comment}</p>
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          </TableCell>
        )}
        {columns.updatedAt[0] && (
          <TableCell>{format(modem.updatedAt, DATETIME_FORMAT)}</TableCell>
        )}

        {columns.actions[0] && (
          <TableCell>
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button aria-haspopup="true" size="icon" variant="ghost">
                  <MoreHorizontal className="h-4 w-4" />
                  <span className="sr-only">{t(k.SHOW_ACTIONS)}</span>
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent align="end">
                <DropdownMenuLabel>{t(k.ACTIONS)}</DropdownMenuLabel>
                <DropdownMenuSeparator />
                <DropdownMenuItem
                  className="font-semibold"
                  onClick={() => setSelectedModem(modem)}
                >
                  {t(k.SHOW_MODEM)}
                </DropdownMenuItem>
                <DropdownMenuItem onClick={() => handleChangeIp()}>
                  {t(k.CHANGE_IP)}
                </DropdownMenuItem>
                <DropdownMenuItem onClick={() => setDhcpOpen(true)}>
                  {t(k.CHANGE_DHCP)}
                </DropdownMenuItem>
                <DropdownMenuItem onClick={() => setRebootOpen(true)}>
                  {t(k.REBOOT)}
                </DropdownMenuItem>
                <DropdownMenuItem onClick={() => setResetOpen(true)}>
                  {t(k.RESET)}
                </DropdownMenuItem>
                <DropdownMenuItem onClick={() => setStatusSwitchOpen(true)}>
                  {status === "disabled" ? t(k.ENABLE) : t(k.DISABLE)}
                </DropdownMenuItem>
                <DropdownMenuItem
                  disabled={occupancyStatus === "free"}
                  onClick={() => setSwitchOpen(true)}
                >
                  {t(k.SWITCH)}
                </DropdownMenuItem>
                <DropdownMenuItem
                  disabled={occupancyStatus === "free" && !session?.limitTime}
                  onClick={() => setClearSessionOpen(true)}
                >
                  {t(k.CLEAR_SESSION)}
                </DropdownMenuItem>
                <DropdownMenuItem
                  disabled={occupancyStatus === "busy"}
                  onClick={() => setAssignModemOpen(true)}
                >
                  {t(k.ASSIGN)}
                </DropdownMenuItem>
                <DropdownMenuItem
                  disabled={occupancyStatus === "busy"}
                  onClick={() => setAssignTrialOpen(true)}
                >
                  {t(k.ASSIGN_TRIAL)}
                </DropdownMenuItem>

                <DropdownMenuItem
                  disabled={occupancyStatus === "free"}
                  onClick={() => setExtendOpen(true)}
                >
                  {t(k.EXTEND)}
                </DropdownMenuItem>
                <DropdownMenuItem
                  className="text-destructive"
                  onClick={() => setDeleteOpen(true)}
                >
                  {t(k.DELETE)}
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
            {dhcpOpen && (
              <DHCPModal
                modemId={_id}
                modemGatewayIP={gatewayIP}
                setDhcpOpen={setDhcpOpen}
              />
            )}
            {rebootOpen && (
              <RebootModal modemId={_id} setRebootOpen={setRebootOpen} />
            )}
            {resetOpen && (
              <ResetModal modemId={_id} setResetOpen={setResetOpen} />
            )}
            {statusSwitchOpen && (
              <StatusSwitchOpen
                modemId={_id}
                status={status}
                setStatusSwitchOpen={setStatusSwitchOpen}
              />
            )}
            {switchOpen && (
              <SwitchModal
                modemId={_id}
                serverNumber={serverNumber}
                setSwitchOpen={setSwitchOpen}
              />
            )}
            {clearSessionOpen && (
              <ClearSession
                modemId={_id}
                setClearSessionOpen={setClearSessionOpen}
              />
            )}
            {assignModemOpen && (
              <AssignModemModal
                modemId={_id}
                setAssignModemOpen={setAssignModemOpen}
              />
            )}
            {assignTrialOpen && (
              <AssignTrialModal
                modemId={_id}
                setAssignTrialOpen={setAssignTrialOpen}
              />
            )}

            {extendOpen && (
              <ExtendSessionModal
                modemId={_id}
                limitTime={limitTime}
                setExtendOpen={setExtendOpen}
              />
            )}

            {deleteOpen && (
              <DeleteModal modemId={_id} setDeleteOpen={setDeleteOpen} />
            )}
          </TableCell>
        )}
      </TableRow>
    </>
  );
}
