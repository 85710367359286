export const backconnectServersHandler = (socket, setBackconnectServers) => {
  function handler(payload) {
    const backconnectServers = payload;
    setBackconnectServers(() => backconnectServers);
  }
  socket.on("web:data:backconnect-servers", handler);
  return () => {
    socket.off("web:data:backconnect-servers", handler);
  };
};
