import { zodResolver } from "@hookform/resolvers/zod";
// import { z } from "zod";
import { Button } from "components/ui/button";
import { Form } from "components/ui/form";
import { cn } from "lib/utils";
import React from "react";
import { useForm } from "react-hook-form";

import AutoFormObject from "./fields/object";
import { getDefaultValues, getObjectFormSchema } from "./utils";

export function AutoFormSubmit({ children, className, disabled }) {
  return (
    <Button type="submit" disabled={disabled} className={className}>
      {children ?? "Submit"}
    </Button>
  );
}

function AutoForm({
  formSchema,
  values: valuesProp,
  onValuesChange: onValuesChangeProp,
  onParsedValuesChange,
  mode,
  onSubmit: onSubmitProp,
  fieldConfig,
  children,
  className,
  dependencies,
}) {
  const objectFormSchema = getObjectFormSchema(formSchema);
  const defaultValues = getDefaultValues(objectFormSchema, fieldConfig);

  const form = useForm({
    resolver: zodResolver(formSchema),
    defaultValues: defaultValues ?? undefined,
    values: valuesProp,
    mode,
  });

  function onSubmit(values) {
   
    const parsedValues = formSchema.safeParse(values);
    if (parsedValues.success) {
      onSubmitProp?.(parsedValues.data);
    }
  }

  const values = form.watch();
  // valuesString is needed because form.watch() returns a new object every time
  const valuesString = JSON.stringify(values);

  React.useEffect(() => {
    onValuesChangeProp?.(values);
    const parsedValues = formSchema.safeParse(values);
    if (parsedValues.success) {
      onParsedValuesChange?.(parsedValues.data);
    }
  }, [valuesString]);

  const renderChildren =
    typeof children === "function" ? children(form.formState) : children;

  return (
    <div className="w-full">
      <Form {...form}>
        <form
          onSubmit={(e) => {
            form.handleSubmit(onSubmit)(e);
          }}
          className={cn("space-y-5", className)}
        >
          <AutoFormObject
            schema={objectFormSchema}
            form={form}
            dependencies={dependencies}
            fieldConfig={fieldConfig}
          />

          {renderChildren}
        </form>
      </Form>
    </div>
  );
}

export default AutoForm;
