import { clsx } from "clsx";
import { keys as k } from "common/mod.ts";
import { enUS as en, ru } from "date-fns/locale";
import { t } from "i18next";
import { toast } from "sonner";
import { twMerge } from "tailwind-merge";

export const DATETIME_FORMAT = "dd/MM/yyyy HH:mm";
export const LESS_THAN_1_DAY_FORMAT = ["hours", "minutes"];
export const LESS_THAN_1_MONTH_FORMAT = ["days", "hours"];
export const MORE_THAN_1_MONTH_FORMAT = ["months", "days"];

export const localeMap = {
  en: en,
  ru: ru,
};

export const NAMESPACES = {
  GENERAL: "general",
  STATUSES: "statuses",
  COUNTRIES: "countries",
  CITIES: "cities",
};

export const occupancyStatusMap = {
  free: {
    text: "free",
    color: "bg-green text-white",
  },
  reserved: {
    text: "reserved",
    color: "bg-yellow text-white",
  },
  busy: {
    text: "busy",
    color: "bg-destructive text-white",
  },
};

export const statusMap = {
  available: {
    text: "available",
    color: "bg-green text-white",
  },
  disabled: {
    text: "disabled",
    color: "bg-black text-white",
  },
  unavailable: {
    text: "unavailable",
    color: "bg-destructive text-white",
  },
  no_internet: {
    text: "no_internet",
    color: "bg-yellow text-white",
  },
};

export const couponStatusMap = {
  active: {
    text: "active",
    color: "bg-green text-white",
  },
  inactive: {
    text: "inactive",
    color: "bg-destructive text-white",
  },
};

export const couponTypes = {
  percent: "COUPON_PERCENTAGE",
  fixed: "COUPON_FIXED",
};

export const planPeriods = [7, 14, 30];

export function cn(...inputs) {
  return twMerge(clsx(inputs));
}

export function handleCopy(content) {
  if (!navigator.clipboard) {
    toast.error(t(k.NOTIFICATION_CLIPBOARD_NOT_SUPPORTED));
    return;
  }
  navigator.clipboard.writeText(content);
  toast.success(t(k.NOTIFICATION_COPIED_TO_CLIPBOARD, { content }));
  // toast.info(`Copied to clipboard: ${content}`);
}

export const toReadableSize = (data) => {
  data = parseInt(data);
  const sizes = ["B", "KB", "MB", "GB", "TB"];
  let i = 0;
  while (data > 1024) {
    data /= 1024;
    i++;
  }
  return `${Math.ceil(data.toFixed(2))} ${sizes[i]}`;
};

export const toReadableSpeed = (data) => {
  if (data < 1000) return `0`;
  data = parseFloat(data) * 8; // Convert to bits
  const sizes = ["Bit/s", "KBit/s", "MBit/s", "GBit/s", "TBit/s"];
  let i = 0;
  while (data > 1024) {
    data /= 1024;
    i++;
  }
  return `${data.toFixed(2)} ${sizes[i]}`;
};

export const getUsedTraffic = (used, limit) => {
  let usedTraffic = toReadableSize(used);
  if (limit) usedTraffic += ` / ${toReadableSize(limit)}`;
  return usedTraffic;
};
