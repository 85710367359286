import { keys as k } from "common/mod.ts";
import { Button } from "components/ui/button";
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "components/ui/dropdown-menu";
import { SettingsIcon } from "lucide-react";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

export default function IPStatisticsColumns({
  selectedCityColumn,
  setSelectedCityColumn,
  selectedServerColumn,
  setSelectedServerColumn,
  selectedOperatorColumn,
  setSelectedOperatorColumn,
}) {
  const { t } = useTranslation();

  useEffect(() => {
    if (selectedOperatorColumn) {
      setSelectedServerColumn(true);
    }
  }, [selectedOperatorColumn]);

  useEffect(() => {
    if (selectedServerColumn) {
      setSelectedCityColumn(true);
    } else {
      setSelectedOperatorColumn(false);
    }
  }, [selectedServerColumn]);

  useEffect(() => {
    if (!selectedCityColumn) {
      setSelectedServerColumn(false);
      setSelectedOperatorColumn(false);
    }
  }, [selectedCityColumn]);

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant="outline" size="sm">
          <SettingsIcon className="h-4 w-4 mr-2" />
          {t(k.COLUMNS)}
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end" className="w-[200px]">
        <DropdownMenuCheckboxItem checked disabled>
          {t(k.COUNTRY)}
        </DropdownMenuCheckboxItem>
        <DropdownMenuCheckboxItem checked disabled>
          {t(k.COUNT)}
        </DropdownMenuCheckboxItem>
        <DropdownMenuSeparator />
        <DropdownMenuCheckboxItem
          checked={selectedCityColumn}
          onCheckedChange={(v) => setSelectedCityColumn(v)}
        >
          {t(k.CITY)}
        </DropdownMenuCheckboxItem>
        <DropdownMenuCheckboxItem
          checked={selectedServerColumn}
          onCheckedChange={(v) => setSelectedServerColumn(v)}
        >
          {t(k.LOCATION_NUMBER)}
        </DropdownMenuCheckboxItem>
        <DropdownMenuCheckboxItem
          checked={selectedOperatorColumn}
          onCheckedChange={(v) => setSelectedOperatorColumn(v)}
        >
          {t(k.OPERATOR)}
        </DropdownMenuCheckboxItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
