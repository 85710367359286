import { keys as k } from "common/mod.ts";
import { Button } from "components/ui/button";
// import { Tabs, TabsContent, TabsList, TabsTrigger } from "components/ui/tabs";
import { Label } from "components/ui/label";
import { Popover, PopoverContent, PopoverTrigger } from "components/ui/popover";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "components/ui/select";
import { Spinner } from "components/ui/spinner";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "components/ui/table";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "components/ui/tooltip";
import {
  deletePricingPlanHandler,
  pricingPlansHandler,
  updatePricingPlanHandler,
} from "handlers/pricing-plans";
import { NAMESPACES } from "lib/utils";
import { CheckIcon, CopyIcon, EditIcon, XIcon } from "lucide-react";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { socket } from "socket";

import PlanDialog from "./plan-dialog";

const clearPlan = {
  country: "",
  city: "",
  operator: "",
  subPlans: [],
};

export default function PricingPlanTable() {
  const { t } = useTranslation([
    NAMESPACES.GENERAL,
    NAMESPACES.COUNTRIES,
    NAMESPACES.CITIES,
  ]);
  const [pricingPlans, setPricingPlans] = useState([]);
  const [locations, setLocations] = useState([]);
  const [plan, setPlan] = useState(null);
  const [filterCountry, setFilterCountry] = useState("all");
  const [filterCity, setFilterCity] = useState("all");
  const [filterOperator, setFilterOperator] = useState("all");

  useEffect(() => {
    socket.emit("main:fetch:pricing-plans");
  }, []);

  useEffect(
    () => pricingPlansHandler(socket, setPricingPlans, setLocations),
    []
  );

  useEffect(() => {
    updatePricingPlanHandler(socket, setPricingPlans, setPlan);
  }, []);

  useEffect(() => {
    deletePricingPlanHandler(socket, setPricingPlans);
  }, []);

  const handleCopyPlan = (index) => {
    setPlan({ ...pricingPlans[index], _id: null });
  };

  const handleUpdatePlan = (index) => {
    setPlan({ ...pricingPlans[index] });
  };

  const handleDeletePlan = (index) => () => {
    socket.emit("main:action:delete-pricing-plan", pricingPlans[index]._id);
  };

  const filteredPlans = useMemo(() => {
    return pricingPlans.filter((plan) => {
      if (filterCountry !== "all" && plan.country !== filterCountry) {
        return false;
      }
      if (filterCity !== "all" && plan.city !== filterCity) {
        return false;
      }
      if (filterOperator !== "all" && plan.operator !== filterOperator) {
        return false;
      }
      return true;
    });
  }, [pricingPlans, filterCountry, filterCity, filterOperator]);

  const planCountries = useMemo(
    () => [...new Set(pricingPlans.map((plan) => plan.country))],
    [pricingPlans]
  );

  const planCities = useMemo(
    () => [
      ...new Set(
        pricingPlans
          .filter((p) => p.country === filterCountry || filterCountry === "all")
          .map((plan) => plan.city)
      ),
    ],
    [pricingPlans, filterCountry]
  );

  const planOperators = useMemo(
    () => [
      ...new Set(
        pricingPlans
          .filter((p) => p.country === filterCountry || filterCountry === "all")
          .filter((p) => p.city === filterCity || filterCity === "all")
          .map((plan) => plan.operator)
      ),
    ],
    [pricingPlans, filterCountry, filterCity]
  );

  return (
    <div className="container mx-auto py-12 px-4 md:px-6">
      <div className="flex items-center justify-between mb-8">
        <h1 className="text-3xl font-bold">{t(k.PRICING_PLANS)}</h1>
      </div>
      <div className="flex flex-col">
        <div className="flex gap-4 row">
          <div className="grid gap-2 w-[150px]">
            <Label htmlFor="filter-country">{t(k.COUNTRY)}</Label>
            <Select
              id="filter-country"
              value={filterCountry}
              onValueChange={setFilterCountry}
            >
              <SelectTrigger>
                <SelectValue placeholder={t(k.SELECT_COUNTRY)} />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="all">{t(k.ALL)}</SelectItem>
                {planCountries.map((country) => (
                  <SelectItem key={country} value={country}>
                    {t(country, { ns: "countries" })}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </div>

          <div className="grid gap-2 w-[150px]">
            <Label htmlFor="filter-city">{t(k.CITY)}</Label>
            <Select
              id="filter-city"
              value={filterCity}
              onValueChange={setFilterCity}
            >
              <SelectTrigger>
                <SelectValue placeholder={t(k.SELECT_CITY)} />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="all">{t(k.ALL)}</SelectItem>
                {planCities.map((city) => (
                  <SelectItem key={city} value={city}>
                    {t(city, { ns: "cities" })}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </div>
          <div className="grid gap-2 w-[150px]">
            <Label htmlFor="filter-operator">{t(k.OPERATOR)}</Label>
            <Select
              id="filter-operator"
              value={filterOperator}
              onValueChange={setFilterOperator}
            >
              <SelectTrigger>
                <SelectValue placeholder={t(k.SELECT_OPERATOR)} />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="all">{t(k.ALL)}</SelectItem>
                {planOperators.map((operator) => (
                  <SelectItem key={operator} value={operator}>
                    {operator}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </div>
        </div>
        {!pricingPlans.length ? (
          <Spinner className="text-primary flex justify-center" />
        ) : (
          <div className="grid gap-8">
            <Table className="mt-8 table-auto">
              <TableHeader>
                <TableRow>
                  <TableHead className="text-xs md:text-base text-center">
                    {t(k.OPERATOR)}
                  </TableHead>
                  <TableHead className="text-xs md:text-base text-center">
                    {t(k.LOCATION)}
                  </TableHead>
                  <TableHead className="text-xs md:text-base text-center">
                    {t(k.PLANS)}
                  </TableHead>
                  <TableHead className="text-xs md:text-base text-center">
                    {t(k.ACTIONS)}
                  </TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {filteredPlans.map((plan, index) => (
                  <TableRow key={index}>
                    <TableCell className="text-xs md:text-sm">
                      {plan.operator}
                    </TableCell>
                    <TableCell className="text-xs md:text-sm">
                      {t(plan.country, { ns: "countries" })},{" "}
                      {t(plan.city, { ns: "cities" })}
                    </TableCell>
                    <TableCell>
                      <Popover>
                        <PopoverTrigger asChild className="text-center p-0">
                          <Button variant="link" className="text-xs md:text-sm">
                            {t(k.VIEW_PLANS)}
                          </Button>
                        </PopoverTrigger>
                        <PopoverContent className="w-[600px] p-6">
                          {plan.subPlans.map((planDetails, i) => (
                            <div key={i} className="border-b pb-4 mb-4">
                              <div className="flex items-center justify-between">
                                <div>
                                  <h4 className="text-lg font-bold">
                                    {planDetails.period} {t(k.DAYS)}
                                  </h4>
                                  <p className="text-muted-foreground">
                                    {planDetails.description}
                                  </p>
                                </div>
                                <div className="text-2xl font-bold">
                                  ${planDetails.price}
                                </div>
                              </div>
                              <ul className="mt-2 text-sm text-muted-foreground">
                                {planDetails.features.map((feature, j) => (
                                  <li key={j} className="flex items-center">
                                    <CheckIcon className="w-4 h-4 mr-2 text-primary" />
                                    {feature}
                                  </li>
                                ))}
                              </ul>
                            </div>
                          ))}
                        </PopoverContent>
                      </Popover>
                    </TableCell>
                    <TableCell>
                      <div className="flex items-center justify-left">
                        <TooltipProvider delayDuration={100}>
                          <Tooltip>
                            <TooltipTrigger>
                              <Button
                                variant="outline"
                                size="sm"
                                className="mr-2"
                                onClick={() => handleCopyPlan(index)}
                              >
                                <CopyIcon className="w-4 h-4" />
                              </Button>
                            </TooltipTrigger>

                            <TooltipContent>{t(k.COPY_PLAN)}</TooltipContent>
                          </Tooltip>
                        </TooltipProvider>

                        <TooltipProvider delayDuration={100}>
                          <Tooltip>
                            <TooltipTrigger>
                              <Button
                                variant="outline"
                                size="sm"
                                className="mr-2"
                                onClick={() => handleUpdatePlan(index)}
                              >
                                <EditIcon className="w-4 h-4" />
                              </Button>
                            </TooltipTrigger>

                            <TooltipContent>{t(k.EDIT_PLAN)}</TooltipContent>
                          </Tooltip>
                        </TooltipProvider>

                        <TooltipProvider delayDuration={100}>
                          <Tooltip>
                            <TooltipTrigger>
                              <Button
                                variant="outline"
                                size="sm"
                                onClick={handleDeletePlan(index)}
                              >
                                <XIcon className="w-4 h-4" />
                              </Button>
                            </TooltipTrigger>

                            <TooltipContent>{t(k.DELETE_PLAN)}</TooltipContent>
                          </Tooltip>
                        </TooltipProvider>
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        )}
        <div className="mt-8">
          <div className="flex items-center justify-center">
            <Button size="lg" onClick={() => setPlan(clearPlan)}>
              {t(k.ADD_PLAN)}
            </Button>
          </div>
          {plan && (
            <PlanDialog plan={plan} setPlan={setPlan} locations={locations} />
          )}
        </div>
      </div>
    </div>
  );
}
