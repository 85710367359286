import { keys as k } from "common/mod.ts";
import { SignalStrengthIcon } from "components/icons/signal";
import { Badge } from "components/ui/badge";
import { Button } from "components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "components/ui/dropdown-menu";
import { Switch } from "components/ui/switch";
import { TableCell, TableRow } from "components/ui/table";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "components/ui/tooltip";
import { format, formatDuration, intervalToDuration } from "date-fns";
import i18n from "i18n";
import {
  DATETIME_FORMAT,
  LESS_THAN_1_DAY_FORMAT,
  LESS_THAN_1_MONTH_FORMAT,
  localeMap,
  MORE_THAN_1_MONTH_FORMAT,
  NAMESPACES,
  statusMap,
} from "lib/utils";
import { MoreHorizontal } from "lucide-react";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { socket } from "socket";
import { toast } from "sonner";

import EditCommentModal from "./edit-modal";
import LoginInfo from "./login-info";
import RenewalModal from "./renewal-modal";

export default function ProxyRow({ modem, pricingPlans, backconnectServers }) {
  const { t } = useTranslation(Object.values(NAMESPACES));
  const [durationLeft, setDurationLeft] = useState("");
  const [currentRenew, setCurrentRenew] = useState(modem.session.renewPeriod);
  const [confirmRenew, setConfirmRenew] = useState(false);
  const [modifyRenew, setModifyRenew] = useState(false);

  const [renewModalOpen, setRenewModalOpen] = useState(false);
  const [editCommentOpen, setEditCommentOpen] = useState(false);
  const {
    _id,
    operator,
    publicIP,
    status,
    session,
    server,
    httpPort,
    socks5Port,
    signal,
    direct,
    interface: interfaceName,
  } = modem;
  const {
    user,
    limitTime,
    comment,
    authKey,
    plan: currentPlan,
    renewPeriod,
  } = session;
  const { city, country, publicURL, serverNumber } = server;

  const backConnectSocks5Port = `2${serverNumber}${interfaceName.replace("modem_", "").padStart(3, "0")}`;
  const backConnectHttpPort = `4${serverNumber}${interfaceName.replace("modem_", "").padStart(3, "0")}`;

  const pricePlan = pricingPlans.find(
    (plan) =>
      plan._id === currentPlan ||
      (plan.country === country &&
        plan.city === city &&
        plan.operator === operator)
  );

  const handleChangeIp = () => {
    toast.success(t(k.ACTIONS_IP_CHANGE_REQUESTED));
    socket.emit("main:action:changeIp", _id);
  };

  const handleReboot = () => {
    toast.success(t(k.ACTIONS_REBOOT_REQUESTED));
    socket.emit("main:action:reboot", _id);
  };

  const handleReset = () => {
    toast.success(t(k.ACTIONS_RESET_REQUESTED));
    socket.emit("main:action:reset", _id);
  };

  const handleRenew = (data) => {
    toast.success(t(k.ACTIONS_RENEW_UPDATED));
    socket.emit("main:action:update-renewal", _id, data);
  };

  useEffect(() => {
    let durationFormat;
    const interval = intervalToDuration({ start: Date.now(), end: limitTime });
    if (interval.months > 0) durationFormat = MORE_THAN_1_MONTH_FORMAT;
    else {
      if (interval.days > 0) durationFormat = LESS_THAN_1_MONTH_FORMAT;
      else durationFormat = LESS_THAN_1_DAY_FORMAT;
    }
    setDurationLeft(
      formatDuration(
        intervalToDuration({ start: Date.now(), end: limitTime }),
        { format: durationFormat, locale: localeMap[i18n.language] }
      )
    );
  }, [limitTime]);

  useEffect(() => {
    setCurrentRenew(modem.session.renewPeriod);
  }, [modem.session.renewPeriod]);

  useEffect(() => {
    if (currentRenew && !modem.session.renewPeriod) setRenewModalOpen(true);
  }, [currentRenew, modem.session.renewPeriod]);

  useEffect(() => {
    if (!currentRenew && modem.session.renewPeriod) setConfirmRenew(true);
    else setConfirmRenew(false);
  }, [currentRenew, modem.session.renewPeriod]);

  useEffect(() => {
    if (currentRenew && !confirmRenew && modem.session.renewPeriod)
      setModifyRenew(true);
    else setModifyRenew(false);
  }, [currentRenew, modem.session.renewPeriod, confirmRenew, modifyRenew]);

  return (
    <TableRow>
      <TableCell className="font-medium">
        {t(city, { ns: "cities" })}, {t(country, { ns: "countries" })}
      </TableCell>
      <TableCell className="hidden md:table-cell">{operator}</TableCell>
      <TableCell className="text-center">
        <SignalStrengthIcon signal={signal} className="w-[25px] mx-auto" />
      </TableCell>
      <TableCell> {publicIP} </TableCell>
      <TableCell className="text-center">
        <Badge
          className={`text-xs ${statusMap[status].color}`}
          variant="outline"
        >
          {t(statusMap[status].text.toUpperCase(), { ns: "statuses" })}
        </Badge>
      </TableCell>
      <TableCell>
        <LoginInfo
          modemId={_id}
          username={user.username}
          password={user.password}
          httpPort={httpPort}
          socks5Port={socks5Port}
          backConnectHttpPort={backConnectHttpPort}
          backConnectSocks5Port={backConnectSocks5Port}
          url={publicURL}
          direct={direct}
          backconnectServers={backconnectServers}
          changeIpUrl={`https://${window.location.host}/api/proxy/modems/${_id}/changeIp/${authKey}`}
        />
      </TableCell>
      <TableCell>
        <div className="flex items-center gap-4">
          <Switch
            id="renew"
            checked={currentRenew}
            onCheckedChange={(value) => setCurrentRenew(value)}
          />
          {confirmRenew && (
            <Button
              onClick={() => handleRenew({ renewPeriod: null })}
              size="sm"
            >
              {t(k.CONFIRM)}
            </Button>
          )}
          {modifyRenew && (
            <Button onClick={() => setRenewModalOpen(true)} size="sm">
              {t(k.MODIFY)}
            </Button>
          )}
        </div>
      </TableCell>

      <TableCell className="flex flex-col items-center md:w-[170px]">
        <Badge className="text-center">{durationLeft}</Badge>
        <span className="hidden md:block">
          {format(limitTime, DATETIME_FORMAT, {
            locale: localeMap[i18n.language],
          })}
        </span>
      </TableCell>
      <TableCell>
        <TooltipProvider delayDuration={100}>
          <Tooltip>
            <TooltipTrigger className="w-[100px] whitespace-nowrap overflow-hidden text-ellipsis text-left">
              {comment}
            </TooltipTrigger>
            <TooltipContent>
              <p>{comment}</p>
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
      </TableCell>
      <TableCell>
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button aria-haspopup="true" size="icon" variant="ghost">
              <MoreHorizontal className="h-4 w-4" />
              <span className="sr-only">{t(k.TOGGLE_MENU)}</span>
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent align="end">
            <DropdownMenuLabel>{t(k.ACTIONS)}</DropdownMenuLabel>
            <DropdownMenuSeparator />
            <DropdownMenuItem onClick={() => handleChangeIp()}>
              {t(k.CHANGE_IP)}
            </DropdownMenuItem>
            <DropdownMenuItem onClick={() => handleReboot()}>
              {t(k.REBOOT)}
            </DropdownMenuItem>
            <DropdownMenuItem onClick={() => handleReset()}>
              {t(k.RESET)}
            </DropdownMenuItem>

            <DropdownMenuItem onClick={() => setEditCommentOpen(true)}>
              {t(k.EDIT_COMMENT)}
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
        {editCommentOpen && (
          <EditCommentModal
            modemId={_id}
            currentComment={comment}
            setEditCommentOpen={setEditCommentOpen}
          />
        )}
        {renewModalOpen && (
          <RenewalModal
            pricingPlan={pricePlan}
            period={renewPeriod}
            handleRenew={handleRenew}
            setRenewModalOpen={setRenewModalOpen}
          />
        )}
      </TableCell>
    </TableRow>
  );
}
