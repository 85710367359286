import { keys as k } from "common/mod.ts";
import { Button } from "components/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "components/ui/card";
import { Input } from "components/ui/input";
import { Label } from "components/ui/label";
import { handleSocketTokens } from "handlers/auth";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { socket } from "socket";
import { toast } from "sonner";

export default function LoginForm() {
  const { t } = useTranslation();
  const [forgotPassword, setForgotPassword] = useState(false);
  const [forgotPasswordEmail, setForgotPasswordEmail] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const toLocation = location.state?.from?.pathname || "/";

  const handleLoginSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch("/api/auth/login", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email, password }),
      });

      if (response.status === 400)
        return toast.error(t(k.INVALID_EMAIL_OR_PASSWORD));
      if (response.status === 401) return toast.error(t(k.USER_NOT_ACTIVE));

      const { accessToken, refreshToken, user } = await response.json();

      handleSocketTokens({ socket, accessToken, refreshToken, user });
      toast.success(t(k.LOGGED_IN));
      navigate(toLocation);
    } catch (err) {
      toast.error(t(k.GENERAL_ERROR));
    }
  };

  const handleForgotPassword = async () => {
    try {
      await fetch("/api/auth/forgot-password", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email: forgotPasswordEmail }),
      });

      toast.success(t(k.RESET_PASSWORD_EMAIL_SENT));
      setForgotPasswordEmail("");
      setForgotPassword(false);
    } catch (err) {
      toast.error(t(k.GENERAL_ERROR));
    }
  };

  return (
    <div className="flex items-center justify-center h-screen">
      {!forgotPassword && (
        <form
          id="login-form"
          className="w-full max-w-sm"
          onSubmit={handleLoginSubmit}
        >
          <Card className="mx-auto max-w-sm">
            <CardHeader>
              <CardTitle className="text-2xl">{t(k.LOGIN)}</CardTitle>
              <CardDescription>{t(k.LOGIN_DESCRIPTION)}</CardDescription>
            </CardHeader>
            <CardContent>
              <div className="grid gap-4">
                <div className="grid gap-2">
                  <Label htmlFor="email">{t(k.EMAIL)}</Label>
                  <Input
                    id="email"
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder={t(k.EMAIL_PLACEHOLDER)}
                    required
                  />
                </div>
                <div className="grid gap-2">
                  <div className="flex items-center">
                    <Label htmlFor="password">{t(k.PASSWORD)}</Label>
                  </div>
                  <Input
                    id="password"
                    type="password"
                    onChange={(e) => setPassword(e.target.value)}
                    value={password}
                    required
                  />
                  <Button
                    type="button"
                    variant="link"
                    onClick={() => setForgotPassword(true)}
                    className="ml-auto inline-block text-sm"
                  >
                    {t(k.FORGOT_YOUR_PASSWORD)}
                  </Button>
                </div>
                <Button type="submit" className="w-full">
                  {t(k.LOGIN)}
                </Button>
              </div>
              <div className="mt-4 text-center text-sm">
                {t(k.LOGIN_NO_ACCOUNT)}{" "}
                <Link to="/register" className="underline">
                  {t(k.SIGN_UP)}
                </Link>
              </div>
            </CardContent>
          </Card>
        </form>
      )}
      {forgotPassword && (
        <Card className="mx-auto max-w-sm">
          <CardHeader>
            <CardTitle className="text-2xl">{t(k.FORGOT_PASSWORD)}</CardTitle>
            <CardDescription>
              {t(k.FORGOT_PASSWORD_DESCRIPTION)}
            </CardDescription>
          </CardHeader>
          <CardContent>
            <div className="grid gap-4">
              <div className="grid gap-2">
                <Label htmlFor="email">{t(k.EMAIL)}</Label>
                <Input
                  value={forgotPasswordEmail}
                  onChange={(e) => setForgotPasswordEmail(e.target.value)}
                  id="email"
                  type="email"
                  placeholder={t(k.EMAIL_PLACEHOLDER)}
                  required
                />
              </div>

              <Button
                type="submit"
                className="w-full"
                onClick={() => handleForgotPassword()}
              >
                {t(k.RESET_PASSWORD)}
              </Button>
            </div>
            <div className="mt-4 text-center text-sm">
              {t(k.LOGIN_NO_ACCOUNT)}{" "}
              <Link to="/register" className="underline">
                {t(k.SIGN_UP)}
              </Link>
              <br />
              <Button onClick={() => setForgotPassword(false)} variant="link">
                {t(k.BACK_TO_LOGIN)}
              </Button>
            </div>
          </CardContent>
        </Card>
      )}
    </div>
  );
}
