import { keys as k } from "common/mod.ts";
import { Button } from "components/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "components/ui/card";
import { Input } from "components/ui/input";
import { Label } from "components/ui/label";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "sonner";

export default function RegisterForm() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const handleSubmit = async (e) => {
    e.preventDefault();
    const form = new FormData(e.target);
    const formData = Object.fromEntries(form);
    if (formData.password !== formData.confirmPassword) {
      return toast.error(t(k.PASSWORDS_DO_NOT_MATCH));
    }
    try {
      const response = await fetch("/api/auth/register", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          firstName: formData.firstName,
          lastName: formData.lastName,
          telegram: formData.telegram,
          email: formData.email,
          password: formData.password,
        }),
      });
      if (response.status === 400) return toast.error(t(k.REGISTER_ERROR));

      const { userId } = await response.json();
      toast.success(t(k.REGISTER_SUCCESS));
      navigate(`/confirm/${userId}`);
    } catch (err) {
      toast.error(t(k.GENERAL_ERROR));
    }
  };

  return (
    <div className="flex items-center justify-center h-screen">
      <form id="register-form" onSubmit={handleSubmit}>
        <Card className="mx-auto max-w-sm">
          <CardHeader>
            <CardTitle className="text-xl">{t(k.SIGN_UP)}</CardTitle>
            <CardDescription>{t(k.SIGN_UP_DESCRIPTION)}</CardDescription>
          </CardHeader>
          <CardContent>
            <div className="grid gap-4">
              <div className="grid grid-cols-2 gap-4">
                <div className="grid gap-2">
                  <Label htmlFor="first-name">{t(k.FIRST_NAME)}</Label>
                  <Input
                    id="first-name"
                    name="firstName"
                    placeholder={t(k.FIRST_NAME_PLACEHOLDER)}
                    required
                  />
                </div>
                <div className="grid gap-2">
                  <Label htmlFor="last-name">{t(k.LAST_NAME)}</Label>
                  <Input
                    id="last-name"
                    name="lastName"
                    placeholder={t(k.LAST_NAME_PLACEHOLDER)}
                    required
                  />
                </div>
              </div>
              <div className="grid gap-2">
                <Label htmlFor="email">{t(k.EMAIL)}</Label>
                <Input
                  id="email"
                  name="email"
                  type="email"
                  placeholder={t(k.EMAIL_PLACEHOLDER)}
                  required
                />
              </div>
              <div className="grid gap-2">
                <Label htmlFor="telegram">{t(k.TELEGRAM_ID)}</Label>
                <Input
                  id="telegram"
                  name="telegram"
                  placeholder={t(k.TELEGRAM_PLACEHOLDER)}
                  required
                />
              </div>
              <div className="grid gap-2">
                <Label htmlFor="password">{t(k.PASSWORD)}</Label>
                <Input id="password" name="password" type="password" required />
              </div>
              <div className="grid gap-2">
                <Label htmlFor="confirm-password">
                  {t(k.CONFIRM_PASSWORD)}
                </Label>
                <Input
                  id="confirm-password"
                  name="confirmPassword"
                  type="password"
                  required
                />
              </div>
              <Button type="submit" className="w-full" form="register-form">
                {t(k.CREATE_AN_ACCOUNT)}
              </Button>
            </div>
            <div className="mt-4 text-center text-sm">
              {t(k.ALREADY_HAVE_AN_ACCOUNT)}{" "}
              <Link to="/login" className="underline">
                {t(k.SIGN_IN)}
              </Link>
            </div>
          </CardContent>
        </Card>
      </form>
    </div>
  );
}
