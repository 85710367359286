import { cn } from "lib/utils";
import { LoaderCircle } from "lucide-react";
import * as React from "react";

const spinnerVariants = "w-16 h-16 rounded-full animate-spin";

const Spinner = React.forwardRef((props, ref) => {
  const { className, ...rest } = props;
  return (
    <div className={className}>
      <LoaderCircle
        ref={ref}
        className={cn(spinnerVariants, className)}
        {...rest}
      />
    </div>
  );
});

Spinner.displayName = "Spinner";

export { Spinner };
