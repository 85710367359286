import { availableModemsCountHandler } from "handlers/pricing-plans";
import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { socket } from "socket";

const PurchaseContext = createContext();

export const PurchaseProvider = ({ children }) => {
  const [selectedCountry, setSelectedCountry] = useState();
  const [selectedCity, setSelectedCity] = useState();
  const [selectedOperator, setSelectedOperator] = useState();
  const [availableModemsCount, setAvailableModemsCount] = useState(0);
  const [plans, setPlans] = useState([]);
  const [selectedSubPlan, setSelectedSubPlan] = useState();
  const [selectedPlan, setSelectedPlan] = useState();
  const [selectedCountryCities, setSelectedCountryCities] = useState([]);
  const [selectedCountryOperators, setSelectedCountryOperators] = useState([]);
  const [countries, setCountries] = useState([]);
  const [cities, setCities] = useState([]);
  const [operators, setOperators] = useState([]);

  useEffect(() => {
    setSelectedCity();
    setSelectedOperator();
  }, [selectedCountry]);

  useEffect(() => {
    setSelectedOperator();
  }, [selectedCity]);

  useEffect(() => {
    setSelectedCountryCities([
      ...new Set(
        plans
          .filter((cp) => cp.country === selectedCountry)
          .map((cp) => cp.city),
      ),
    ]);
  }, [selectedCountry, plans]);

  useEffect(() => {
    if (!selectedCountry) return;
    if (!selectedCity) return;
    setSelectedCountryOperators(
      plans
        .filter((cp) => cp.country === selectedCountry)
        .filter((cp) => cp.city === selectedCity)
        .map((cp) => cp.operator),
    );
  }, [selectedCity, plans, selectedCountry]);

  useEffect(() => {
    setSelectedPlan(
      plans.find(
        (cp) =>
          cp.country === selectedCountry &&
          cp.city === selectedCity &&
          cp.operator === selectedOperator,
      ),
    );
  }, [selectedCountry, selectedCity, selectedOperator, plans]);

  useEffect(() => {
    setCountries([...new Set(plans.map((cp) => cp.country))]);
  }, [plans]);

  useEffect(() => {
    setCities([
      ...new Set(
        plans
          .filter((cp) => cp.country === selectedCountry)
          .map((cp) => cp.city),
      ),
    ]);
  }, [selectedCountry, plans]);

  useEffect(() => {
    setOperators([
      ...new Set(
        plans
          .filter((cp) => cp.country === selectedCountry)
          .filter((cp) => cp.city === selectedCity)
          .map((cp) => cp.operator),
      ),
    ]);
  }, [selectedCity, plans, selectedCountry]);

  useEffect(
    () => availableModemsCountHandler(socket, setAvailableModemsCount),
    [setAvailableModemsCount],
  );

  useEffect(() => {
    socket.emit("main:fetch:available-modems-count", {
      country: selectedCountry,
      city: selectedCity,
      operator: selectedOperator,
    });
  }, [selectedCountry, selectedCity, selectedOperator]);

  const contextValue = useMemo(
    () => ({
      plans,
      setPlans,
      selectedCountry,
      setSelectedCountry,
      selectedSubPlan,
      setSelectedPlan,
      selectedCity,
      setSelectedCity,
      selectedOperator,
      setSelectedOperator,
      setSelectedSubPlan,
      selectedPlan,
      selectedCountryCities,
      selectedCountryOperators,
      countries,
      cities,
      operators,
      availableModemsCount,
    }),
    [
      plans,
      setPlans,
      selectedCountry,
      setSelectedCountry,
      selectedSubPlan,
      setSelectedPlan,
      selectedCity,
      setSelectedCity,
      selectedOperator,
      setSelectedOperator,
      setSelectedSubPlan,
      selectedPlan,
      selectedCountryCities,
      selectedCountryOperators,
      countries,
      cities,
      operators,
      availableModemsCount,
    ],
  );

  return (
    <PurchaseContext.Provider value={contextValue}>
      {children}
    </PurchaseContext.Provider>
  );
};

export const usePurchase = () => {
  return useContext(PurchaseContext);
};
