import { keys as k } from "common/mod.ts";
import { pricingPlansHandler } from "handlers/pricing-plans";
import { cn, NAMESPACES } from "lib/utils";
import { CheckCircle2 } from "lucide-react";
import { usePurchase } from "providers/PurchaseProvider";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { socket } from "socket";

import { Button } from "./button";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "./card";
import { Label } from "./label";
import PurchaseDialog from "./purchase";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "./select";

const PricingHeader = ({ title, subtitle, availableModemsCount }) => {
  const { t } = useTranslation();
  return (
    <section className="text-center">
      <h2 className="text-3xl font-bold">{title}</h2>
      <p className="text-xl pt-1">{subtitle}</p>
      <p className="text-md mt-5 text-green">
        {t(k.AVAILABLE_MODEMS_COUNT)}: {availableModemsCount}
      </p>
      <br />
    </section>
  );
};

const PricingCard = ({ subPlan, setSelectedSubPlan, availableModemsCount }) => {
  const { t } = useTranslation();
  const { price, period, description, features, popular, exclusive } = subPlan;
  return (
    <Card
      className={cn(
        `w-72 flex flex-col justify-between py-1 ${popular ? "border-rose-400" : "border-zinc-700"} mx-auto sm:mx-0`,
        {
          "animate-background-shine bg-white dark:bg-[linear-gradient(110deg,#000103,45%,#1e2631,55%,#000103)] bg-[length:200%_100%] transition-colors":
            exclusive,
        }
      )}
    >
      <div>
        <CardHeader className="pb-8 pt-4">
          <CardTitle className="text-zinc-700 dark:text-zinc-300 text-lg text-center">
            {period} {t(k.DAYS)}
          </CardTitle>

          <div className="flex gap-0.5 text-center mx-auto">
            <h3 className="text-3xl font-bold">
              {price ? price + "$" : t(k.CUSTOM)}
            </h3>
            <span className="flex flex-col justify-end text-sm mb-1"></span>
          </div>
          {description && (
            <CardDescription className="pt-1.5 h-12">
              {description}
            </CardDescription>
          )}
        </CardHeader>
        <CardContent className="flex flex-col gap-2">
          {features.map((feature) => (
            <CheckItem key={feature} text={feature} />
          ))}
        </CardContent>
      </div>
      <CardFooter className="mt-2">
        <Button
          disabled={!availableModemsCount}
          onClick={() => setSelectedSubPlan(subPlan)}
          className="relative inline-flex w-full items-center justify-center rounded-md bg-black text-white dark:bg-white px-6 font-medium  dark:text-black transition-colors focus:outline-none focus:ring-2 focus:ring-slate-400 focus:ring-offset-2 focus:ring-offset-slate-50"
        >
          <div className="absolute -inset-0.5 -z-10 rounded-lg bg-gradient-to-b from-[#c7d2fe] to-[#8678f9] opacity-75 blur" />
          {t(k.PURCHASE)}
        </Button>
      </CardFooter>
    </Card>
  );
};

const CheckItem = ({ text }) => (
  <div className="flex gap-2">
    <CheckCircle2 size={18} className="my-auto text-green-400" />
    <p className="pt-0.5 text-zinc-700 dark:text-zinc-300 text-sm">{text}</p>
  </div>
);

export default function Pricing() {
  const { t } = useTranslation([
    NAMESPACES.GENERAL,
    NAMESPACES.COUNTRIES,
    NAMESPACES.CITIES,
  ]);
  const {
    setSelectedCountry,
    selectedCountry,
    setSelectedSubPlan,
    selectedPlan,
    setPlans,
    selectedCity,
    selectedOperator,
    setSelectedCity,
    setSelectedOperator,
    selectedSubPlan,
    countries,
    cities,
    operators,
    availableModemsCount,
  } = usePurchase();

  useEffect(() => {
    socket.emit("main:fetch:pricing-plans");
  }, []);

  useEffect(() => pricingPlansHandler(socket, setPlans), [setPlans]);

  return (
    <div className="py-8">
      <PricingHeader
        title={t(k.PRICING_PLANS)}
        subtitle={t(k.PRICING_PLANS_DESCRIPTION)}
        availableModemsCount={availableModemsCount}
      />
      <div className="flex flex-wrap justify-center gap-8 mt-8">
        <div className="grid gap-2 w-[150px]">
          <Label htmlFor="filter-country" className="text-center">
            {t(k.COUNTRY)}
          </Label>
          <Select
            id="filter-country"
            value={selectedCountry}
            onValueChange={setSelectedCountry}
          >
            <SelectTrigger>
              <SelectValue placeholder={t(k.SELECT_COUNTRY)}>
                {t(selectedCountry, { ns: "countries" }) || t(k.SELECT_COUNTRY)}
              </SelectValue>
            </SelectTrigger>
            <SelectContent>
              {countries.map((country) => (
                <SelectItem key={country} value={country}>
                  {t(country, { ns: "countries" })}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>

        <div className="grid gap-2 w-[150px]">
          <Label htmlFor="filter-city" className="text-center">
            {t(k.CITY)}
          </Label>
          <Select
            id="filter-city"
            value={selectedCity}
            onValueChange={setSelectedCity}
          >
            <SelectTrigger>
              <SelectValue placeholder={t(k.SELECT_CITY)}>
                {t(selectedCity, { ns: "cities" }) || t(k.SELECT_CITY)}
              </SelectValue>
            </SelectTrigger>
            <SelectContent>
              {cities.map((city) => (
                <SelectItem key={city} value={city}>
                  {t(city, { ns: "cities" })}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>
        <div className="grid gap-2 w-[150px]">
          <Label htmlFor="filter-operator" className="text-center">
            {t(k.OPERATOR)}
          </Label>
          <Select
            id="filter-operator"
            value={selectedOperator}
            onValueChange={setSelectedOperator}
          >
            <SelectTrigger>
              <SelectValue placeholder={t(k.SELECT_OPERATOR)}>
                {selectedOperator || t(k.SELECT_OPERATOR)}
              </SelectValue>
            </SelectTrigger>
            <SelectContent>
              {operators.map((operator) => (
                <SelectItem key={operator} value={operator}>
                  {operator}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>
      </div>

      {selectedPlan && (
        <section className="flex flex-col sm:flex-row sm:flex-wrap justify-center gap-8 mt-8">
          {selectedPlan?.subPlans.map((sp) => {
            return (
              <PricingCard
                key={sp._id}
                subPlan={sp}
                setSelectedSubPlan={setSelectedSubPlan}
                availableModemsCount={availableModemsCount}
              />
            );
          })}
        </section>
      )}
      {selectedSubPlan && <PurchaseDialog />}
    </div>
  );
}
