import { keys as k } from "common/mod.ts";
import { authFailed, handleAuth } from "handlers/auth";
import { handleUser } from "handlers/user";
import { t } from "i18next";
import React, { createContext, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { socket } from "socket";
import { toast } from "sonner";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [hasConnected, setHasConnected] = useState(socket.connected);

  const navigate = useNavigate();
  useEffect(() => {
    handleAuth(socket, navigate);
    handleUser(socket);
  }, [navigate]);

  useEffect(() => {
    function handleConnection(err) {
      if (socket.connected) setHasConnected(true);

      if (err) {
        if (err === "transport error" || err.message === "xhr poll error") {
          toast.error(t(k.CONNECTION_ERROR));
          if (!hasConnected) navigate("/login");
        } else {
          authFailed(err, navigate);
        }
      }
    }

    socket.on("connect", handleConnection);
    socket.on("disconnect", handleConnection);
    socket.on("connect_error", handleConnection);
    return () => {
      socket.off("connect", handleConnection);
      socket.off("disconnect", handleConnection);
      socket.off("connect_error", handleConnection);
    };
  }, [navigate, hasConnected]);

  return (
    <AuthContext.Provider value={{ hasConnected }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};
