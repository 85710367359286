import { keys as k } from "common/mod.ts";
import { Button } from "components/ui/button";
import { Input } from "components/ui/input";
import { Label } from "components/ui/label";
import { Popover, PopoverContent, PopoverTrigger } from "components/ui/popover";
import { handleCopy } from "lib/utils";
import { CopyIcon } from "lucide-react";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

export default function AnydeskPopover({ anydesk }) {
  const { t } = useTranslation();
  const { id, password } = anydesk;
  return (
    <Popover>
      <PopoverTrigger>
        <Button variant="outline">{t(k.SHOW)}</Button>
      </PopoverTrigger>
      <PopoverContent>
        <Label for="anydeskId">{t(k.ID)}:</Label>
        <div className="flex items-center gap-2">
          <Input id="anydeskId" value={id} readOnly />
          <Button variant="ghost" size="icon" onClick={() => handleCopy(id)}>
            <CopyIcon className="h-4 w-4" />
          </Button>
        </div>
        <Label for="anydeskPassword">{t(k.PASSWORD)}:</Label>
        <div className="flex items-center gap-2">
          <Input id="anydeskPassword" value={password} readOnly />
          <Button
            variant="ghost"
            size="icon"
            onClick={() => handleCopy(password)}
          >
            <CopyIcon className="h-4 w-4" />
          </Button>
        </div>
        <Button className="mt-4 w-full mx-auto">
          <Link target="_blank" replace={true} to={`anydesk:${id}`}>
            {t(k.CONNECT)}
          </Link>
        </Button>
      </PopoverContent>
    </Popover>
  );
}
