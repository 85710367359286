import { keys as k } from "common/mod.ts";
import Dashboard from "components/layout/admin/dashboard";
import { ContentLayout } from "components/layout/panel/content-layout";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from "components/ui/breadcrumb";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

export default function DashboardPage() {
  const { t } = useTranslation();
  return (
    <ContentLayout title={t(k.DASHBOARD)}>
      <Breadcrumb>
        <BreadcrumbList>
          <BreadcrumbItem>
            <BreadcrumbLink asChild>
              <Link href="/">{t(k.HOME)}</Link>
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbSeparator />
          <BreadcrumbItem>
            <BreadcrumbPage>{t(k.DASHBOARD)}</BreadcrumbPage>
          </BreadcrumbItem>
        </BreadcrumbList>
      </Breadcrumb>
      <Dashboard />
    </ContentLayout>
  );
}
