import { keys as k } from "common/mod.ts";
import { Badge } from "components/ui/badge";
import { Button } from "components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "components/ui/dialog";
import { Input } from "components/ui/input";
import { Popover, PopoverContent, PopoverTrigger } from "components/ui/popover";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "components/ui/select";
import { Separator } from "components/ui/separator";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "components/ui/table";
import { NAMESPACES, statusMap } from "lib/utils";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { socket } from "socket";
import { toast } from "sonner";

import AnydeskPopover from "./anydesk-popover";

export default function AdminServersTable({ servers }) {
  const { t } = useTranslation(Object.values(NAMESPACES));
  const [newRestriction, setNewRestriction] = useState("");
  const [selectedServer, setSelectedServer] = useState(null);
  const [copyFromServer, setCopyFromServer] = useState(null);
  const [modifiedRestrictions, setModifiedRestrictions] = useState([]);
  const [hasChanges, setHasChanges] = useState(false);

  useEffect(() => {
    if (selectedServer) {
      setModifiedRestrictions([...selectedServer.restrictions]);
    }
  }, [selectedServer]);

  useEffect(() => {
    if (selectedServer) {
      setHasChanges(
        JSON.stringify(selectedServer.restrictions) !==
          JSON.stringify(modifiedRestrictions)
      );
    }
  }, [selectedServer, modifiedRestrictions]);

  const addRestriction = () => {
    if (newRestriction && isValidRestriction(newRestriction)) {
      setModifiedRestrictions([
        ...new Set([...modifiedRestrictions, newRestriction]),
      ]);
      setNewRestriction("");
    } else {
      toast.error(t(k.INVALID_RESTRICTION));
    }
  };

  const removeRestriction = (restriction) => {
    setModifiedRestrictions(
      modifiedRestrictions.filter((r) => r !== restriction)
    );
  };

  const copyRestrictions = (fromServerId) => {
    const fromServer = servers.find((s) => s._id === fromServerId);
    if (!fromServer) return;

    const validRestrictions =
      fromServer.restrictions.filter(isValidRestriction);
    setModifiedRestrictions([
      ...new Set([...modifiedRestrictions, ...validRestrictions]),
    ]);
    if (validRestrictions.length !== fromServer.restrictions.length) {
      toast.error(t(k.INVALID_RESTRICTIONS_COPIED));
    }
  };

  const updateRestrictions = (serverId) => {
    if (!selectedServer) return;

    socket.emit("main:action:update-server", serverId, {
      restrictions: modifiedRestrictions,
    });

    setSelectedServer(null);
    setHasChanges(false);
    toast.success(t(k.ACTIONS_RESTRICTIONS_UPDATED));
  };

  const isValidRestriction = (restriction) => {
    const ipRangeRegex = /^(\d{1,3}\.){3}\d{1,3}(\/\d{1,2})?$/;
    const hostnameRegex =
      /^([a-zA-Z0-9]([a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?\.)+[a-zA-Z]{2,}$/;
    const wildcardHostnameRegex =
      /^(\*[a-zA-Z0-9\-.]+|\*?[a-zA-Z0-9]([a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(\.[a-zA-Z0-9]([a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*\*)$/;

    return restriction
      .split(",")
      .every(
        (item) =>
          ipRangeRegex.test(item.trim()) ||
          hostnameRegex.test(item.trim()) ||
          wildcardHostnameRegex.test(item.trim())
      );
  };

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl font-bold mb-4">
        {t(k.SERVER_RESTRICTIONS_MANAGEMENT)}
      </h1>
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead>{t(k.LOCATION)}</TableHead>
            <TableHead>{t(k.SERVER_NUMBER)}</TableHead>
            <TableHead>{t(k.PUBLIC_URL)}</TableHead>
            <TableHead>{t(k.PUBLIC_IP)}</TableHead>
            <TableHead>{t(k.INTERNAL_IP)}</TableHead>
            <TableHead className="text-center">{t(k.STATUS)}</TableHead>
            <TableHead className="text-center">{t(k.ANYDESK)}</TableHead>
            <TableHead>{t(k.RESTRICTIONS)}</TableHead>
            <TableHead>{t(k.ACTIONS)}</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {servers.map((server) => (
            <TableRow key={server._id}>
              <TableCell>
                {t(server.city, { ns: "cities" })},{" "}
                {t(server.country, { ns: "countries" })}
              </TableCell>
              <TableCell>{server.serverNumber}</TableCell>
              <TableCell>{server.publicURL}</TableCell>
              <TableCell>{server.publicIP}</TableCell>
              <TableCell>{server.internalIP}</TableCell>
              <TableCell className="text-center">
                <Badge
                  className={`text-xs ${statusMap[server.status].color} text-center`}
                  variant="outline"
                >
                  {t(statusMap[server.status].text.toUpperCase(), {
                    ns: "statuses",
                  })}
                </Badge>
              </TableCell>
              <TableCell>
                {server.anydesk && <AnydeskPopover anydesk={server.anydesk} />}
              </TableCell>
              <TableCell>
                <ul>
                  {server.restrictions.slice(0, 3).map((restriction, index) => (
                    <li key={index}>{restriction}</li>
                  ))}
                  {server.restrictions.length > 3 && (
                    <Popover>
                      <PopoverTrigger asChild>
                        <Button variant="link">
                          +{server.restrictions.length - 3} {t(k.MORE)}
                        </Button>
                      </PopoverTrigger>
                      <PopoverContent>
                        <ul>
                          {server.restrictions
                            .slice(3)
                            .map((restriction, index) => (
                              <li key={index}>{restriction}</li>
                            ))}
                        </ul>
                      </PopoverContent>
                    </Popover>
                  )}
                </ul>
              </TableCell>
              <TableCell>
                <Dialog open={selectedServer === server}>
                  <DialogTrigger asChild>
                    <Button
                      variant="outline"
                      onClick={() => setSelectedServer(server)}
                    >
                      {t(k.MODIFY)}
                    </Button>
                  </DialogTrigger>
                  <DialogContent
                    closeClick={() => setSelectedServer(null)}
                    onEscapeKeyDown={() => setSelectedServer(null)}
                    onPointerDownOutside={() => setSelectedServer(null)}
                    className="w-full min-w-[60vw] p-[20px]"
                  >
                    <DialogHeader>
                      <DialogTitle>
                        {t(k.IP_RESTRICTIONS_FOR)}{" "}
                        {t(server.city, { ns: "cities" })},{" "}
                        {t(server.country, { ns: "countries" })} #
                        {server.serverNumber}
                      </DialogTitle>
                    </DialogHeader>
                    <div className="flex space-x-2 justify-around">
                      <div className="flex flex-col space-y-1">
                        <h3 className="font-semibold mb-2 text-center">
                          {t(k.CURRENT_RESTRICTIONS)}
                        </h3>
                        {modifiedRestrictions.map((restriction, index) => (
                          <div
                            key={index}
                            className="flex justify-between items-center min-w-[250px]"
                          >
                            {restriction}
                            <Button
                              variant="destructive"
                              size="sm"
                              onClick={() => removeRestriction(restriction)}
                              className="ml-2"
                            >
                              {t(k.REMOVE)}
                            </Button>
                          </div>
                        ))}
                      </div>

                      <Separator orientation="vertical" />

                      <div className="flex flex-col space-x-2 space-y-5">
                        <h3 className="font-semibold mb-2 text-center">
                          {t(k.RESTRICTIONS_ACTIONS)}
                        </h3>
                        <p className="text-sm text-muted-foreground text-center">
                          {t(k.ADD_NEW_RESTRICTIONS)}
                        </p>
                        <div className="flex space-x-2">
                          <Input
                            placeholder={t(k.NEW_RESTRICTION)}
                            value={newRestriction}
                            onChange={(e) => setNewRestriction(e.target.value)}
                          />

                          <Button onClick={addRestriction}>{t(k.ADD)}</Button>
                        </div>

                        <p className="text-lg font-bold text-center">
                          {t(k.OR)}
                        </p>
                        <p className="text-sm text-muted-foreground text-center">
                          {t(k.COPY_RESTRICTIONS_FROM_ANOTHER)}
                        </p>

                        <div className="flex justify-equal">
                          <Select
                            onValueChange={(value) => setCopyFromServer(value)}
                            value={copyFromServer}
                          >
                            <SelectTrigger className="w-[200px]">
                              <SelectValue>
                                {!copyFromServer
                                  ? t(k.SELECT_SERVER)
                                  : `${t(servers.find((s) => s._id === copyFromServer).city, { ns: "cities" })}, ${t(servers.find((s) => s._id === copyFromServer).country, { ns: "countries" })} #${servers.find((s) => s._id === copyFromServer).serverNumber}`}
                              </SelectValue>
                            </SelectTrigger>
                            <SelectContent>
                              {servers
                                .filter((s) => s._id !== server._id)
                                .map((server) => (
                                  <SelectItem
                                    key={server._id}
                                    value={server._id}
                                  >
                                    {t(server.city, { ns: "cities" })},{" "}
                                    {t(server.country, { ns: "countries" })} #
                                    {server.serverNumber}
                                  </SelectItem>
                                ))}
                            </SelectContent>
                          </Select>
                          <Button
                            onClick={() => copyRestrictions(copyFromServer)}
                            disabled={!copyFromServer}
                            className="ml-2"
                          >
                            {t(k.COPY)}
                          </Button>
                        </div>
                      </div>
                    </div>
                    <Button
                      onClick={() => updateRestrictions(server._id)}
                      className="w-full"
                      disabled={!hasChanges}
                    >
                      {t(k.UPDATE_RESTRICTIONS)}
                    </Button>
                  </DialogContent>
                </Dialog>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
}
