import { keys as k } from "common/mod.ts";
import { Button } from "components/ui/button";
import { Input } from "components/ui/input";
import { Label } from "components/ui/label";
import { Popover, PopoverContent, PopoverTrigger } from "components/ui/popover";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "components/ui/select";
import { handleCopy } from "lib/utils";
import { CopyIcon } from "lucide-react";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { socket } from "socket";
import { toast } from "sonner";

export default function LoginInfo({
  modemId,
  username,
  password,
  httpPort,
  backConnectHttpPort,
  socks5Port,
  backConnectSocks5Port,
  url,
  changeIpUrl,
  direct,
  backconnectServers,
}) {
  const { t } = useTranslation();
  const backconnectUrls = backconnectServers.map((server) => server.url);
  const urlOptions = useMemo(() => {
    const options = [];
    if (!direct || backconnectUrls.length === 0) options.push(url);
    return [...options, ...backconnectUrls.filter((bUrl) => bUrl)];
  }, [direct, url, backconnectUrls]);

  const [newUsername, setNewUsername] = useState(username);
  const [newPassword, setNewPassword] = useState(password);
  const [isBackConnect, setIsBackConnect] = useState(false);
  const [selectedUrl, setSelectedUrl] = useState(urlOptions[0]);

  const [changesPending, setChangesPending] = useState(false);

  useEffect(() => {
    if (newUsername !== username || newPassword !== password) {
      setChangesPending(true);
    } else {
      setChangesPending(false);
    }
  }, [newUsername, newPassword, username, password]);

  const updateProxyLoginInfo = async () => {
    socket.emit(
      "main:action:update-modem-login",
      modemId,
      newUsername,
      newPassword
    );
    toast.success(t(k.PROXY_LOGIN_INFO_UPDATED));
  };

  useEffect(() => {
    if (backconnectUrls.includes(selectedUrl)) {
      setIsBackConnect(true);
    } else {
      setIsBackConnect(false);
    }
  }, [selectedUrl, backconnectUrls]);

  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button variant="outline">{t(k.SHOW)}</Button>
      </PopoverTrigger>
      <PopoverContent className="w-[500px] p-6 space-y-4">
        <div className="grid grid-cols-2 items-center gap-4">
          <Label htmlFor="username">{t(k.USERNAME)}</Label>
          <div className="flex items-center gap-2">
            <Input
              type="text"
              value={newUsername}
              disabled={!modemId}
              onChange={(e) => setNewUsername(e.target.value)}
            />
            <Button
              variant="ghost"
              size="icon"
              onClick={() => handleCopy(username)}
            >
              <CopyIcon className="h-4 w-4" />
              <span className="sr-only">{t(k.COPY_USERNAME)}</span>
            </Button>
          </div>
          <Label htmlFor="password">{t(k.PASSWORD)}</Label>
          <div className="flex items-center gap-2">
            <Input
              id="password"
              value={newPassword}
              disabled={!modemId}
              onChange={(e) => setNewPassword(e.target.value)}
            />
            <Button
              variant="ghost"
              size="icon"
              onClick={() => handleCopy(password)}
            >
              <CopyIcon className="h-4 w-4" />
              <span className="sr-only">{t(k.COPY_PASSWORD)}</span>
            </Button>
          </div>
          <Label htmlFor="url">{t(k.URL)}</Label>
          <div className="flex items-center gap-2">
            <Select
              id="url"
              value={selectedUrl}
              onValueChange={(val) => setSelectedUrl(val)}
            >
              <SelectTrigger>
                <SelectValue placeholder={t(k.SELECT_SERVER)} />
              </SelectTrigger>
              <SelectContent>
                {urlOptions.map((url) => (
                  <SelectItem key={url} value={url}>
                    {url}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
            <Button variant="ghost" size="icon" onClick={() => handleCopy(url)}>
              <CopyIcon className="h-4 w-4" />
              <span className="sr-only">{t(k.COPY_URL)}</span>
            </Button>
          </div>
          <Label htmlFor="httpPort">{t(k.HTTP_PORT)}</Label>
          <div className="flex items-center gap-2">
            <Input
              id="httpPort"
              value={isBackConnect ? backConnectHttpPort : httpPort}
              disabled
            />
            <Button
              variant="ghost"
              size="icon"
              onClick={() =>
                handleCopy(isBackConnect ? backConnectHttpPort : httpPort)
              }
            >
              <CopyIcon className="h-4 w-4" />
              <span className="sr-only">{t(k.COPY_HTTP_PORT)}</span>
            </Button>
          </div>
          <Label htmlFor="socks5Port">{t(k.SOCKS_PORT)}</Label>
          <div className="flex items-center gap-2">
            <Input
              id="socks5Port"
              value={isBackConnect ? backConnectSocks5Port : socks5Port}
              disabled
            />
            <Button
              variant="ghost"
              size="icon"
              onClick={() =>
                handleCopy(isBackConnect ? backConnectSocks5Port : socks5Port)
              }
            >
              <CopyIcon className="h-4 w-4" />
              <span className="sr-only">{t(k.COPY_SOCKS_PORT)}</span>
            </Button>
          </div>
        </div>
        <div className="flex justify-between">
          <Button
            variant="outline"
            onClick={() =>
              handleCopy(
                `${username}:${password}@${selectedUrl}:${
                  isBackConnect ? backConnectHttpPort : httpPort
                }`
              )
            }
          >
            {t(k.COPY_HTTP_LOGIN)}
            <CopyIcon className="h-4 w-4 ml-2" />
          </Button>
          <Button
            variant="outline"
            onClick={() =>
              handleCopy(
                `${username}:${password}@${selectedUrl}:${
                  isBackConnect ? backConnectSocks5Port : socks5Port
                }`
              )
            }
          >
            {t(k.COPY_SOCKS_LOGIN)}
            <CopyIcon className="h-4 w-4 ml-2" />
          </Button>
        </div>
        {changeIpUrl && (
          <div className="flex w-full items-center">
            <Button
              variant="outline"
              className="w-full"
              onClick={() => handleCopy(changeIpUrl)}
            >
              {t(k.COPY_CHANGE_IP_URL)}
              <CopyIcon className="h-4 w-4 ml-2" />
            </Button>
          </div>
        )}
        {modemId && changesPending && (
          <div className="flex justify-center">
            <Button onClick={() => updateProxyLoginInfo()}>
              {t(k.SAVE_CHANGES)}
            </Button>
          </div>
        )}
      </PopoverContent>
    </Popover>
  );
}
