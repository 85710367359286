import { keys as k } from "common/mod.ts";
import {
  Table,
  TableBody,
  TableHead,
  TableHeader,
  TableRow,
} from "components/ui/table";
import { pricingPlansHandler } from "handlers/pricing-plans";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { socket } from "socket";

import ProxyRow from "./row";

export default function ProxiesTable({ modems, backconnectServers }) {
  const { t } = useTranslation();

  const [pricingPlans, setPricingPlans] = useState([]);

  useEffect(() => {
    socket.emit("main:fetch:pricing-plans");
  }, []);

  useEffect(
    () => pricingPlansHandler(socket, setPricingPlans),
    [setPricingPlans]
  );

  return (
    <Table>
      <TableHeader>
        <TableRow>
          <TableHead>{t(k.LOCATION)}</TableHead>
          <TableHead className="hidden md:table-cell">
            {t(k.OPERATOR)}
          </TableHead>
          <TableHead className="text-center">{t(k.SIGNAL)}</TableHead>
          <TableHead>{t(k.IP)}</TableHead>
          <TableHead className="text-center">{t(k.STATUS)}</TableHead>

          <TableHead>{t(k.USER_LOGIN_INFO)}</TableHead>
          <TableHead className="w-[170px]">{t(k.RENEW)}</TableHead>
          <TableHead className="w-[170px] text-center">
            {t(k.VALID_UNTIL)}
          </TableHead>
          <TableHead className="hidden md:table-cell">{t(k.COMMENT)}</TableHead>
          <TableHead>
            <span className="sr-only">{t(k.ACTIONS)}</span>
          </TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {modems.map((modem) => (
          <ProxyRow
            key={modem._id}
            modem={modem}
            pricingPlans={pricingPlans}
            backconnectServers={backconnectServers}
          />
        ))}
      </TableBody>
    </Table>
  );
}
