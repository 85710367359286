import { keys as k } from "common/mod.ts";
import { refillRequestHandler } from "handlers/refill";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { socket } from "socket";

import { Button } from "./button";
import { Input } from "./input";
import { Label } from "./label";
import { Separator } from "./separator";

export default function Refill() {
  const { t } = useTranslation();
  const user = JSON.parse(localStorage.getItem("user"));
  const balance = user.balance;
  const [topUpAmount, setTopUpAmount] = useState("0");
  const [total, setTotal] = useState(balance + parseFloat(topUpAmount));

  useEffect(() => {
    const amount = topUpAmount === "" ? 0 : parseFloat(topUpAmount);
    setTotal(balance + amount);
  }, [topUpAmount, balance]);

  const handleRefill = async () => {
    socket.emit("main:action:refill-request", {
      amount: parseFloat(topUpAmount),
      currentUrl: window.location.href,
    });
  };

  useEffect(() => {
    refillRequestHandler(socket);
  }, []);

  return (
    <div className="max-w-2xl mx-auto py-12">
      <div className="grid gap-6">
        <div className="grid gap-2">
          <Label htmlFor="balance">{t(k.CURRENT_BALANCE)}</Label>
          <Input
            id="balance"
            type="number"
            value={balance}
            disabled
            className="flex-1"
          />
        </div>
        <div className="grid gap-2">
          <Label htmlFor="topup">{t(k.REFILL_AMOUNT)}</Label>
          <Input
            id="topup"
            type="number"
            onFocus={() =>
              setTopUpAmount(topUpAmount === "0" ? "" : topUpAmount)
            }
            value={topUpAmount}
            onChange={(e) => setTopUpAmount(e.target.value)}
            min={0}
            className="flex-1"
          />
        </div>
        <div className="grid gap-2">
          <Label htmlFor="total">{t(k.NEW_BALANCE)}</Label>
          <Input
            id="total"
            type="number"
            value={total}
            disabled
            className="flex-1"
          />
        </div>
        <Separator />
        <Button type="submit" className="w-full" onClick={handleRefill}>
          {t(k.REFILL_BALANCE)} {topUpAmount ? `($${topUpAmount})` : ""}
        </Button>
      </div>
    </div>
  );
}
