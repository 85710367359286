import { CaretSortIcon, CheckIcon } from "@radix-ui/react-icons";
import { keys as k } from "common/mod.ts";
import { Badge } from "components/ui/badge";
import { Button } from "components/ui/button";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "components/ui/command";
import { Popover, PopoverContent, PopoverTrigger } from "components/ui/popover";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "components/ui/table";
import { format } from "date-fns";
import { cn, couponStatusMap, couponTypes, NAMESPACES } from "lib/utils";
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

export default function CouponTable({
  coupons,
  setSelectedCoupon,
  setCouponFormOpen,
}) {
  const { t } = useTranslation(Object.values(NAMESPACES));

  const [couponCodeFilter, setCouponCodeFilter] = useState("");
  const [couponOpen, setCouponOpen] = useState(false);

  const filteredItems = useMemo(() => {
    return coupons.filter((item) => {
      if (!couponCodeFilter) return true;
      return item.code === couponCodeFilter;
    });
  }, [coupons, couponCodeFilter]);

  return (
    <>
      <div className="flex items-center justify-between mb-4">
        <div className="flex items-center gap-4">
          <Popover open={couponOpen} onOpenChange={setCouponOpen}>
            <PopoverTrigger asChild>
              <Button
                variant="outline"
                role="combobox"
                aria-expanded={couponOpen}
                className="w-[200px] justify-between"
              >
                {couponCodeFilter || t(k.SELECT_COUPON)}
                <CaretSortIcon className="ml-2 h-4 w-4 shrink-0 opacity-50" />
              </Button>
            </PopoverTrigger>
            <PopoverContent className="w-[200px] p-0">
              <Command>
                <CommandInput
                  placeholder={t(k.SELECT_COUPON)}
                  className="h-9"
                />
                <CommandEmpty>{t(k.NO_COUPON_FOUND)}</CommandEmpty>
                <CommandGroup>
                  <CommandList>
                    {coupons.map((coupon) => (
                      <CommandItem
                        key={coupon.code}
                        value={coupon.code}
                        onSelect={(code) => {
                          setCouponCodeFilter(
                            code === couponCodeFilter ? "" : code
                          );
                        }}
                      >
                        {coupon.code}
                        <CheckIcon
                          className={cn(
                            "ml-auto h-4 w-4",
                            coupon.code === couponCodeFilter
                              ? "opacity-100"
                              : "opacity-0"
                          )}
                        />
                      </CommandItem>
                    ))}
                  </CommandList>
                </CommandGroup>
              </Command>
            </PopoverContent>
          </Popover>
        </div>
        <div className="flex items-right gap-4">
          <Button onClick={() => setCouponFormOpen(true)} className="ml-auto">
            + {t(k.ADD_COUPON)}
          </Button>
        </div>
      </div>
      {filteredItems.length ? (
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead>{t(k.COUPON_CODE)}</TableHead>
              <TableHead>{t(k.COUPON_DISCOUNT)}</TableHead>
              <TableHead>{t(k.COUPON_TYPE)}</TableHead>
              <TableHead>{t(k.COUPON_USES)}</TableHead>
              <TableHead>{t(k.STATUS)}</TableHead>
              <TableHead>{t(k.COUPON_AFFECTED_PRODUCTS)}</TableHead>
              <TableHead>{t(k.COUPON_EXPIRY)}</TableHead>
              <TableHead>{t(k.ACTIONS)}</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {filteredItems.map((coupon) => (
              <TableRow key={coupon._id}>
                <TableCell className="w-1/12">{coupon.code}</TableCell>
                <TableCell className="w-1/12">{coupon.discount}</TableCell>
                <TableCell className="w-1/12">
                  {t(k[couponTypes[coupon.type]])}
                </TableCell>
                <TableCell className="w-1/12">
                  {coupon.used} {coupon.limit ? ` / ${coupon.limit}` : null}
                </TableCell>
                <TableCell className="w-1/12">
                  <Badge
                    variant="outline"
                    className={`${couponStatusMap[coupon.status].color}`}
                  >
                    {t(couponStatusMap[coupon.status].text?.toUpperCase(), {
                      ns: "statuses",
                    })}
                  </Badge>
                </TableCell>
                <TableCell className="w-1/12">
                  <strong>
                    {`${
                      coupon.selectedCountries.length
                        ? coupon.selectedCountries
                            .map((country) => t(country, { ns: "countries" }))
                            .join(" | ")
                        : t(k.ALL_COUNTRIES)
                    }`}
                    <br></br>
                    {coupon.selectedCities.length
                      ? `${coupon.selectedCities.map((city) => t(city, { ns: "cities" })).join(" | ")}`
                      : t(k.ALL_CITIES)}
                    <br></br>
                    {coupon.selectedOperators.length
                      ? `${coupon.selectedOperators.join(" | ")}`
                      : t(k.ALL_OPERATORS)}
                  </strong>
                </TableCell>
                <TableCell className="w-1/12">
                  {format(new Date(coupon.expiration), "dd/MM/yyyy")}
                </TableCell>
                <TableCell className="w-1/12">
                  <Button onClick={() => setSelectedCoupon(coupon)}>
                    {t(k.EDIT)}
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      ) : (
        <div className="flex justify-center items-center h-32">
          {t(k.NO_COUPONS_AVAILABLE)}
        </div>
      )}
    </>
  );
}
