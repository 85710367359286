export const handlePaymentHistory = (socket, setItems, setSpinner) => {
  function handler(payload) {
    setItems(() => payload);
    setSpinner(false);
  }

  socket.on("web:data:payment-history", handler);

  return () => {
    socket.off("web:data:payment-history", handler);
  };
};

export const updatePaymentHistoryItem = (socket, setItems) => {
  function handler(updatedItem) {
    setItems((prevItems) => {
      const currentItems = [...prevItems];
      const index = currentItems.findIndex(
        (item) => item._id === updatedItem._id,
      );
      if (index === -1) return [...prevItems, updatedItem];
      else
        return prevItems.map((item) =>
          item._id === updatedItem._id ? updatedItem : item,
        );
    });
  }
  socket.on("web:action:update-payment-history", handler);
  return () => {
    socket.off("web:action:update-payment-history", handler);
  };
};
