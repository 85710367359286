export const couponHandler = (socket, setCoupons, setSpinner) => {
  function handler(payload) {
    setCoupons(payload);
    setSpinner(false);
  }
  socket.on("web:data:coupons", handler);
  return () => {
    socket.off("web:data:coupons", handler);
  };
};

export const updateCouponHandler = (socket, setCoupons) => {
  function handler(updatedCoupon) {
    setCoupons((prevCoupons) => {
      const currentCoupons = [...prevCoupons];
      const index = currentCoupons.findIndex(
        (coupon) => coupon._id === updatedCoupon._id
      );
      if (index === -1) return [...prevCoupons, updatedCoupon];
      else
        return prevCoupons.map((coupon) =>
          coupon._id === updatedCoupon._id ? updatedCoupon : coupon
        );
    });
  }
  socket.on("web:action:update-coupon", handler);
  return () => {
    socket.off("web:action:update-coupon", handler);
  };
};

export const couponSelectionHandler = (socket, setSelectionItems) => {
  function handler(payload) {
    setSelectionItems(payload);
  }

  socket.on("web:data:coupon-selection", handler);
  return () => {
    socket.off("web:data:coupon-selection", handler);
  };
};
