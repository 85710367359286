import { keys as k } from "common/mod.ts";
import { Button } from "components/ui/button";
import { Card, CardContent, CardHeader } from "components/ui/card";
import { Dialog, DialogContent, DialogTitle } from "components/ui/dialog";
import { Input } from "components/ui/input";
import { Label } from "components/ui/label";
// import { Separator } from "components/ui/separator";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "components/ui/select";
import { Textarea } from "components/ui/textarea";
import { NAMESPACES, planPeriods } from "lib/utils";
import { XIcon } from "lucide-react";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { socket } from "socket";

const clearPlan = {
  country: "",
  city: "",
  operator: "",
  subPlans: [],
};

export default function PlanDialog({ plan, setPlan, locations }) {
  const { t } = useTranslation([
    NAMESPACES.GENERAL,
    NAMESPACES.COUNTRIES,
    NAMESPACES.CITIES,
  ]);
  const isUpdate = useCallback(() => {
    if (plan._id === null || plan._id === undefined) return false;
    return true;
  }, [plan]);

  const [currentCountry, setCurrentCountry] = useState(
    isUpdate() ? plan.country : null
  );
  const [currentCity, setCurrentCity] = useState(isUpdate() ? plan.city : null);
  const [currentOperator, setCurrentOperator] = useState(
    isUpdate() ? plan.operator : null
  );

  const countries = useMemo(() => {
    return Object.keys(locations);
  }, [locations]);

  const cities = useMemo(() => {
    return locations[currentCountry]
      ? Object.keys(locations[currentCountry])
      : [];
  }, [locations, currentCountry]);

  const operators = useMemo(() => {
    return locations[currentCountry] && locations[currentCountry][currentCity]
      ? locations[currentCountry][currentCity]
      : [];
  }, [locations, currentCountry, currentCity]);

  useEffect(() => {
    const newPlan = plan || clearPlan;
    if (currentCountry !== "all") newPlan.country = currentCountry;
    if (currentCity !== "all") newPlan.city = currentCity;
    if (currentOperator !== "all") newPlan.operator = currentOperator;
    setPlan(newPlan);
  }, [currentCountry, currentCity, currentOperator, plan, setPlan]);

  const handleAddPlan = () => {
    setPlan({
      ...plan,
      subPlans: [
        ...plan.subPlans,
        {
          period: "",
          price: 0,
          description: "",
          features: [],
        },
      ],
    });
  };

  const handleRemovePlan = (index) => {
    if (plan.subPlans.length === 1) {
      setPlan({
        ...plan,
        subPlans: clearPlan.subPlans,
      });
    } else {
      setPlan({
        ...plan,
        subPlans: plan.subPlans.filter((_, i) => i !== index),
      });
    }
  };

  const handlePlanChange = (index, field, value) => {
    setPlan({
      ...plan,
      subPlans: plan.subPlans.map((subPlan, i) =>
        i === index ? { ...subPlan, [field]: value } : subPlan
      ),
    });
  };

  const updateFeatures = (index, featureIndex, value) => {
    setPlan({
      ...plan,
      subPlans: plan.subPlans.map((subPlan, i) =>
        i === index
          ? {
              ...subPlan,
              features: subPlan.features.map((feature, j) =>
                j === featureIndex ? value : feature
              ),
            }
          : subPlan
      ),
    });
  };

  const addFeature = (index) => {
    setPlan({
      ...plan,
      subPlans: plan.subPlans.map((subPlan, i) => {
        if (i === index) {
          return {
            ...subPlan,
            features: [...subPlan.features, ""],
          };
        }
        return subPlan;
      }),
    });
  };

  const handlePlanSubmit = () => {
    socket.emit("main:action:create-pricing-plan", plan);
  };

  const handlePlanUpdate = () => {
    socket.emit("main:action:update-pricing-plan", plan);
  };

  return (
    <Dialog open={plan} asChild modal={true}>
      <DialogContent
        className="max-w-screen overflow-y-scroll max-h-screen"
        closeClick={() => setPlan(null)}
        onEscapeKeyDown={() => setPlan(null)}
        onPointerDownOutside={() => setPlan(null)}
      >
        <DialogTitle className="text-center">
          {isUpdate() ? t(k.EDIT_PLAN) : t(k.ADD_PLAN)}
        </DialogTitle>
        <div className="flex items-center justify-center mb-8 flex-wrap">
          <div className="flex flex-col md:flex-row gap-4">
            <Card className="w-[300px]">
              <CardHeader>
                <div className="flex items-center justify-between">
                  {!isUpdate() && (
                    <Button
                      variant="outline"
                      size="sm"
                      onClick={() => setPlan(clearPlan)}
                    >
                      {t(k.CLEAR)}
                    </Button>
                  )}
                </div>
              </CardHeader>
              <CardContent className="flex flex-col gap-4">
                <div className="grid gap-2">
                  <Label htmlFor={`new-country`}>{t(k.COUNTRY)}</Label>
                  <Select
                    id={`new-country`}
                    value={currentCountry}
                    onValueChange={(val) => setCurrentCountry(val)}
                  >
                    <SelectTrigger>
                      <SelectValue placeholder={t(k.SELECT_COUNTRY)} />
                    </SelectTrigger>
                    <SelectContent>
                      {countries.map((country) => (
                        <SelectItem key={country} value={country}>
                          {t(country, { ns: "countries" })}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                </div>
                <div className="grid gap-2">
                  <Label htmlFor={`new-city`}>{t(k.CITY)}</Label>
                  <Select
                    id={`new-city`}
                    value={currentCity}
                    onValueChange={(val) => setCurrentCity(val)}
                  >
                    <SelectTrigger>
                      <SelectValue placeholder={t(k.SELECT_CITY)} />
                    </SelectTrigger>
                    <SelectContent>
                      {cities.map((city) => (
                        <SelectItem key={city} value={city}>
                          {t(city, { ns: "cities" })}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                </div>
                <div className="grid gap-2">
                  <Label htmlFor={`new-operator`}>{t(k.OPERATOR)}</Label>
                  <Select
                    id={`new-operator`}
                    value={currentOperator}
                    onValueChange={(val) => setCurrentOperator(val)}
                  >
                    <SelectTrigger>
                      <SelectValue placeholder={t(k.SELECT_OPERATOR)} />
                    </SelectTrigger>
                    <SelectContent>
                      {operators.map((operator) => (
                        <SelectItem key={operator} value={operator}>
                          {operator}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                </div>
                <div className="grid gap-4">
                  <Button
                    variant="outline"
                    onClick={handleAddPlan}
                    className="col-span-3"
                  >
                    {t(k.ADD_SUB_PLAN)}
                  </Button>
                </div>
                <div className="grid gap-4">
                  <Button
                    className="col-span-3"
                    onClick={() =>
                      isUpdate() ? handlePlanUpdate() : handlePlanSubmit()
                    }
                  >
                    {isUpdate() ? t(k.UPDATE) : t(k.SUBMIT_PLAN)}
                  </Button>
                </div>
              </CardContent>
            </Card>

            {plan.subPlans.map((planDetails, i) => (
              <>
                <Card className="w-[300px]">
                  <CardHeader>
                    <div className="flex items-center justify-between">
                      <Label className="font-bold">
                        {t(k.SUB_PLAN)} {i}
                      </Label>
                      <Button
                        variant="outline"
                        size="sm"
                        onClick={() => handleRemovePlan(i)}
                      >
                        <XIcon className="w-4 h-4" />
                      </Button>
                    </div>
                  </CardHeader>
                  <CardContent>
                    <div key={i} className="grid gap-4">
                      <div className="grid gap-2">
                        <Label htmlFor={`period-${i}`}>{t(k.PERIOD)}</Label>
                        <Select
                          id={`period-i`}
                          value={planDetails.period}
                          onValueChange={(val) =>
                            handlePlanChange(i, "period", val)
                          }
                        >
                          <SelectTrigger>
                            <SelectValue placeholder={t(k.SELECT_PERIOD)} />
                          </SelectTrigger>
                          <SelectContent>
                            {planPeriods.map((period) => (
                              <SelectItem key={period} value={period}>
                                {period} {t(k.DAYS)}
                              </SelectItem>
                            ))}
                          </SelectContent>
                        </Select>
                      </div>
                      <div className="grid gap-2">
                        <Label htmlFor={`price-${i}`}>{t(k.PRICE)}</Label>
                        <Input
                          id={`price-${i}`}
                          type="number"
                          value={planDetails.price}
                          onChange={(e) =>
                            handlePlanChange(
                              i,
                              "price",
                              parseFloat(e.target.value)
                            )
                          }
                        />
                      </div>
                      <div className="grid gap-2">
                        <Label htmlFor={`description-${i}`}>
                          {t(k.DESCRIPTION)}
                        </Label>
                        <Textarea
                          id={`description-${i}`}
                          value={planDetails.description}
                          onChange={(e) =>
                            handlePlanChange(i, "description", e.target.value)
                          }
                        />
                      </div>
                      <div className="grid gap-2">
                        <Label htmlFor={`features-${i}`}>
                          {t(k.FEATURES1)}
                        </Label>
                        {planDetails.features.map((feature, j) => (
                          <Input
                            key={j}
                            value={feature}
                            onChange={(e) =>
                              updateFeatures(i, j, e.target.value)
                            }
                          />
                        ))}
                        <Button
                          variant="outline"
                          size="sm"
                          onClick={() => addFeature(i)}
                        >
                          {t(k.ADD_FEATURE)}
                        </Button>
                      </div>
                    </div>
                  </CardContent>
                </Card>
              </>
            ))}
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}
