import { keys as k } from "common/mod.ts";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

export default function ErrorPage() {
  const { t } = useTranslation();
  return (
    <div className="flex min-h-[100dvh] flex-col items-center justify-center bg-background px-4 py-12 sm:px-6 lg:px-8">
      <div className="mx-auto max-w-md text-center">
        <h1 className="mt-4 text-3xl font-bold tracking-tight text-foreground sm:text-4xl">
          {t(k.ERROR_PAGE_TITLE)}
        </h1>
        <p className="mt-4 text-muted-foreground">
          {t(k.ERROR_PAGE_DESCRIPTION)}
        </p>
        <div className="mt-6">
          <Link
            reloadDocument={true}
            to="/proxies"
            className="inline-flex items-center rounded-md bg-primary px-4 py-2 text-sm font-medium text-primary-foreground shadow-sm transition-colors hover:bg-primary/90 focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2"
            prefetch={false}
          >
            {t(k.GO_TO_HOMEPAGE)}
          </Link>
        </div>
      </div>
    </div>
  );
}
