import { keys as k } from "common/mod.ts";
import { ModeToggle } from "components/mode-toggle";
import { Label } from "components/ui/label";
import LanguageSwitcher from "components/ui/language-switcher";
import React from "react";
import { useTranslation } from "react-i18next";

import NotificationsComponent from "./notifications";
import { SheetMenu } from "./sheet-menu";
import { UserNav } from "./user-nav";

export function Navbar({ title }) {
  const { t } = useTranslation();
  const user = JSON.parse(localStorage.getItem("user"));
  const balance = user.balance;
  return (
    <header className="sticky top-0 z-10 w-full bg-background/95 shadow backdrop-blur supports-[backdrop-filter]:bg-background/60 dark:shadow-secondary">
      <div className="mx-4 sm:mx-8 flex h-14 items-center">
        <div className="flex items-center space-x-4 lg:space-x-0">
          <SheetMenu />
          <h1 className="font-bold text-primary text-xs md:text-lg">{title}</h1>
        </div>
        <div className="flex flex-1 items-center space-x-2 justify-end mr-4">
          <Label className="hidden md:block">
            {t(k.BALANCE)}: {balance}$
          </Label>
          <NotificationsComponent />
          <UserNav />
          <ModeToggle />
          <LanguageSwitcher />
        </div>
      </div>
    </header>
  );
}
