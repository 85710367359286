import { CheckIcon, ChevronDownIcon, GlobeIcon } from "lucide-react";
import React from "react";
import { useTranslation } from "react-i18next";

import { Button } from "./button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "./dropdown-menu";

export default function LanguageSwitcher({ ...props }) {
  const { i18n } = useTranslation();
  const languages = {
    en: "English",
    ru: "Русский",
  };

  return (
    <div {...props}>
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button
            variant="outline"
            className="rounded-full w-8 md:w-20 min-w-8 h-8 p-0 m-0"
          >
            <GlobeIcon className="h-5 w-5" />
            <span className="hidden md:block ml-2">
              {languages[i18n.language].slice(0, 2).toUpperCase()}
            </span>
            <ChevronDownIcon className="hidden md:block h-4 w-4" />
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent align="end" className="w-48">
          {Object.entries(languages).map(([lng, label]) => (
            <DropdownMenuItem
              key={lng}
              onClick={() => i18n.changeLanguage(lng)}
            >
              <span>{label}</span>{" "}
              {i18n.language === lng && <CheckIcon className="h-4 w-4" />}
            </DropdownMenuItem>
          ))}
        </DropdownMenuContent>
      </DropdownMenu>
    </div>
  );
}
