import { Card, CardContent, CardHeader, CardTitle } from "components/ui/card";
import { ChartContainer } from "components/ui/chart";
import { format } from "date-fns";
import React from "react";
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

export default function ModemChart({ data, config, title, granularity }) {
  const tickFormatter = (item) => {
    switch (granularity) {
      case "minutely":
        return format(new Date(item), "hh:mm a");
      case "hourly":
        return format(new Date(item), "hh:mm a");
      case "daily":
        return format(new Date(item), "dd/MM");
      case "monthly":
        return format(new Date(item), "MM/yyyy");
      case "yearly":
        return format(new Date(item), "yyyy");
      default:
        return item;
    }
  };

  return (
    <Card className="w-full max-w-4xl">
      <CardHeader>
        <CardTitle>{title}</CardTitle>
      </CardHeader>
      <CardContent>
        <ChartContainer config={config}>
          <ResponsiveContainer width="100%" height="100%">
            <LineChart
              data={data}
              margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis
                dataKey="date"
                tickFormatter={(item) => tickFormatter(item)}
              />
              <YAxis />
              <Tooltip
                labelFormatter={(item) => tickFormatter(item)}
                formatter={(value, name) => [value, name]}
              />

              {Object.entries(config).map(([key, c]) => (
                <Line
                  key={key}
                  type="monotone"
                  dataKey={key}
                  stroke={c.color}
                  fill={c.color}
                  name={c.label}
                />
              ))}

              <Legend />
            </LineChart>
          </ResponsiveContainer>
        </ChartContainer>
      </CardContent>
    </Card>
  );
}
