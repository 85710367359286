import { keys as k } from "common/mod.ts";
import { Button } from "components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "components/ui/dialog";
import React from "react";
import { useTranslation } from "react-i18next";

import { deleteModem } from "./actions-handler";

export default function DeleteModal({ modemId, setDeleteOpen }) {
  const { t } = useTranslation();
  return (
    <Dialog defaultOpen>
      <DialogTrigger></DialogTrigger>
      <DialogContent
        closeClick={() => setDeleteOpen(false)}
        onEscapeKeyDown={() => setDeleteOpen(false)}
        onPointerDownOutside={() => setDeleteOpen(false)}
      >
        <DialogHeader>
          <DialogTitle>{t(k.DELETE_MODEM)}</DialogTitle>
        </DialogHeader>
        <div className="flex flex-col gap-4">
          <div>
            <p>{t(k.DELETE_MODEM_WARNING)}</p>
          </div>
          <DialogFooter>
            <div className="flex gap-4">
              <Button onClick={() => setDeleteOpen(false)} variant="outline">
                {t(k.CANCEL)}
              </Button>
              <Button
                variant="destructive"
                onClick={() => deleteModem(modemId, setDeleteOpen)}
              >
                {t(k.DELETE)}
              </Button>
            </div>
          </DialogFooter>
        </div>
      </DialogContent>
    </Dialog>
  );
}
