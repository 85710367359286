import { keys as k } from "common/mod.ts";
import CouponTable from "components/layout/admin/coupons";
import CouponForm from "components/layout/admin/coupons/form";
import { ContentLayout } from "components/layout/panel/content-layout";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from "components/ui/breadcrumb";
import { Card, CardContent, CardFooter, CardHeader } from "components/ui/card";
import { Spinner } from "components/ui/spinner";
import { couponHandler, updateCouponHandler } from "handlers/coupons";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { socket } from "socket";

export default function Coupons() {
  const { t } = useTranslation();
  const [coupons, setCoupons] = useState([]);
  const [selectedCoupon, setSelectedCoupon] = useState(null);
  const [couponFormOpen, setCouponFormOpen] = useState(false);
  const [spinner, setSpinner] = useState(true);

  useEffect(() => {
    socket.emit("main:fetch:coupons");
  }, []);
  useEffect(() => couponHandler(socket, setCoupons, setSpinner), []);
  useEffect(() => updateCouponHandler(socket, setCoupons), []);

  useEffect(() => {
    setCouponFormOpen(!!selectedCoupon);
  }, [selectedCoupon]);

  useEffect(() => {
    if (!couponFormOpen) setSelectedCoupon(null);
  }, [couponFormOpen]);

  return (
    <ContentLayout title={t(k.COUPONS)}>
      <Card className="rounded-lg border-none mt-6">
        <CardHeader>
          <Breadcrumb>
            <BreadcrumbList>
              <BreadcrumbItem>
                <BreadcrumbPage>{t(k.ADMIN_PANEL)}</BreadcrumbPage>
              </BreadcrumbItem>
              <BreadcrumbSeparator />
              <BreadcrumbItem>
                <BreadcrumbPage>{t(k.COUPONS)}</BreadcrumbPage>
              </BreadcrumbItem>
            </BreadcrumbList>
          </Breadcrumb>
        </CardHeader>
        <CardContent>
          {spinner && <Spinner className="text-primary flex justify-center" />}
          <CouponTable
            coupons={coupons}
            setCouponFormOpen={setCouponFormOpen}
            setSelectedCoupon={setSelectedCoupon}
          />

          {couponFormOpen && (
            <CouponForm
              selectedCoupon={selectedCoupon}
              setCouponFormOpen={setCouponFormOpen}
            />
          )}
        </CardContent>
        <CardFooter></CardFooter>
      </Card>
    </ContentLayout>
  );
}
