import { keys as k } from "common/mod.ts";
import { Button } from "components/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "components/ui/card";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "sonner";

export default function ConfirmUser() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const params = useParams();
  const [tokenInvalid, setTokenInvalid] = useState(false);
  const [tokenExpired, setTokenExpired] = useState(false);

  const verifyToken = useCallback(
    async (userId, token) => {
      try {
        const response = await fetch(`/api/auth/confirm/${userId}/${token}`);
        if (response.status === 401) return setTokenExpired(true);
        if (response.status !== 200) throw new Error("Invalid token");
        toast.success(t(k.CONFIRM_USER_SUCCESS));
        navigate("/login");
      } catch (err) {
        toast.error(t(k.GENERAL_ERROR));
        setTokenInvalid(true);
      }
    },
    [navigate, t]
  );

  useEffect(() => {
    const { userId, token } = params;
    if (!userId) {
      toast.error(t(k.CONFIRM_USER_NO_USER_ID));
      return navigate("/");
    }
    if (token) verifyToken(userId, token);
  }, [params, navigate, verifyToken, t]);

  const handleRegenerateToken = async (userId) => {
    try {
      const response = await fetch(`/api/auth/regenerate-token/${userId}`);
      if (!response.status === 200) throw new Error("Error regenerating token");
      toast.success(t(k.CONFIRM_USER_TOKEN_REGENERATED));
    } catch (err) {
      toast.error(err.message);
    }
  };

  return (
    <div className="flex items-center justify-center h-screen">
      <Card className="mx-auto max-w-sm">
        <CardHeader>
          <CardTitle className="text-xl">{t(k.CONFIRM_EMAIL)}</CardTitle>
          <CardDescription>{t(k.CONFIRM_EMAIL_DESCRIPTION)}</CardDescription>
        </CardHeader>
        <CardContent>
          {!tokenInvalid && !tokenExpired && <p>{t(k.TOKEN_UNUSED)}</p>}
          {tokenInvalid && (
            <>
              <p>{t(k.TOKEN_INVALID_INFO)}</p>
              <Button
                className="mt-6"
                onClick={() => handleRegenerateToken(params.userId)}
              >
                {t(k.REGENERATE_TOKEN)}
              </Button>
            </>
          )}
          {tokenExpired && (
            <>
              <p>{t(k.TOKEN_EXPIRED_INFO)}</p>
              <Button
                className="mt-6"
                onClick={() => handleRegenerateToken(params.userId)}
              >
                {t(k.REGENERATE_TOKEN)}
              </Button>
            </>
          )}
        </CardContent>
      </Card>
    </div>
  );
}
