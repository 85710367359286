import { keys as k } from "common/mod.ts";
import { Calendar } from "components/ui/calendar";
import { Input } from "components/ui/input";
import { Label } from "components/ui/label";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "components/ui/tabs";
import { addDays, addHours, format } from "date-fns";
import { DATETIME_FORMAT } from "lib/utils";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

export default function PeriodSelector({
  startPeriod,
  endPeriod,
  setEndPeriod,
}) {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState("date");
  const [selectedDate, setSelectedDate] = useState(startPeriod || new Date());
  const [selectedDateHour, setSelectedDateHour] = useState(
    selectedDate.getHours()
  );
  const [selectedDateMinute, setSelectedDateMinute] = useState(
    selectedDate.getMinutes()
  );
  const [selectedDurationDays, setSelectedDurationDays] = useState(0);
  const [selectedDurationHours, setSelectedDurationHours] = useState(0);

  useEffect(() => {
    if (activeTab === "date") {
      setEndPeriod(
        new Date(
          selectedDate.getFullYear(),
          selectedDate.getMonth(),
          selectedDate.getDate(),
          selectedDateHour,
          selectedDateMinute
        )
      );
    } else {
      setEndPeriod(
        addDays(
          addHours(startPeriod || new Date(), selectedDurationHours),
          selectedDurationDays
        )
      );
    }
  }, [
    selectedDate,
    selectedDateHour,
    selectedDateMinute,
    selectedDurationDays,
    selectedDurationHours,
    activeTab,
  ]);

  return (
    <div className="grid gap-6 py-4">
      <Tabs
        defaultValue={activeTab}
        onValueChange={setActiveTab}
        className="border-b"
      >
        <TabsList className="flex">
          <TabsTrigger value="date">{t(k.SELECT_DATE)}</TabsTrigger>
          <TabsTrigger value="duration">{t(k.SELECT_DURATION)}</TabsTrigger>
        </TabsList>
        <TabsContent value="date" className="mb-10">
          <div className="flex flex-col items-center">
            <Calendar
              mode="single"
              selected={selectedDate}
              onSelect={(selectedDate) =>
                selectedDate && setSelectedDate(selectedDate)
              }
              defaultMonth={selectedDate}
              initialFocus
            />
            <div className="flex items-center gap-20">
              <div className="space-y-2">
                <Label htmlFor="hour">{t(k.HOURS)}</Label>
                <Input
                  id="hour"
                  type="number"
                  min="0"
                  max="23"
                  value={selectedDateHour}
                  onChange={(e) =>
                    e.target.value && setSelectedDateHour(e.target.value)
                  }
                />
              </div>
              <div className="space-y-2">
                <Label htmlFor="minute">{t(k.MINUTES)}</Label>
                <Input
                  id="minute"
                  type="number"
                  min="0"
                  max="59"
                  value={selectedDateMinute}
                  onChange={(e) => setSelectedDateMinute(e.target.value)}
                />
              </div>
            </div>
          </div>
        </TabsContent>
        <TabsContent value="duration" className="mb-10">
          <div className="grid gap-4">
            <div className="grid grid-cols-2 gap-4">
              <div className="space-y-2">
                <Label htmlFor="days">{t(k.DAYS)}</Label>
                <Input
                  id="days"
                  type="number"
                  min="0"
                  defaultValue="0"
                  onChange={(e) =>
                    setSelectedDurationDays(parseInt(e.target.value))
                  }
                />
              </div>
              <div className="space-y-2">
                <Label htmlFor="hours">{t(k.HOURS)}</Label>
                <Input
                  id="hours"
                  type="number"
                  min="0"
                  defaultValue="0"
                  onChange={(e) =>
                    setSelectedDurationHours(parseInt(e.target.value))
                  }
                />
              </div>
            </div>
          </div>
        </TabsContent>
      </Tabs>

      <div className="mt-4 text-muted-foreground">
        {t(k.END_PERIOD)} {format(endPeriod, DATETIME_FORMAT)}
      </div>
    </div>
  );
}
