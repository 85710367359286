import { keys as k } from "common/mod.ts";
import AdminPaymentHistoryTable from "components/layout/admin/history/payment";
import { ContentLayout } from "components/layout/panel/content-layout";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from "components/ui/breadcrumb";
import { Card, CardContent, CardFooter, CardHeader } from "components/ui/card";
import { Spinner } from "components/ui/spinner";
import { handlePaymentHistory } from "handlers/admin-payment-history";
import { updatePaymentHistoryItem } from "handlers/payment-history";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { socket } from "socket";

export default function AdminPaymentHistory() {
  const { t } = useTranslation();
  const [items, setItems] = useState([]);
  const [spinner, setSpinner] = useState(true);

  useEffect(() => {
    socket.emit("main:fetch:admin-payment-history");
  }, []);

  useEffect(() => {
    handlePaymentHistory(socket, setItems, setSpinner);
  }, []);

  useEffect(() => {
    updatePaymentHistoryItem(socket, setItems);
  }, []);

  return (
    <ContentLayout title={t(k.PAYMENT_HISTORY)}>
      <Card className="rounded-lg border-none mt-6">
        <CardHeader>
          <Breadcrumb>
            <BreadcrumbList>
              <BreadcrumbItem>
                <BreadcrumbPage>{t(k.ADMIN_PANEL)}</BreadcrumbPage>
              </BreadcrumbItem>
              <BreadcrumbSeparator />
              <BreadcrumbItem>
                <BreadcrumbPage>{t(k.HISTORY)}</BreadcrumbPage>
              </BreadcrumbItem>
              <BreadcrumbSeparator />
              <BreadcrumbItem>
                <BreadcrumbPage>{t(k.PAYMENT)}</BreadcrumbPage>
              </BreadcrumbItem>
            </BreadcrumbList>
          </Breadcrumb>
        </CardHeader>

        <CardContent>
          {spinner && (
            <div className="flex justify-center">
              <Spinner className="text-primary" />
            </div>
          )}
          {items.length ? (
            <AdminPaymentHistoryTable items={items} />
          ) : (
            <p className="text-center text-muted-foreground">
              {t(k.NO_PAYMENT_HISTORY_AVAILABLE)}
            </p>
          )}
        </CardContent>
        <CardFooter>
          <div className="text-xs text-muted-foreground"></div>
        </CardFooter>
      </Card>
    </ContentLayout>
  );
}
