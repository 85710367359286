import { keys as k } from "common/mod.ts";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "components/ui/select";
import React from "react";
import { useTranslation } from "react-i18next";

export default function IPStatisticsFilter({
  selectedPeriod,
  setSelectedPeriod,
}) {
  const { t } = useTranslation();

  const periodFilterOptions = {
    all: t(k.IP_STATISTICS_ALL),
    "1h": t(k.IP_STATISTICS_LAST_HOUR),
    "6h": t(k.IP_STATISTICS_LAST_6_HOURS),
    "12h": t(k.IP_STATISTICS_LAST_12_HOURS),
    "24h": t(k.IP_STATISTICS_LAST_24_HOURS),
    "7d": t(k.IP_STATISTICS_LAST_7_DAYS),
    "30d": t(k.IP_STATISTICS_LAST_30_DAYS),
  };

  return (
    <div className="flex justify-between">
      <Select
        id="period"
        value={selectedPeriod}
        onValueChange={(val) => setSelectedPeriod(val)}
      >
        <SelectTrigger className="w-[200px]">
          <SelectValue placeholder={t(k.SELECT_PERIOD)} />
        </SelectTrigger>
        <SelectContent className="w-[200px]">
          {Object.entries(periodFilterOptions).map(([value, label]) => (
            <SelectItem key={label} value={value}>
              {label}
            </SelectItem>
          ))}
        </SelectContent>
      </Select>
    </div>
  );
}
