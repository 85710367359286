import { keys as k } from "common/mod.ts";
import AutoForm, { AutoFormSubmit } from "components/auto-form";
import { DependencyType } from "components/auto-form/types";
import { ContentLayout } from "components/layout/panel/content-layout";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from "components/ui/breadcrumb";
import { Card, CardContent, CardFooter, CardHeader } from "components/ui/card";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "sonner";
import { z } from "zod";

export default function Account() {
  const { t } = useTranslation();
  const [user, setUser] = useState(JSON.parse(localStorage.getItem("user")));

  const formSchema = z
    .object({
      email: z.string().email().optional().describe(t(k.EMAIL)),
      firstName: z
        .string()
        .min(2, {
          message: t(k.ACCOUNT_FIRST_NAME_VALIDATION),
        })
        .describe(t(k.FIRST_NAME)),
      lastName: z
        .string()
        .min(2, {
          message: t(k.ACCOUNT_LAST_NAME_VALIDATION),
        })
        .describe(t(k.LAST_NAME)),
      telegram: z.string().describe(t(k.TELEGRAM)).optional().nullish(),
      changePassword: z
        .boolean()
        .default(false)
        .optional()
        .describe(t(k.CHANGE_PASSWORD)),
      currentPassword: z.string().optional().describe(t(k.CURRENT_PASSWORD)),
      newPassword: z
        .string()
        .min(8, {
          message: t(k.ACCOUNT_PASSWORD_VALIDATION),
        })
        .optional()
        .describe(t(k.NEW_PASSWORD)),
      confirmPassword: z.string().optional().describe(t(k.CONFIRM_PASSWORD)),
    })
    .refine((data) => data.newPassword === data.confirmPassword, {
      message: t(k.ACCOUNT_PASSWORD_MATCH_VALIDATION),
      path: ["confirmPassword"],
    });

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));
    setUser(user);
  }, [user]);

  async function onSubmit(values) {
    const {
      email,
      telegram,
      firstName,
      lastName,
      currentPassword,
      newPassword,
    } = values;
    const userId = JSON.parse(localStorage.getItem("user"))._id;
    const response = await fetch(`/api/users/${userId}`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("accessToken")} `,
      },
      body: JSON.stringify({
        email,
        telegram: telegram?.startsWith("@")
          ? telegram.replace("@", "")
          : telegram,
        firstName,
        lastName,
        currentPassword,
        newPassword,
      }),
    });

    if (response.status !== 200) {
      switch (response.status) {
        case 400:
          return toast.error(t(k.ACCOUNT_INVALID_CURRENT_PASSWORD));
        case 401:
          return toast.error(t(k.ACCOUNT_UNAUTHORIZED));
        default:
          return toast.error(t(k.GENERAL_ERROR));
      }
    }

    const newUser = await response.json();
    localStorage.setItem("user", JSON.stringify(newUser));
    setUser(newUser);
    toast.success(t(k.ACCOUNT_UPDATED));
  }

  return (
    <ContentLayout title={t(k.ACCOUNT)}>
      <Card className="rounded-lg border-none mt-6">
        <CardHeader>
          <Breadcrumb>
            <BreadcrumbList>
              <BreadcrumbItem>
                <BreadcrumbPage>{t(k.SETTINGS)}</BreadcrumbPage>
              </BreadcrumbItem>
              <BreadcrumbSeparator />
              <BreadcrumbItem>
                <BreadcrumbPage>{t(k.ACCOUNT)}</BreadcrumbPage>
              </BreadcrumbItem>
            </BreadcrumbList>
          </Breadcrumb>
        </CardHeader>
        <CardContent>
          <AutoForm
            formSchema={formSchema}
            onSubmit={(data) => onSubmit(data)}
            values={user}
            fieldConfig={{
              currentPassword: {
                inputProps: {
                  type: "password",
                },
              },
              newPassword: {
                inputProps: {
                  type: "password",
                },
              },
              confirmPassword: {
                inputProps: {
                  type: "password",
                },
              },
            }}
            dependencies={[
              {
                type: DependencyType.HIDES,
                targetField: "currentPassword",
                when: (values) => !values.changePassword,
              },
              {
                type: DependencyType.HIDES,
                targetField: "newPassword",
                when: (values) => !values.changePassword,
              },
              {
                type: DependencyType.HIDES,
                targetField: "confirmPassword",
                when: (values) => !values.changePassword,
              },
              {
                type: DependencyType.REQUIRES,
                targetField: "currentPassword",
                when: (values) => values.changePassword,
              },
              {
                type: DependencyType.REQUIRES,
                targetField: "newPassword",
                when: (values) => values.changePassword,
              },
              {
                type: DependencyType.REQUIRES,
                targetField: "confirmPassword",
                when: (values) => values.changePassword,
              },
              {
                type: DependencyType.DISABLES,
                targetField: "email",
                when: (values) => values.email,
              },
            ]}
          >
            <AutoFormSubmit>{t(k.UPDATE_ACCOUNT)}</AutoFormSubmit>
          </AutoForm>
        </CardContent>
        <CardFooter>
          <div className="text-xs text-muted-foreground"></div>
        </CardFooter>
      </Card>
    </ContentLayout>
  );
}
