import { keys as k } from "common/mod.ts";
import { Button } from "components/ui/button";
import { Separator } from "components/ui/separator";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { socket } from "socket";
import { toast } from "sonner";

import AssignModemModal from "./assign-modal";
import AssignTrialOpen from "./assign-trial-modal";
import ClearSessionModal from "./clear-session-modal";
import DeleteModal from "./delete-modal";
import DHCPModal from "./dhcp-modal";
import ExtendSessionModal from "./extend-modal";
import RebootModal from "./reboot-modal";
import ResetModal from "./reset-modal";
import StatusSwitchOpen from "./status-switcher-modal";
import SwitchModal from "./switch-modal";

export default function Actions({ selectedModem }) {
  const { t } = useTranslation();
  const { _id, gatewayIP } = selectedModem;
  const [rebootOpen, setRebootOpen] = useState(false);
  const [resetOpen, setResetOpen] = useState(false);
  const [statusSwitchOpen, setStatusSwitchOpen] = useState(false);
  const [switchOpen, setSwitchOpen] = useState(false);
  const [extendOpen, setExtendOpen] = useState(false);
  const [clearSessionOpen, setClearSessionOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [dhcpOpen, setDhcpOpen] = useState(false);
  const [assignOpen, setAssignOpen] = useState(false);
  const [assignTrialOpen, setAssignTrialOpen] = useState(false);

  const changeIP = () => {
    toast.success(t(k.ACTIONS_IP_CHANGE_REQUESTED));
    socket.emit("main:action:changeIp", _id);
  };

  return (
    <>
      <div className="flex flex-col gap-4 text-center items-center relative p-6">
        <Separator
          orientation="horizontal"
          className="invisible md:visible absolute w-[90%] mx-auto"
        />

        <h3 className="text-lg font-semibold mt-8 mb-8">{t(k.ACTIONS)}</h3>
        <div className="flex flex-col gap-4 flex-wrap flex-start">
          <div className="flex gap-4 flex-wrap justify-center">
            <Button variant="outline" onClick={() => changeIP()}>
              {t(k.CHANGE_IP)}
            </Button>
            <Button variant="outline" onClick={() => setDhcpOpen(true)}>
              {t(k.CHANGE_DHCP)}
            </Button>

            <Button variant="outline" onClick={() => setRebootOpen(true)}>
              {t(k.REBOOT)}
            </Button>

            <Button variant="outline" onClick={() => setResetOpen(true)}>
              {t(k.RESET)}
            </Button>
          </div>
          <div className="flex gap-4 flex-wrap justify-center">
            <Button
              variant="outline"
              onClick={() => setSwitchOpen(true)}
              disabled={selectedModem.occupancyStatus === "free"}
            >
              {t(k.SWITCH)}
            </Button>
            <Button
              variant="outline"
              disabled={
                selectedModem.occupancyStatus === "free" &&
                !selectedModem.session?.limitTime
              }
              onClick={() => setClearSessionOpen(true)}
            >
              {t(k.CLEAR_SESSION)}
            </Button>
            <Button
              variant="outline"
              disabled={selectedModem.occupancyStatus === "free"}
              onClick={() => setExtendOpen(true)}
            >
              {t(k.EXTEND)}
            </Button>
          </div>
          <div className="flex gap-4 flex-wrap justify-center">
            <Button
              variant="outline"
              disabled={selectedModem.occupancyStatus !== "free"}
              onClick={() => setAssignOpen(true)}
            >
              {t(k.ASSIGN)}
            </Button>

            <Button
              variant="outline"
              disabled={selectedModem.occupancyStatus !== "free"}
              onClick={() => setAssignTrialOpen(true)}
            >
              {t(k.ASSIGN_TRIAL)}
            </Button>
          </div>
          <div className="flex gap-4 flex-wrap justify-center">
            <Button variant="outline" onClick={() => setStatusSwitchOpen(true)}>
              {selectedModem.status === "disabled" ? t(k.ENABLE) : t(k.DISABLE)}
            </Button>
            <Button variant="destructive" onClick={() => setDeleteOpen(true)}>
              {t(k.DELETE)}
            </Button>
          </div>
        </div>
      </div>
      {dhcpOpen && (
        <DHCPModal
          modemId={_id}
          modemGatewayIP={gatewayIP}
          setDhcpOpen={setDhcpOpen}
        />
      )}
      {rebootOpen && (
        <RebootModal modemId={_id} setRebootOpen={setRebootOpen} />
      )}
      {resetOpen && <ResetModal modemId={_id} setResetOpen={setResetOpen} />}
      {statusSwitchOpen && (
        <StatusSwitchOpen
          modemId={_id}
          status={selectedModem.status}
          setStatusSwitchOpen={setStatusSwitchOpen}
        />
      )}
      {deleteOpen && (
        <DeleteModal modemId={_id} setDeleteOpen={setDeleteOpen} />
      )}
      {switchOpen && (
        <SwitchModal modemId={_id} setSwitchOpen={setSwitchOpen} />
      )}
      {extendOpen && (
        <ExtendSessionModal
          modemId={_id}
          limitTime={selectedModem.session.limitTime}
          setExtendOpen={setExtendOpen}
        />
      )}
      {clearSessionOpen && (
        <ClearSessionModal
          modemId={_id}
          setClearSessionOpen={setClearSessionOpen}
        />
      )}
      {assignOpen && (
        <AssignModemModal modemId={_id} setAsignModemOpen={setAssignOpen} />
      )}
      {assignTrialOpen && (
        <AssignTrialOpen
          modemId={_id}
          setAssignTrialOpen={setAssignTrialOpen}
        />
      )}
    </>
  );
}
