export default {
  SORT_BY: "Сортировать",
  DATE: "Дата",
  STATUS: "Статус",
  SORT_BY_DATE: "по дате",
  SORT_BY_STATUS: "по статусу",
  ASCENDING: "по возрастанию",
  DESCENDING: "по убыванию",
  SELECT_USER: "Выбрать пользователя",
  NO_USER_FOUND: "Пользователь не найден.",
  USER: "Пользователь",
  LAST_UPDATED: "Дата",
  AMOUNT: "Сумма",
  REFUND_AMOUNT: "Сумма возврата",
  TRANSACTION_ID: "ID транзакции",
  COMPLETED: "завершено",
  TOGGLE_MENU: "Переключить меню",
  ACTIONS: "Действия",
  REFUND: "Возврат",
  CONFIRM_REFUND: "Вы уверены, что хотите вернуть этот платеж?",
  REFUND_DESCRIPTION:
    "Возврат этого платежа отменит транзакцию, и пользователю\nбудет возвращена сумма.",
  REFUND_AMOUNT_WARNING:
    "Сумма возврата должна быть меньше или равна сумме платежа",
  CANCEL: "Отмена",
  MODIFY: "Изменить",
  CONFIRM: "Подтвердить",
  CREATE: "Создать",
  PRICING_PLAN: "тарифный план",
  CLEAR: "Очистить",
  COUNTRY: "Страна",
  CITY: "Город",
  OPERATOR: "Оператор",
  ADD_SUB_PLAN: "Добавить под-план",
  UPDATE: "Обновить",
  SUBMIT: "Отправить",
  PLAN: "план",
  SUBMIT_PLAN: "Добавить план",
  SUB_PLAN: "Под-план",
  TITLE: "Заголовок",
  PRICE: "Цена",
  DESCRIPTION: "Описание",
  FEATURES: "Деталии",
  ADD_FEATURE: "Добавить деталь",
  PRICING_PLANS: "Тарифные планы",
  AVAILABLE_MODEMS_COUNT: "Доступно модемов",
  PRICING_PLANS_DESCRIPTION: "Выберите план, который подойдет именно вам",
  ALL: "Все",
  PLANS: "Планы",
  VIEW_PLANS: "Просмотр планов",
  COPY_PLAN: "Копировать план",
  EDIT_PLAN: "Редактировать план",
  DELETE_PLAN: "Удалить план",
  ADD_PLAN: "Добавить план",
  CHANGE_IP: "Сменить IP",
  CHANGE_DHCP: "Сменить DHCP",
  REBOOT: "Перезагрузить",
  RESET: "Сбросить",
  SWITCH: "Переключить",
  EXTEND: "Продлить",
  ASSIGN: "Назначить",
  DELETE: "Удалить",
  ASSIGN_MODEM: "Назначить модем",
  ASSIGN_TRIAL: "Назначить пробную версию",
  CLEAR_SESSION: "Очистить сессию",
  CLEAR_SESSION_WARNING: "Вы уверены, что хотите очистить сессию?",
  DELETE_MODEM: "Удалить модем",
  DELETE_MODEM_WARNING: "Вы уверены, что хотите удалить этот модем?",
  CHANGE_MODEM_DHCP: "Изменить DHCP модема",
  CHANGE_MODEM_DHCP_WARNING: "Это изменит IP шлюза DHCP для модема.",
  SELECT_PERIOD: "Выберите период",
  SELECT_PERIOD_DESCRIPTION: "Выберите, как вы хотите продлить текущий период.",
  EXTEND_SESSION: "Продлить сессию",
  LOCATIONS: "Локации",
  SERVER_NUMBER: "Номер сервера",
  OCCUPANCY: "Занятость",
  OCCUPANCY_STATUS: "Статус занятости",
  CLEAR_FILTERS: "Очистить фильтры",
  MANAGE_COLUMNS: "Управление колонками",
  SIGNAL: "Сигнал",
  PUBLIC_IP: "Публичный IP",
  INTERFACE: "Интерфейс",
  INTERNAL_IP: "Внутренний IP",
  GATEWAY_IP: "DHCP",
  UPDATED_AT: "Обновлено в",
  COMMENT: "Комментарий",
  RENEW: "Продлить",
  RENEW_SUBSCRIPTION: "Продлить подписку",
  RENEW_SUBSCRIPTION_DESCRIPTION:
    "Выберите период для продления текущей подписки.",
  MODEM_STATUS: "Статус модема",
  USER_LOGIN_INFO: "Логин пользователя",
  ADMIN_LOGIN_INFO: "Логин администратора",
  VALID_UNTIL: "Действительно до",
  UPDATED: "Обновлено",
  SELECT_DATE: "Выберите дату",
  SELECT_DURATION: "Выберите продолжительность",
  HOURS: "Часы",
  MINUTES: "Минуты",
  DAYS: "Дней",
  END_PERIOD: "Конец периода:",
  MODEM_DETAILS: "Детали модема",
  GENERAL: "Общие",
  ID: "ID",
  SERVER_ID: "ID сервера",
  NUMBER: "Номер",
  LOCATION_NUMBER: "Номер локации",
  COUNT: "Количество",
  LAST_REBOOT: "Последняя перезагрузка",
  SESSION: "Сессия",
  RESERVED_BY: "Зарезервировано",
  RESERVED_UNTIL: "Зарезервировано до",
  PURCHASED_BY: "Куплено",
  PURCHASED_UNTIL: "Куплено до",
  USED_TRAFFIC: "Использованный трафик",
  LIMIT_TRAFFIC: "Лимит трафика",
  LATEST_DOWNLOAD_SPEED: "Последняя скорость загрузки",
  LATEST_UPLOAD_SPEED: "Последняя скорость выгрузки",
  CONNECTION_DETAILS: "Детали соединения",
  URL: "URL",
  COPY_URL: "Копировать URL",
  HTTP_PORT: "HTTP Порт",
  COPY_HTTP_PORT: "Копировать HTTP порт",
  SOCKS_PORT: "SOCKS5 Порт",
  COPY_SOCKS_PORT: "Копировать SOCKS5 порт",
  ADMIN_USERNAME: "Имя пользователя администратора",
  COPY_USERNAME: "Копировать имя пользователя",
  COPY_HTTP_LOGIN: "Копировать HTTP логин",
  ADMIN_PASSWORD: "Пароль администратора",
  COPY_PASSWORD: "Копировать пароль",
  COPY_SOCKS_LOGIN: "Копировать SOCKS5 логин",
  CLIENT_USERNAME: "Имя пользователя клиента",
  CLIENT_PASSWORD: "Пароль клиента",
  CLOSE: "Закрыть",
  REBOOT_MODEM: "Перезагрузить модем",
  REBOOT_MODEM_WARNING: "Вы уверены, что хотите перезагрузить этот модем?",
  RESET_MODEM: "Сбросить модем",
  RESET_MODEM_WARNING: "Вы уверены, что хотите сбросить этот модем?",
  ENABLE_MODEM: "Включить модем",
  DISABLE_MODEM: "Отключить модем",
  ENABLE_MODEM_CONFIRM: "Вы уверены, что хотите включить этот модем?",
  DISABLE_MODEM_CONFIRM: "Вы уверены, что хотите отключить этот модем?",
  SHOW_ACTIONS: "Показать действия",
  SHOW_MODEM: "Показать модем",
  FREE: "Свободен",
  RESERVED: "Зарезервировано",
  BUSY: "Занят",
  SWITCH_MODEM: "Переключить модем",
  SELECT: "Выбрать",
  SERVER_RESTRICTIONS_MANAGEMENT: "Управление ограничениями сервера",
  PUBLIC_URL: "Публичный URL",
  ANYDESK: "AnyDesk",
  CONNECT: "Соединить",
  RESTRICTIONS: "Ограничения",
  NEW_RESTRICTION: "Новое ограничение",
  IP_RESTRICTIONS_FOR: "Ограничения IP для",
  CURRENT_RESTRICTIONS: "Текущие ограничения",
  REMOVE: "Удалить",
  RESTRICTIONS_ACTIONS: "Действия ограничений",
  ADD_NEW_RESTRICTIONS: "Добавить новые ограничения",
  ADD: "Добавить",
  OR: "ИЛИ",
  COPY_RESTRICTIONS_FROM_ANOTHER: "Копировать ограничения с другого сервера",
  SELECT_SERVER: "Выбрать сервер",
  SELECT_COUNTRY: "Выбрать страну",
  SELECT_CITY: "Выбрать город",
  SELECT_OPERATOR: "Выбрать оператора",
  SELECT_LOCATION: "Выбрать локацию",
  COPY: "Копировать",
  UPDATE_RESTRICTIONS: "Обновить ограничения",
  AVAILABLE: "Доступен",
  UNAVAILABLE: "Недоступен",
  NO_INTERNET: "Нет сети",
  EDIT_BALANCE: "Изменить баланс",
  ENTER_BALANCE: "Введите баланс",
  EDIT: "Изменить",
  ENABLE: "Включить",
  DISABLE: "Отключить",
  EMAIL: "Электронная почта",
  TELEGRAM_ID: "Telegram",
  FULL_NAME: "Полное имя",
  BALANCE: "Баланс",
  EMAIL_PLACEHOLDER: "user@example.com",
  FIRST_NAME_PLACEHOLDER: "Иван",
  LAST_NAME_PLACEHOLDER: "Иванов",
  TELEGRAM_PLACEHOLDER: "@username",
  ROLE: "Роль",
  DEFAULT: "по умолчанию",
  GHOST: "призрак",
  RIGHT: "право",
  START: "начало",
  SIGN_OUT: "Выйти",
  GLOBALPROXY: "Global Proxy",
  PROFILE: "Профиль",
  ACCOUNT: "Аккаунт",
  SELECT_AN_OPTION: "Выберите опцию",
  EDIT_COMMENT: "Редактировать комментарий",
  ENTER_COMMENT: "Введите комментарий",
  SHOW: "Показать",
  USERNAME: "Имя пользователя",
  PASSWORD: "Пароль",
  COPY_CHANGE_IP_URL: "Копировать URL смены IP",
  SAVE_CHANGES: "Сохранить изменения",
  IP: "IP",
  LOGIN_INFO: "Информация для входа",
  QUANTITY: "Количество",
  PERIOD: "Период",
  PRICE_PER_ITEM: "Цена за единицу",
  SWITCH_THEME: "Переключить тему",
  MORE: "Больше",
  PICK_A_DATE: "Выбрать дату",
  COLUMNS: "Колонки",
  LOCATION: "Местоположение",
  NO_RESULTS_FOUND: "Результаты не найдены",
  CUSTOM: "Пользовательский",
  PURCHASE_PRODUCT: "Купить продукт",
  PURCHASE_PRODUCT_DESCRIPTION:
    "Заполните данные ниже, чтобы завершить покупку.",
  TOTAL: "Итого",
  REQUEST_TRIAL: "Запросить пробную версию",
  PURCHASE: "Купить",
  DUE: "",
  PURCHASE_REDIRECT_INFO: "* Вы будете перенаправлены на платежную ссылку.",
  CURRENT_BALANCE: "Текущий баланс",
  REFILL_AMOUNT: "Сумма пополнения",
  NEW_BALANCE: "Новый баланс",
  REFILL_BALANCE: "Пополнить баланс",
  REQUEST_TRIAL_ACCESS: "Запросить доступ к пробной версии",
  REQUEST_TRIAL_PROVIDE_HANDLER_WARNING:
    "Пожалуйста, предоставьте либо телеграм-аккаунт, либо адрес электронной почты.",
  REQUEST_TRIAL_ACCESS_DESCRIPTION:
    "Заполните форму ниже, чтобы запросить доступ к пробной версии.\nУкажите либо телеграм-аккаунт, либо адрес электронной почты для связи.",
  TELEGRAM: "Телеграм",
  TRIAL_PROCESS: "Процесс получения пробной версии:",
  TRIAL_PROCESS_STEP_1: "Мы получим ваш запрос на пробную версию",
  TRIAL_PROCESS_STEP_2: "Наша команда рассмотрит и предоставит доступ",
  TRIAL_PROCESS_STEP_3:
    "Мы свяжемся с вами через телеграм или электронную почту с дальнейшими инструкциями",
  SUBMIT_REQUEST: "Отправить запрос",
  HOME: "Главная",
  DASHBOARD: "Панель",
  ADMIN_PANEL: "Панель администратора",
  HISTORY: "История",
  PAYMENT: "Платеж",
  PAYMENT_HISTORY: "История платежей",
  PROXY_HISTORY: "История прокси",
  NO_PAYMENT_HISTORY_AVAILABLE: "История платежей недоступна",
  PROXIES: "Прокси",
  NO_PROXIES_AVAILABLE: "Прокси недоступны",
  SERVERS: "Серверы",
  NO_SERVERS_AVAILABLE: "Серверы недоступны",
  USERS: "Пользователи",
  NO_USERS_AVAILABLE: "Пользователи недоступны",
  COUPONS: "Купоны",
  NO_COUPONS_AVAILABLE: "Купоны недоступны",
  ADD_COUPON: "Добавить купон",
  GENERATE_COUPON: "Сгенерировать",
  UPDATE_COUPON: "Обновить купон",
  NO_COUPON_FOUND: "Купон не найден",
  COUPON_INVALID: "Недействительный купон",
  SELECT_COUPON: "Выберите купон",
  COUPON_CODE: "Код купона",
  COUPON_DISCOUNT: "Скидка",
  COUPON_TYPE: "Тип",
  COUPON_USES: "Использования",
  COUPON_LIMIT: "Лимит использований",
  COUPON_EXPIRY: "Истекает",
  COUPON_FIXED: "Фиксированная сумма",
  COUPON_PERCENTAGE: "Процент",
  COUPON_ZERO_IS_UNLIMITED: "0 - неограниченно",
  COUPON_CODE_VALIDATION: "Код купона должен содержать не менее 2 символов.",
  COUPON_PERCENTAGE_VALIDATION: "Процент должен быть меньше или равен 100",
  COUPON_AFFECTED_PRODUCTS: "Локации",
  SELECT_COUNTRIES: "Выберите страны",
  SELECT_COUNTRIES_DESCRIPTION:
    "Выберите страны, для которых будет доступен купон",
  SELECT_CITIES: "Выберите города",
  SELECT_CITIES_DESCRIPTION:
    "Выберите города, для которых будет доступен купон. Применяется только если одна страна выбрана",
  SELECT_OPERATORS: "Выберите операторов",
  SELECT_OPERATORS_DESCRIPTION:
    "Выберите операторов, для которых будет доступен купон. Применяется только если один город выбран",
  ALL_COUNTRIES: "Все страны",
  ALL_CITIES: "Все города",
  ALL_OPERATORS: "Все операторы",
  ALL_LOCATIONS: "Все локации",
  NONE_INCLUDES_ALL: "Если ничего не выбрано, это включает все",
  CONFIRM_EMAIL: "Подтвердите электронную почту",
  CONFIRM_EMAIL_DESCRIPTION:
    "Пожалуйста, проверьте свою электронную почту, чтобы подтвердить учетную запись",
  TOKEN_UNUSED:
    "Вы будете перенаправлены на страницу входа после подтверждения\nэлектронной почты. Если вы не видите письмо, проверьте папку со спамом.\nЕсли вы все еще не видите письмо, свяжитесь со службой поддержки.",
  TOKEN_INVALID_INFO:
    "Токен недействителен. Попробуйте снова или свяжитесь со службой поддержки.",
  REGENERATE_TOKEN: "Сгенерировать токен снова",
  TOKEN_EXPIRED_INFO:
    "Токен истек. Попробуйте снова или свяжитесь со службой поддержки.",
  ERROR_PAGE_TITLE: "Ой, что-то пошло не так!",
  ERROR_PAGE_DESCRIPTION:
    "Извините, произошла неожиданная ошибка. Пожалуйста, попробуйте\nпозже или свяжитесь со службой поддержки, если проблема сохраняется.",
  GO_TO_HOMEPAGE: "Перейти на главную страницу",
  IP_STATISTICS: "Статистика IP",
  NO_IP_STATISTICS_AVAILABLE: "Статистика IP недоступна",
  PURCHASE_PROXIES: "Купить прокси",
  NO_PROXIES_HISTORY_AVAILABLE: "История прокси недоступна",
  LOGIN: "Войти",
  LOGIN_DESCRIPTION: "Введите свой email ниже для входа в учетную запись",
  FORGOT_YOUR_PASSWORD: "Забыли пароль?",
  LOGIN_NO_ACCOUNT: "Нет учетной записи?",
  FORGOT_PASSWORD: "Забыли пароль",
  FORGOT_PASSWORD_DESCRIPTION: "Введите свой email ниже для сброса пароля",
  RESET_PASSWORD: "Сбросить пароль",
  BACK_TO_LOGIN: "Назад ко входу",
  SIGN_UP: "Зарегистрироваться",
  SIGN_UP_DESCRIPTION: "Введите свои данные для создания учетной записи",
  FIRST_NAME: "Имя",
  LAST_NAME: "Фамилия",
  CONFIRM_PASSWORD: "Подтвердите пароль",
  CREATE_AN_ACCOUNT: "Создать учетную запись",
  ALREADY_HAVE_AN_ACCOUNT: "Уже есть учетная запись?",
  SIGN_IN: "Войти",
  SETTINGS: "Настройки",
  UPDATE_ACCOUNT: "Обновить учетную запись",
  REFILL: "Пополнить",
  CONTACT: "Контакты",
  TELEGRAM_OFFICIAL: "Официальный канал",
  TELEGRAM_OFFICIAL_LINK: "https://t.me/globalproxy_official",
  TELEGRAM_SUPPORT: "Служба поддержки",
  TELEGRAM_SUPPORT_LINK: "https://t.me/GlobalProxy_support",
  INVALID_EMAIL_OR_PASSWORD: "Неверный email или пароль",
  USER_NOT_ACTIVE:
    "Пользователь не активен. Проверьте свою электронную почту для активации.",
  LOGGED_IN: "Вход выполнен успешно",
  LOGGED_OUT: "Выход выполнен успешно",
  AUTHENTICATION_FAILED:
    "Аутентификация не удалась. Пожалуйста, войдите снова.",
  GENERAL_ERROR: "Произошла ошибка. Попробуйте снова.",
  CONNECTION_ERROR: "Ошибка соединения. Переподключение...",
  RESET_PASSWORD_EMAIL_SENT: "Письмо для сброса пароля отправлено",
  PASSWORDS_DO_NOT_MATCH: "Пароли не совпадают",
  REGISTER_ERROR: "Произошла ошибка при регистрации. Попробуйте снова.",
  REGISTER_SUCCESS:
    "Регистрация прошла успешно. Проверьте свою электронную почту для активации.",
  CHANGE_PASSWORD: "Сменить пароль",
  CURRENT_PASSWORD: "Текущий пароль",
  NEW_PASSWORD: "Новый пароль",
  ACCOUNT_UPDATED: "Учетная запись успешно обновлена",
  ACCOUNT_FIRST_NAME_VALIDATION: "Имя должно содержать не менее 2 символов.",
  ACCOUNT_LAST_NAME_VALIDATION: "Фамилия должна содержать не менее 2 символов.",
  ACCOUNT_PASSWORD_VALIDATION: "Пароль должен содержать не менее 8 символов.",
  ACCOUNT_PASSWORD_MATCH_VALIDATION: "Пароли не совпадают.",
  ACCOUNT_INVALID_CURRENT_PASSWORD: "Неверный текущий пароль. Попробуйте снова",
  ACCOUNT_UNAUTHORIZED: "Неавторизовано. Пожалуйста, попробуйте снова войти.",
  CONFIRM_USER_SUCCESS: "Пользователь успешно подтвержден",
  CONFIRM_USER_NO_USER_ID: "Не указан ID пользователя",
  CONFIRM_USER_TOKEN_REGENERATED:
    "Токен успешно сгенерирован. Проверьте свою электронную почту",
  INVALID_RESTRICTION: "Недействительное ограничение",
  INVALID_RESTRICTIONS_COPIED:
    "Некоторые ограничения не были скопированы, так как они недействительны",
  AUTH_USER_NOT_ACTIVE:
    "Пользователь не активен. Проверьте свою электронную почту для активации.",
  ACTIONS_MODEM_REBOOT_INITIATED: "Инициализирована перезагрузка модема",
  ACTIONS_MODEM_RESET_INITIATED: "Инициализирован сброс модема",
  ACTIONS_MODEM_DELETE_INITIATED: "Инициализировано удаление модема",
  ACTIONS_DHCP_CHANGE_INITIATED: "Инициализировано изменение DHCP",
  ACTIONS_MODEM_SWITCH_INITIATED: "Инициализировано переключение модема",
  ACTIONS_SESSION_CLEARED: "Сессия очищена",
  ACTIONS_SESSION_EXTENDED: "Сессия продлена",
  ACTIONS_MODEM_ASSIGNED: "Модем назначен",
  ACTIONS_TRIAL_ASSIGNED: "Пробная версия назначена",
  ACTIONS_IP_CHANGE_REQUESTED: "Запрошена смена IP",
  ACTIONS_REBOOT_REQUESTED: "Запрошена перезагрузка",
  ACTIONS_RESET_REQUESTED: "Запрошен сброс",
  ACTIONS_RESTRICTIONS_UPDATED: "Ограничения обновлены",
  ACTIONS_BALANCE_UPDATED: "Баланс обновлен",
  ACTIONS_COMMENT_UPDATED: "Комментарий обновлен",
  ACTIONS_RENEW_UPDATED: "Продление обновлено",
  ACTIONS_STATUS_SWITCH_INITIATED: "Инициализировано изменение статуса",
  PROXY_LOGIN_INFO_UPDATED: "Информация для входа в прокси обновлена",
  PAYMENT_REQUEST_SUCCESS: "Платеж успешен. Переход на страницу прокси...",
  IP_STATISTICS_ALL: "Все",
  IP_STATISTICS_LAST_HOUR: "Последний час",
  IP_STATISTICS_LAST_6_HOURS: "Последние 6 часов",
  IP_STATISTICS_LAST_12_HOURS: "Последние 12 часов",
  IP_STATISTICS_LAST_24_HOURS: "Последние 24 часа",
  IP_STATISTICS_LAST_7_DAYS: "Последние 7 дней",
  IP_STATISTICS_LAST_30_DAYS: "Последние 30 дней",
  NEW_GATEWAY_IP: "Новый IP DHCP",
  NOTIFICATIONS: "Уведомления",
  ALL_NOTIFICATIONS: "Все",
  OPERATIONAL_NOTIFICATIONS: "Операционные",
  ACCOUNT_NOTIFICATIONS: "Аккаунте",
  BILLING_NOTIFICATIONS: "Платежи",
  MARK_AS_READ: "Отметить как прочитанное",
  DASHBOARD_GRANULARITY_SELECT: "Выберите гранулярность",
  DASHBOARD_GRANULARITY_MINUTELY: "Минуты",
  DASHBOARD_GRANULARITY_HOURLY: "Часы",
  DASHBOARD_GRANULARITY_DAILY: "Дни",
  DASHBOARD_GRANULARITY_WEEKLY: "Недели",
  DASHBOARD_GRANULARITY_MONTHLY: "Месяцы",
  DASHBOARD_GRANULARITY_YEARLY: "Годы",
  DASHBOARD_AVAILABILITY_CHART_TITLE: "Доступность модемов",
  DASHBOARD_AVAILAILABILITY_FREE: "Свободен",
  DASHBOARD_AVAILAILABILITY_BUSY: "Занят",
  DASHBOARD_AVAILAILABILITY_RESERVED: "Зарезервирован",
  DASHBOARD_STATUS_CHART_TITLE: "Статус модемов",
  DASHBOARD_STATUS_AVAILABLE: "Доступен",
  DASHBOARD_STATUS_DISABLED: "Отключен",
  DASHBOARD_STATUS_UNAVAILABLE: "Недоступен",
  DASHBOARD_STATUS_NO_INTERNET: "Нет сети",
  MARK_ALL_AS_READ: "Отметить все как прочитанные",
  NO_NOTIFICATIONS: "Уведомлений нет",
  NOTIFICATION_COPIED_TO_CLIPBOARD: "Скопировано в буфер обмена: {{content}}",
  NOTIFICATION_CLIPBOARD_NOT_SUPPORTED: "Буфер обмена не поддерживается",
  NOTIFICATION_IP_CHANGE_SUCCESS: "IP успешно изменен: {{newIp}}",
  NOTIFICATION_REBOOT_SUCCESS: "Модем успешно перезагружен",
  NOTIFICATION_RESET_SUCCESS: "Модем успешно сброшен",
  NOTIFICATION_USER_UPDATE_SUCCESS:
    "Учетная запись пользователя успешно обновлена",
  NOTIFICATION_PLAN_CREATE_SUCCESS: "План успешно создан",
  NOTIFICATION_PLAN_UPDATE_SUCCESS: "План успешно обновлен",
  NOTIFICATION_PLAN_DELETE_SUCCESS: "План успешно удален",
  NOTIFICATION_REFUND_SUCCESS: "Платеж успешно возвращен",
  NOTIFICATION_TRIAL_REQUEST_SUCCESS:
    "Запрос на пробную версию успешно отправлен",
  NOTIFICATION_COUPON_CREATE_SUCCESS: "Купон успешно создан",
  NOTIFICATION_COUPON_UPDATE_SUCCESS: "Купон успешно обновлен",
  NOTIFICATION_SESSION_PURCHASE_SUCCESS_SUBJECT:
    "Покупка сессии прокси успешна",
  NOTIFICATION_SESSION_PURCHASE_SUCCESS_BODY:
    "Ваша сессия прокси успешно куплена. Зайдите на страницу прокси, чтобы увидеть детали.",
  NOTIFICATION_SESSION_PURCHASE_FAILED_SUBJECT:
    "Покупка сессии прокси не удалась",
  NOTIFICATION_SESSION_PURCHASE_FAILED_BODY:
    "Покупка сессии прокси не удалась.",
  NOTIFICATION_SESSION_EXPIRATION_SUBJECT: "Сессия прокси истекает",
  NOTIFICATION_SESSION_EXPIRATION_BODY:
    "Ваша сессия прокси скоро истечет. Пожалуйста, продлите ее, чтобы продолжить использование.",
  NOTIFICATION_SESSION_RENEWAL_SUBJECT: "Сессия прокси скоро продлится",
  NOTIFICATION_SESSION_RENEWAL_BODY:
    "Ваша сессия прокси скоро продлится. Пожалуйста проверьте ваш баланс чтобы успешно продлить сессию.",
  NOTIFICATION_SESSION_RENEWAL_FAILED_SUBJECT: "Продление сессии не удалось",
  NOTIFICATION_SESSION_RENEWAL_FAILED_BODY:
    "Продление сессии не удалось из-за недостаточного баланса.",
  NOTIFICATION_SESSION_RENEWED_SUBJECT: "Сессия прокси продлена",
  NOTIFICATION_SESSION_RENEWED_BODY: "Ваша сессия прокси успешно продлена.",
  ERROR_USER_NOT_FOUND: "Пользователь не найден",
  ERROR_NO_MODEMS_AVAILABLE: "Модемы недоступны",
  ERROR_GATEWAY_EXISTS: "IP DHCP уже существует",
  ERROR_PROXY_SERVER_NOT_CONNECTED: "Прокси сервер не подключен",
  ERROR_MODEM_NOT_FOUND: "Модем не найден",
  ERROR_MODEM_ASSIGNED: "Модем уже назначен",
  ERROR_IP_CHANGE: "Ошибка смены IP",
  ERROR_REBOOT_FAILED: "Ошибка перезагрузки",
  ERROR_RESET_FAILED: "Ошибка сброса",
  ERROR_REBOOT_LIMIT: "Превышен лимит перезагрузок",
  ERROR_MODEM_NOT_PURCHASED: "Модем не куплен",
  ERROR_UNAUTHORIZED_ACTION: "Недопустимое действие",
  ERROR_SERVER_NOT_FOUND: "Сервер не найден",
  ERROR_PRICING_PLAN_NOT_FOUND: "Тарифный план не найден",
  ERROR_PURCHASE_INVALID_QUANTITY: "Неверное количество прокси",
  ERROR_PAYMENT_NOT_FOUND: "Платеж не найден",
  ERROR_PAYMENT_NOT_COMPLETED: "Платеж не завершен",
  ERROR_REFUND_AMOUNT_EXCEEDS: "Сумма возврата превышает сумму платежа",
  ERROR_COUPON_EXISTS: "Купон уже существует",
  ERROR_COUPON_NOT_FOUND: "Купон не найден",
};
