import { keys as k } from "common/mod.ts";
import {
  IPStatisticsColumns,
  IPStatisticsFilter,
  IPStatisticsTable,
} from "components/layout/general/ipStatistics";
import { ContentLayout } from "components/layout/panel/content-layout";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbList,
  BreadcrumbPage,
} from "components/ui/breadcrumb";
import { Card, CardContent, CardFooter, CardHeader } from "components/ui/card";
import { Spinner } from "components/ui/spinner";
import { ipStatisicsHandler } from "handlers/ip-statistics";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { socket } from "socket";

export default function IPStatistics() {
  const { t } = useTranslation();
  const [items, setItems] = useState({});
  const [spinner, setSpinner] = useState(true);
  const [selectedPeriod, setSelectedPeriod] = useState("");
  const storageColumns = JSON.parse(localStorage.getItem("ip-stats-cols"));

  const [selectedOperatorColumn, setSelectedOperatorColumn] = useState(
    storageColumns?.operator ?? true
  );
  const [selectedServerColumn, setSelectedServerColumn] = useState(
    storageColumns?.server ?? true
  );
  const [selectedCityColumn, setSelectedCityColumn] = useState(
    storageColumns?.city ?? true
  );

  useEffect(() => {
    socket.emit("main:fetch:ip-stats", selectedPeriod);
  }, [selectedPeriod]);

  useEffect(() => {
    ipStatisicsHandler(socket, setItems, setSpinner);
  }, []);

  return (
    <ContentLayout title={t(k.IP_STATISTICS)}>
      <Card className="rounded-lg border-none mt-6">
        <CardHeader>
          <Breadcrumb>
            <BreadcrumbList>
              <BreadcrumbItem>
                <BreadcrumbPage>{t(k.IP_STATISTICS)}</BreadcrumbPage>
              </BreadcrumbItem>
            </BreadcrumbList>
          </Breadcrumb>
        </CardHeader>
        <CardContent>
          <div className="flex justify-between mb-2">
            <IPStatisticsFilter
              selectedPeriod={selectedPeriod}
              setSelectedPeriod={setSelectedPeriod}
            />
            <IPStatisticsColumns
              selectedOperatorColumn={selectedOperatorColumn}
              setSelectedOperatorColumn={setSelectedOperatorColumn}
              selectedServerColumn={selectedServerColumn}
              setSelectedServerColumn={setSelectedServerColumn}
              selectedCityColumn={selectedCityColumn}
              setSelectedCityColumn={setSelectedCityColumn}
            />
          </div>
          {spinner && <Spinner className="text-primary flex justify-center" />}
          {items.length ? (
            <IPStatisticsTable
              items={items}
              selectedOperatorColumn={selectedOperatorColumn}
              selectedServerColumn={selectedServerColumn}
              selectedCityColumn={selectedCityColumn}
            />
          ) : (
            <p className="text-center text-muted-foreground">
              {t(k.NO_IP_STATISTICS_AVAILABLE)}
            </p>
          )}
        </CardContent>
        <CardFooter></CardFooter>
      </Card>
    </ContentLayout>
  );
}
