import { keys as k } from "common/mod.ts";
import ProxiesTable from "components/layout/general/proxies/table";
import { ContentLayout } from "components/layout/panel/content-layout";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbList,
  BreadcrumbPage,
} from "components/ui/breadcrumb";
import { Card, CardContent, CardFooter, CardHeader } from "components/ui/card";
import { Spinner } from "components/ui/spinner";
import { backconnectServersHandler } from "handlers/backconnect-servers";
import {
  deleteUserModemHandler,
  updateUserModemHandler,
  userModemsHandler,
} from "handlers/user-modems";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { socket } from "socket";

export default function Proxies() {
  const { t } = useTranslation();
  const [modems, setModems] = useState([]);
  const [backconnectServers, setBackconnectServers] = useState([]);
  const [spinner, setSpinner] = useState(true);

  useEffect(() => {
    socket.emit("main:fetch:user-modems");
  }, []);
  useEffect(() => {
    socket.emit("main:fetch:backconnect-servers", setBackconnectServers);
  }, []);

  useEffect(() => userModemsHandler(socket, setModems, setSpinner), []);
  useEffect(() => updateUserModemHandler(socket, setModems), [modems]);
  useEffect(() => deleteUserModemHandler(socket, setModems), []);
  useEffect(() => backconnectServersHandler(socket, setBackconnectServers), []);

  return (
    <ContentLayout title={t(k.PROXIES)}>
      <Card className="rounded-lg border-none mt-6">
        <CardHeader>
          <Breadcrumb>
            <BreadcrumbList>
              <BreadcrumbItem>
                <BreadcrumbPage>{t(k.PROXIES)}</BreadcrumbPage>
              </BreadcrumbItem>
            </BreadcrumbList>
          </Breadcrumb>
        </CardHeader>
        <CardContent>
          {spinner && <Spinner className="text-primary flex justify-center" />}
          {modems.length ? (
            <ProxiesTable
              modems={modems}
              backconnectServers={backconnectServers}
            />
          ) : (
            <p className="text-center text-muted-foreground">
              {t(k.NO_PROXIES_AVAILABLE)}
            </p>
          )}
        </CardContent>
        <CardFooter></CardFooter>
      </Card>
    </ContentLayout>
  );
}
